import React from 'react'
import { useState } from 'react'
import bg from '../../asset/forest.jpg'
import './style.scss'
import ecosystem from '../../asset/ecosystem.png'
import compliant from '../../asset/compliant.png'
import trees from '../../asset/trees.png'
import EnvCard from './EnvCard'
import paris from '../../asset/Paris.png'
import paris1 from '../../asset/Paris1.png'
import paris2 from '../../asset/Paris2.png'
import ParallaxHeader from '../../component/ParallaxHeader'
import { Image } from 'antd'

const data={
    1:{1:
        <span>Төслийн үйл ажиллагаанаас байгаль орчин /агаар, ус, хөрс, ургамал, амьтан/ хүний эрүүл мэндэд үзүүлж болзошгүй сөрөг нөлөөллийг бууруулах  арга хэмжээг зохистой менежментийн дагуу зохион байгуулж, орчны бохирдол, газрын доройтол, хөрсний элэгдэл эвдрэл цөлжилт үүсэхээс сэргийлэх, үлдэгдэл нөлөөллийг дүйцүүлэн хамгаалах, нөхөн сэргээх, хог хаягдлыг зохистой менежментийг бий болгох, Байгаль орчныг хамгаалах тухай хууль дүрэм журам хийгээд түүнтэй холбоотой харилцааг хууль эрхзүйн хэм хэмжээнд хэрэгжүүлэх, байгаль орчны тэнцвэртэй бодлогыг бий болгож “Байгаль орчинд нөлөөлөх байдлын нарийнвчилсан үнэлгээний тайлан”-г хэрэгжүүлэхэд “Байгаль орчны менежментийн төлөвлөгөө”-ний зорилго оршино.
        Манай компани нь Монгол улсын “Байгаль орчныг хамгаалах тухай” хууль, “Байгаль орчинд нөлөөлөх байдлын үнэлгээний тухай” хууль, Байгаль орчны нөлөөллийн үнэлгээний журам /Засгийн газрын 2013 оны 374 дүгээр тогтоолын 2-р хавсралт/, “Байгаль орчны төлөв байдлын тайлан”, “Байгаль орчны төлөвлөгөөт аудитын тайлан”, “Газрын төлөв байдал чанарын хянан баталгааны тайлан”-г үндэслэн <span style={{fontWeight: 600, color: "black"}}>“БАЙГАЛЬ ОРЧНЫ МЕНЕЖМЕНТИЙН ТӨЛӨВЛӨГӨӨ БОЛОВСРУУЛАХ, ХЯНАН БАТЛАХ, ТАЙЛАГНАХ ЖУРАМ”</span> /Байгаль орчин, аялал жуулчлалын сайдын 2019 оны 10 дугаар сарын 29-ний өдрийн А/618 тоот тушаалын хавсралт/ зэрэг бичиг баримтуудыг удирдлага болгон түүнтэй холбогдсон харилцааг хуулийн хэм хэмжээнд хэрэгжүүлэн ажиллах, байгаль орчинд үүсэж болзошгүй сөрөг нөлөөллийг бууруулах, урьдчилан сэргийлэх чиглэлээр холбогдох арга хэмжээг төлөвлөн жил бүр “Байгаль орчны менежментийн төлөвөлөгөө”-г  хэрэгжүүлэн ажиллаж байна.</span>
    , 2: <span>Монгол улсад хүчин төгөлдөр хэрэгжиж буй бүх хууль, тогтоол, журам, дүрэм стандарт, шийдвэрүүд болон бусад хууль эрх зүйн баримт бичгүүдийг бид үйл ажиллагаандаа мөрдөж ажиллахаас гадна төслийн “Байгаль орчны бодлого” нь дараах хууль эрхзүйн бичиг баримтуудыг тулгуурласан байдаг. Үүнд: <br style={{marginBottom: "1rem"}}/>
    “Ашигт малтмалын тухай” хууль; <br/>
    “Агаарын тухай хууль” (шинэчилсэн найруулга) 2012. 05.17 <br/>
    “Амьтны туай” (шинэчилсэн найруулга) 2012.05.17 <br/>
    “Байгалийн нөөц ашигласны төлбөрийн тухай” (шинэчилсэн найруулга) 2012.05.17 <br/>
    “Байгаль орчинд нөлөөлөх байдлын үнэлгээний тухай хууль” (шинэчилсэн найруулга) 2012.05.17 <br/>
    “Байгаль орчныг хамгаалах тухай хууль” (шинэчилсэн найруулга) 2012.05.17 <br/>
    “Газрын тухай хууль” 2002.06.07 (шинэчилсэн найруулга) <br/>
    “Хог хаягдлын тухай хууль” (шинэчилсэн найруулга) 2012. 05.17 <br/>
    “Хөрс хамгаалах, цөлжилтөөс сэргийлэх тухай хууль” 2012.05.17 <br/>
    “Усны тухай хууль” (шинэчилсэн найруулга) 2012. 05.17 <br/>
    “Ус бохирдуулсны төлбөрийн тухай” 2012. 05.17 
    </span>,
    3: <span>Монгол Улсын Ерөнхийлөгч У.Хүрэлсүхийн 2021 оны 10 сарын 4-ний өдрийн 58-р зарлиг, “Нэг тэрбум” мод тарих үндэсний хөдөлгөөн, Засгийн газрын 2021 оны 11 сарын 17-ний өдрийн 350 дугаар тогтоол, Монгол Улсын Ерөнхийлөгч Ц.Элбэгдорж “2010 оны 04 сарын 14-ний өдрийн 62 дугаар “Мод тарих үндэсний өдөр зарлах тухай”-ын зарлиг түүний хэрэгжилтийг хангах, нийгмийн хариуцлагын хүрээнд экологи байгаль хамгааллын ажлыг үр дүнтэйгээр хэрэгжүүлэх зорилтын хүрээнд “Эко үйлдвэр-Ногоон ирээдүй”  төслийг хэрэгжүүлэн ажиллаж байна. Дээрх зорилгын хүрээнд дараах үндсэн 3 зорилтыг хэрэгжүүлэх юм. <br/>
    Зорилт 1: “Тэр бум мод” хөдөлгөөнийг дэмжин ажиллаж хүрээнд жил бүрийн 5,10 саруудад мод тариалалтын аян зохион байгуулахаас гадна “Ойн инженер” мэргэжлийн боловсон хүчин бэлтгэх, сургах, чадахижуулах, агаар ус хөрс амьтан ургамлын суурь судалгааны бааз үүсгэх <br/>
    Зорилт 2: “Эко үйлдвэр-Ногоон ирээдүй” зорилтын хүрээнд экологи байгаль хамгааллын үйл ажиллагааг үр дүнтэй хэрэгжүүлэх, мод үржүүлэгийн газар байгуулах, мод бут тариалах, ургуулах<br/>
    Зорилт 3: Ард иргэдийн ая тухай ажиллах орчин нөхцөлийг сайжруулах, экологийн тэнцвэрт байдлыг хадгалах</span>

},
    2: `“Зэс эрдэнийн хувь” ХХК-ийн “Катодын зэс боловсруулах үйлдвэр” төслийн үйл ажиллагаанаас байгаль орчинд үзүүлж болзошгүй сөрөг нөлөөллийг бууруулахдаа үүсэж болох эрсдэл, сөрөг үр дагавраас урьдчилан сэргийлэх нөхцөл, боломжийг бодитоор тооцоолон БОНБНҮ-ний тайланд тусгагдсан зөвлөмж, холбогдох дүрэм журам стандартуудыг мөрдөн хэрэгжилтийг хангаж ажиллах шаардлагад тулгуурлан “Компанийн Хөдөлмөрийн дотоод журам” бусад холбогдох дүрэм журмын хүрээнд “Байгаль орчны менежментийн бодлогын төсвийн төлөвлөгөө” боловсруулан хэрэгжүүлж ажиллаж байна. Бид 2022-2023 онд “Байгаль орчны менежментийн бодлогын төсвийн төлөвлөгөө”-ний хүрээнд нийт 1,668,392,590.50 төгрөгийг зарцуулахаар төлөвлөсөн бөгөөд Байгаль орчныг хамгаалах тухай хууль, удирдлага зохион байгуулалтын арга хэмжээний хүрээнд 707,239,567.50 төгрөг, Байгаль хамгаалах менежментийн төлөвлөгөөний хүрээнд 213,653,023.00төгрөг, “Тэр бум мод” үндэсний хөдөлгөөнийг дэмжих ажиллах нийгмийн хариуцлагын хүрээнд 747,500,000.00  төгрөгийг тус тус зарцуулах юм. `
}
function Environment() {
  const [id, setId]= useState(1)
  const [tab, setTab]= useState(1)
  return (
    <div className='env-container'>
        <ParallaxHeader src={bg} style={{objectFit: "cover"}}/>
        <div className='margin-style-real content'>
            <div className="home-news-title-container" style={{ paddingTop: "3rem",marginBottom: "65px", width: "100%" }}>
                {["Менежментийн бодлого", "Менежментийн бодлогын төсвийн төлөвлөгөө"].map((el, index) => (
                <div key={el.id} className="title-style" style={{width: "50%", justifyContent: "center", textAlign: "center",}}  onClick={() => {setId(index+1)}}>
                <span  className={`${id === index+1 ? 'text2' : 'text1'}`}>{el}</span>
                <div style={{with: "100%"}} className={`${id === index+1 ? 'line-style-active' : 'line-style'}`}/>
                </div>
                ))}
            </div>
            <div className='content-body'>
                {id===1 &&
                <div className='tabOne'>
                    <div className='title'>“Зэс эрдэнийн хувь” ХХК-ийн “Байгаль орчны бодлого” нь төслөөс байгаль орчинд үзүүлж болзошгүй сөрөг нөлөөллийг бууруулах, ард иргэдийн эрүүл аюулгүй амьдрах орчин нөхцөлийг бүрдүүлэх, нийгэм эдийн засгийн хөгжлийг байгаль орчны тэнцэлтэй уялдуулан хамгаалах, байгалийн нөөц баялгыг зүй зохистой ашиглах, нөхөн сэргээхэд үндэслэсэн ба дараах 3 чиглэлд хэрэгжих юм. </div>
                    <div className='tabs'>
                            {[{src: ecosystem, text: "Байгаль орчны менежментийн төлөвлөгөө"},
                            {src: compliant, text: "Байгаль орчны хууль эрх зүйн бичиг баримтын бүрдүүлэлт"},
                            {src: trees, text: "ТЭР БУМ МОД Үндэсний хөдөлгөөний хэрэгжилт"}].map((item, index)=>(
                                <EnvCard setTab={setTab} key={index} index={index} tab={tab} {...item} />
                            ))}
                    </div>
                    <div className='content'>
                        <div>{data[id][tab]}</div>
                        {tab===3 && 
                        <div className='picture-slider scrollbar-hide space-x-3'>
                            {[paris, paris1, paris2, paris, paris2].map((item, index)=>(
                                <div className='card'> <Image visible={true} src={item}/></div>
                            ))}
                        </div>}
                    </div>
                </div>
                }
                {id===2 && 
                <div className='tabTwo'>
                    {data[2]}
                </div>}
            </div>

        </div>
    </div>
  )
}

export default Environment