import React, {useEffect, useState} from "react"
import {Link} from "react-router-dom"
import bg from "../../asset/workplace.jpg"
import {Swiper, SwiperSlide} from "swiper/react"
import {Pagination} from "swiper"
import slashImg from "../../asset/Icon metro-quote@2x.png"
import chairman1 from "../../asset/1.png"
import {Modal} from "antd"
import WorkService from "../../services/workService"
import moment from "moment"
import ParallaxHeader from "../../component/ParallaxHeader"

const commitList = [
  {
    id: 1,
    img: chairman1,
    name: "Shalini",
    position: "Project Manager",
    text: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore",
  },
  {
    id: 2,
    img: chairman1,
    name: "Shalini",
    position: "Project Manager",
    text: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore",
  },
  {
    id: 3,
    img: chairman1,
    name: "Shalini",
    position: "Project Manager",
    text: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore",
  },
  {
    id: 4,
    img: chairman1,
    name: "Shalini",
    position: "Project Manager",
    text: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore",
  },
]

const newsTitle = [
  {
    id: 1,
    text: "Бидний тухай",
    to: "/work-place",
  },
  {
    id: 2,
    text: "Хүний нөөцийн бодлого",
    to: "/work-place",
  },
  {
    id: 3,
    text: "Сонгон шалгаруулалтын бодлого",
    to: "/work-place",
  },
  {
    id: 4,
    text: "Нээлттэй ажлын байр",
    to: "/work-place",
  },
]

export default function AnketPage() {
  const [selectedCommit, setSelectedCommit] = useState(null)
  const [open, setOpen] = React.useState(false)
  const [newsTitleId, setNewsTitleId] = useState(4)
  const [hrefId, setHrefId] = useState(null)
  const [resultData, setResultData] = useState(null)

  useEffect(() => {
    const location = window.location.href
    const id = location.substring(location.lastIndexOf("/") + 1)
    setHrefId(id)
    getData(id)
    // window.scrollTo(0, 0)
  }, [window.location.href])

  const getData = async (id) => {
    const result = await WorkService.getWorkById(id)
    setResultData(result[0])
  }

  const onChangeCommit = (data) => {
    setSelectedCommit(data)
    handleOpen()
  }

  const handleOpen = () => setOpen(true)
  const handleClose = () => setOpen(false)

  return (
    <React.Fragment>
      <Modal
        open={open}
        footer={null}
        onCancel={() => setOpen(false)}
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          zIndex: 1,
        }}
      >
        {selectedCommit && (
          <div className="management-modal">
            <img src={selectedCommit.img} className="img" />
            <div className="text-container">
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-between",
                }}
              >
                <span style={{fontSize: 22, fontWeight: "bold"}}>
                  {selectedCommit.name}
                </span>
              </div>
              <span
                style={{
                  fontSize: 20,
                  color: "#AF6C52",
                  marginTop: 10,
                  marginBottom: 10,
                }}
              >
                {selectedCommit.position}
              </span>
              <div style={{fontSize: 18, textAlign: "justify"}}>
                {selectedCommit.text}
              </div>
            </div>
          </div>
        )}
      </Modal>

      <ParallaxHeader src={bg} />
      <div
        className=" margin-style-real"
        style={{marginBottom: 50, marginTop: 50}}
      >
        <div
          className="title-style"
          style={{
            fontSize: "1.1rem",
            fontWeight: "500",
            paddingBottom: "1rem",
            borderBottom: "1px solid rgba(30, 30, 30, 0.1137254902)",
          }}
        >
          Ажлын байрны дэлгэрэнгүй
        </div>
      </div>
      <div className="margin-style-real" style={{marginBottom: 50}}>
        <div className="purchase-page ">
          <div style={{display: "flex", flexDirection: "row"}}>
            <span className="title-question">Ажлын байр:</span>
            <span className="text-answer">{resultData?.position}</span>
          </div>
          <div style={{display: "flex", flexDirection: "row"}}>
            <span className="title-question">Хэлтэс, алба, нэгж:</span>
            <span className="text-answer">{resultData?.branch}</span>
          </div>
          <div style={{display: "flex", flexDirection: "row"}}>
            <span className="title-question">Орон тоо:</span>
            <span className="text-answer">{resultData?.vacancy}</span>
          </div>
          <div style={{display: "flex", flexDirection: "row"}}>
            <span className="title-question">Байршил:</span>
            <span className="text-answer">{resultData?.location}</span>
          </div>
          <div style={{display: "flex", flexDirection: "row"}}>
            <span className="title-question">Ажиллах цагийн хуваарь:</span>
            <span className="text-answer">{resultData?.work_time}</span>
          </div>
          <div style={{display: "flex", flexDirection: "row"}}>
            <span className="title-question">Нээгдсэн огноо:</span>
            <span className="text-answer">
              {moment(resultData?.start_date).format("YYYY.MM.D")}
            </span>
          </div>
          <div style={{display: "flex", flexDirection: "row"}}>
            <span className="title-question">Хаагдах огноо:</span>
            <span className="text-answer">
              {moment(resultData?.end_date).format("YYYY.MM.D")}
            </span>
          </div>
          <div style={{display: "flex", flexDirection: "row"}}>
            <div className="title-question">Төлөв:</div>
            <div
              className="text-answer"
              style={{color: "#6BCDCB", fontSize: 14, fontWeight: "bold"}}
            >
              {resultData?.options_type}
            </div>
          </div>
        </div>
        <div>
          <span
            className="title-style"
            style={{marginTop: 60, marginBottom: 20, fontWeight: 500}}
          >
            Үндсэн чиг үүрэг:
          </span>
          <div
            className="text-style"
            style={{lineHeight: 2, marginBottom: "1rem"}}
          >
            {resultData?.main_role}
          </div>
          <span
            className="title-style"
            style={{marginTop: 60, fontWeight: 500}}
          >
            Ажлын байранд тавигдах шаардлага:
          </span>
          {resultData &&
            resultData.requirement &&
            resultData.requirement.length > 0 &&
            resultData.requirement.map((data, index) => (
              <ul key={index} style={{display: "flex"}}>
                <li>
                  <span className="text-style">{data.value}</span>
                </li>
              </ul>
            ))}
        </div>
      </div>

      <div className="margin-style-real">
        <div className="purchase-button-container">
          <a
            href="https://anket.zes-erdene.mn/"
            className="btn-style"
            style={{textOverflow: "ellipsis", textDecoration: "none"}}
          >
            <span style={{color: "#FFFFFF", fontSize: 18, fontWeight: 500}}>
              Анкет бөглөх
            </span>
          </a>
        </div>
      </div>

      <div
        style={{
          display: "flex",
          justifyContent: "center",
          marginTop: 230,
          marginBottom: 50,
        }}
      >
        <span className="container-title-style-main">АЖИЛЧДЫН СЭТГЭГДЭЛ</span>
      </div>

      <div className="margin-style-real" style={{marginBottom: 130}}>
        <Swiper
          slidesPerView={3}
          spaceBetween={30}
          pagination={{
            clickable: true,
          }}
          modules={[Pagination]}
          className="mySwiper"
        >
          {commitList.map((element, index) => (
            <SwiperSlide>
              <div
                className="workers-commend"
                key={index}
                onClick={() => onChangeCommit(element)}
              >
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    marginTop: 44,
                    marginLeft: 19,
                  }}
                >
                  <img
                    src={slashImg}
                    style={{width: 25, height: 15, marginTop: 5}}
                  />
                  <div className="text">{element.text}</div>
                </div>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    marginBottom: 40,
                    marginLeft: 60,
                  }}
                >
                  <img
                    src={element.img}
                    style={{width: 47, height: 47, borderRadius: 30}}
                  />
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      marginLeft: 15,
                    }}
                  >
                    <div style={{fontSize: 15}}>{element.name}</div>
                    <div style={{color: "#848484", fontSize: 15}}>
                      {element.position}
                    </div>
                  </div>
                </div>
              </div>
            </SwiperSlide>
          ))}
        </Swiper>
      </div>
    </React.Fragment>
  )
}
