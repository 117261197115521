import React, {useEffect, useState} from "react";
import {
    NavAnother,
    NavLinkAnother,
    NavMenuAnother,
    AboutUsAnother,
    TopListContAnother,
    NavListLinkAnother,
    HomeTopContainerAnother
} from "./NavStyle";
import {useLocation} from 'react-router-dom'
import zesBlack from '../../asset/Logo.png'

import more from '../../asset/more.png'
import mongolia from '../../asset/mongolia.png'
import united from '../../asset/united.png'
import Navbar from "./Navbar";
import Dropdown from "./Dropdown";


function getWindowDimensions() {
    const { innerWidth: width, innerHeight: height } = window;
    return {
        width,
        height
    };
}

export default function TopBar() {
    const [windowDimensions, setWindowDimensions] = useState(getWindowDimensions());
    const [changeStatus, setChangeStatus] = useState(false);
    const [mobile, setMobile] = useState(false);
    const [changeLanguage, setChangeLanguage] = useState('MN');
    const location = useLocation();
    const [scrollY, setScrollY] = useState(window.scrollY);
    const [active, setActive]= useState(false)

    useEffect(() => {
        getStatus(location);
    }, [location]);

    const setDimension = () => {
        setScrollY(window.scrollY)
    }
    useEffect(() => {
        window.addEventListener('scroll', setDimension);

        return (() => {
            window.removeEventListener('scroll', setDimension);
        })
    }, [scrollY])

    const getStatus = async (data) => {
        if (data.pathname === '/') {
            setChangeStatus(true);
        } else {
            setChangeStatus(false);
        }
    }
    const style = {
        menuStyleWhite: {height: "2rem", display: "flex", alignItems: "center"},
        menuStyleDark: {color: "rgba(0, 0, 0, 0.638)", display: "flex", alignItems: "center", height: "2rem"},
        navOtherStyleWhite: {
            borderBottom: "2px solid rgba(255, 255, 255, 0.111)",
            backdropFilter:"blur(2px) brightness(60%)",
            display: 'flex',
            justifyContent: "space-between"
        },
        navOtherStyleDark: {
            borderBottom: "0px solid rgba(30,30, 30, 0.011)",
            boxShadow: "0 0 2px rgba(30,30, 30, 0.081)",
            background: "rgba(255,255,255,0.92)"
        },
        navListWhite: {
            background: "white",
        },
        navListBlack:{
            background: "rgba(0,0,0,.45)",
        },
        navItemWhite:{
            color: "white"
        },
        navItemBlack:{
            color: "black",
        }
    }

    return (
        <React.Fragment>
            <div className={`tob-bar-container isActive`}
                 style={{
                     zIndex: "1003",
                     color: 'none',
                     position: "sticky",
                     top: 0,
                     textTransform: "uppercase",
                     textAlign: "center"
                 }}>
                <NavAnother style={changeStatus ? style.navOtherStyleWhite : style.navOtherStyleDark}>
                    <NavLinkAnother to="/" style={{display: "flex", alignItems: "center", justifyContent: "center", padding: 0}}>
                        <div className="home-logo-container" style={{
                            width: "2.8rem",
                            height: "100%",
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                            marginTop: "2rem"
                        }}>
                            <img src={zesBlack} className="home-logo"
                                 style={{width: "100%", height: "100%", objectFit: "scale-down"}}/>
                        </div>
                    </NavLinkAnother>
                    <div className='webMenu'>
                        <NavMenuAnother style={{height: "100%", justifyContent: "space-between"}}>
                            <Dropdown color={changeStatus? "white": "black"}/>
                            {/* <HomeTopContainerAnother style={{height: "100%"}}>
                                <AboutUsAnother to="/about" style={changeStatus ? style.menuStyleWhite : style.menuStyleDark}> <div> Компанийн тухай </div></AboutUsAnother>
                                <TopListContAnother style={changeStatus ? style.navListBlack : style.navListWhite}> 
                                    <NavListLinkAnother style={changeStatus ? style.navItemWhite : style.navItemBlack} to="/about">Компанийн тухай</NavListLinkAnother>
                                    <NavListLinkAnother style={changeStatus ? style.navItemWhite: style.navItemBlack} to="/management">Компанийн удирдлага</NavListLinkAnother>
                                    <NavListLinkAnother style={changeStatus ? style.navItemWhite : style.navItemBlack} to="/news">Мэдээ мэдээлэл</NavListLinkAnother>
                                </TopListContAnother>
                            </HomeTopContainerAnother> */}
                            <NavLinkAnother className={!changeStatus && "white"} style={changeStatus ? style.menuStyleWhite : style.menuStyleDark} to="/construction"> Бүтээн байгуулалт <div className="underline"/></NavLinkAnother>
                            <NavLinkAnother className={!changeStatus && "white"} style={changeStatus ? style.menuStyleWhite : style.menuStyleDark} to="/work-place">   Ажлын байр  <div className="underline"/></NavLinkAnother>
                            <NavLinkAnother className={!changeStatus && "white"} style={changeStatus ? style.menuStyleWhite : style.menuStyleDark} to="/purchase">     Худалдан авалт  <div className="underline"/></NavLinkAnother>
                            <NavLinkAnother className={!changeStatus && "white"} style={changeStatus ? style.menuStyleWhite : style.menuStyleDark} to="/investment">   Хувьцаа эзэмшигчид  <div className="underline"/></NavLinkAnother>
                            <NavLinkAnother className={!changeStatus && "white"} style={changeStatus ? style.menuStyleWhite : style.menuStyleDark} to="/environment"> Байгаль орчин <div className="underline"/></NavLinkAnother>
                        </NavMenuAnother>
                    </div>
                  
                       
                                <div className="newLanguageContainer" style={{fontSize: "1rem", gap: "1rem"}}>
                                    <div onClick={() => setChangeLanguage('MN')} className={`${changeLanguage === 'MN' && "active"}`}> <img style={{width: "1.3rem"}} src={mongolia}/></div>
                                    <div onClick={() => setChangeLanguage('EN')} className={`${changeLanguage !== 'MN' && "active"}`}>  <img style={{width: "1.3rem"}} src={united}/></div>
                                </div>
                            <div className='mobileMenu'>
                            <Navbar active={active} setActive={setActive} changeStatus={changeStatus}/>
                            
                            {/* <Row>
                                <NavMenuAnother style={{height: "100%",  justifyContent: "space-between"}}>
                                    <HomeTopContainerAnother style={{height: "100%"}}>
                                        <AboutUsAnother to="/about" style={changeStatus ? style.menuStyleWhite : style.menuStyleDark}>
                                            <img src={more} style={{width: "1.5rem"}} alt={''}/>
                                        </AboutUsAnother>
                                        <TopListContAnother>
                                            <NavListLinkAnother to="/about">        Компаний тухай </NavListLinkAnother>
                                            <NavListLinkAnother to="/management">   Компаний удирдлага </NavListLinkAnother>
                                            <NavListLinkAnother to="/news">         Мэдээ мэдээлэл</NavListLinkAnother>
                                            <NavListLinkAnother to="/construction"> Бүтээн байгуулалт</NavListLinkAnother>
                                            <NavListLinkAnother to="/work-place">   Ажлын байр</NavListLinkAnother>
                                            <NavListLinkAnother to="/purchase">     Худалдан авалт</NavListLinkAnother>
                                            <NavListLinkAnother to="/investment">   Хувьцаа эзэмшигчид</NavListLinkAnother>
                                        </TopListContAnother>
                                    </HomeTopContainerAnother>
                                </NavMenuAnother>
                                </Row> */}
                            </div>
                        

                

                </NavAnother>
            </div>

        </React.Fragment>
    )
}
