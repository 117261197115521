import React from 'react'
import {Dropdown as Downer, Space} from 'antd'
import {Link} from "react-router-dom"
import { NavLink } from 'react-router-dom'
import { NavLinkAnother } from './NavStyle'
import { DownOutlined } from '@ant-design/icons';

function Dropdown({color}) {
    const style={
        div: {
            display: "flex", alignItems: "center"
        },
        link: {
            textDecoration: "none", fontSize: "12px",
        }
    }
    const items=[
        {label: (<Link style={{...style.link}} to="/management">КОМПАНИЙН УДИРДЛАГА</Link>), key: 0},
        {label: (<Link style={{...style.link}} to="/news">МЭДЭЭ МЭДЭЭЛЭЛ</Link>), key: 1},
        
    ]    
  return (
    <Downer placement='bottom' menu={{items}}>
        <div style={style.div}>
        
            <NavLink className="navLink-dropdown"  style={{color:  color!=="black" ? "rgba(255, 255, 255, 0.838)" : "rgba(0, 0, 0, 0.638)",...style.link}}  to="/about">
               <span>КОМПАНИЙН ТУХАЙ <DownOutlined/></span>
               <div className="underline"/>
                </NavLink>
           
        </div>
    </Downer>
  )
}

export default Dropdown