import React, {useEffect, useState} from "react"
import BannerImg from "../../asset/Home4.jpg"
import megaImg from "../../asset/megaphone@2x.png"
import contactImg from "../../asset/contact-form@2x.png"
import ratingImg from "../../asset/rating@2x.png"
import receiveImg from "../../asset/receive@2x.png"
import selectImg from "../../asset/select@2x.png"
import Paper from "@mui/material/Paper"
import Table from "@mui/material/Table"
import TableHead from "@mui/material/TableHead"
import TableRow from "@mui/material/TableRow"
import TableCell from "@mui/material/TableCell"
import TableBody from "@mui/material/TableBody"
import TableContainer from "@mui/material/TableContainer"
import {Link} from "react-router-dom"
import {Swiper, SwiperSlide} from "swiper/react"
import {Pagination} from "swiper"
import slashImg from "../../asset/Icon metro-quote@2x.png"
import chairman1 from "../../asset/1.png"
import {Modal} from "antd"
import WorkService from "../../services/workService"
import moment from "moment"
import OrgComponent from "../Home/OrgComponent"
import ParallaxHeader from "../../component/ParallaxHeader"
import {useScreenWidth} from "../../customHooks/useScreenWidth"
const commitList = [
  {
    id: 1,
    img: chairman1,
    name: "Shalini",
    position: "Project Manager",
    text: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore",
  },
  {
    id: 2,
    img: chairman1,
    name: "Shalini",
    position: "Project Manager",
    text: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore",
  },
  {
    id: 3,
    img: chairman1,
    name: "Shalini",
    position: "Project Manager",
    text: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore",
  },
  {
    id: 4,
    img: chairman1,
    name: "Shalini",
    position: "Project Manager",
    text: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore",
  },
]

const processData = [
  {
    id: 1,
    img: megaImg,
    title: "Худалдан авах бараа, ажил үйлчилгээг зарлах",
    text: "Худалдан авах бараа, ажил, үйлчилгээг ханган нийлүүлэх этгээдийг тендер, эсхүл хялбаршуулсан худалдан авалт хэлбэрээр сонгон шалгаруулах зарыг өөрийн вэб сайтаар зарлана. Зард ханган нийлүүлэгч болон бараа, ажил, үйлчилгээнд тавигдах шаардлагыг тодорхой тусгана. Шаардлагад тусгаснаас өөр нэмэлт мэдээллийг аль нэг талд өгөхгүй бөгөөд шаардлагатай нэмэлт мэдээллийг вэб-ээр дамжуулан хүргэнэ.",
  },
  {
    id: 2,
    img: receiveImg,
    title: "Санал хүлээн авах",
    text: "Тендер, Хялбаршуулсан худалдан авалтын сонгон шалгаруулалтын зард дурьдсан хугацаанд, заасан сувгаар ханган нийлүүлэх саналыг хүлээн авна.",
  },
  {
    id: 3,
    img: selectImg,
    title: "Урьдчилсан шатны шалгаруулалт",
    text: "Санал ирүүлсэн этгээдээс ирүүлсэн материалын бүрдэл, материалыг хянаж, шаардлага хангасан этгээдийг дараагийн шатны шалгаруулалтад шалгаруулна. Ирүүлэх мэдээлэл, материалыг бүрэн ирүүлээгүй этгээдийг сонгон шалгаруулалтаас хасна.",
  },
  {
    id: 4,
    img: contactImg,
    title: "Мэдээлэл, лавлагаа",
    text: "Урьдчилсан шатны шалгаруулалтад тэнцсэн бараа, ажил, үйлчилгээ нийлүүлэх саналаа ирүүлсэн этгээдээс нийлүүлэх бараа, ажил, үйлчилгээ болон өмнөх ажлын туршлагын талаар судалгааны ажлыг зохион байгуулна. Шаардлагатай тохиолдолд нэмэлт материал гаргуулж, уулзалт зохион байгуулна.",
  },
  {
    id: 5,
    img: ratingImg,
    title: "Эцсийн шалгаруулалт",
    text: "Ирүүлсэн санал, судалгаан дээр үндэслэн Нийлүүлэгчийг сонгон шалгаруулна.",
  },
]

const newsTitle = [
  {
    id: 1,
    text: "Худалдан авалтын бодлого",
  },
  {
    id: 2,
    text: "Худалдан авалтын процесс",
  },
  {
    id: 3,
    text: "Худалдан авалт",
  },
]

export default function Purchase() {
  const [selectedData, setSelectedData] = useState(null)
  const [newsTitleId, setNewsTitleId] = useState(1)
  const [selectedCommit, setSelectedCommit] = useState(null)
  const [toggle, setToggle] = useState(false)
  const [selectedProcess, setSelectedProcess] = useState()
  const [listData, setListData] = useState([])

  useEffect(() => {
    getData()
  }, [])
  const screenSize = useScreenWidth()
  const [open, setOpen] = React.useState(false)

  const getData = async () => {
    const result = await WorkService.getPurchase()
    setListData(result)
  }

  const clickChange = (data) => {
    setSelectedData(data)
  }

  const onChangeCommit = (data) => {
    setSelectedCommit(data)
    handleOpen()
  }

  const handleOpen = () => setOpen(true)
  const handleClose = () => setOpen(false)

  const openToggle = () => setToggle(true)
  const handleToggle = (e) => {
    setSelectedProcess(e)
    openToggle()
  }
  const style = {
    text: {
      fontSize: ".875rem",
      fontWeight: 600,
      textAlign: "center",
    },
  }
  return (
    <React.Fragment>
      <Modal
        open={open}
        footer={null}
        onCancel={() => setOpen(false)}
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          zIndex: 1,
        }}
      >
        {selectedCommit && (
          <div className="management-modal">
            <img src={selectedCommit.img} className="img" />
            <div className="text-container">
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-between",
                }}
              >
                <span style={{fontSize: 22, fontWeight: "bold"}}>
                  {selectedCommit.name}
                </span>
              </div>
              <span
                style={{
                  fontSize: 20,
                  color: "#AF6C52",
                  marginTop: 10,
                  marginBottom: 10,
                }}
              >
                {selectedCommit.position}
              </span>
              <div style={{fontSize: 18, textAlign: "justify"}}>
                {selectedCommit.text}
              </div>
            </div>
          </div>
        )}
      </Modal>
      <Modal
        open={toggle}
        footer={null}
        onCancel={() => setToggle(false)}
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          zIndex: 1,
        }}
      >
        {selectedProcess && (
          <div className="process-modal">
            <img src={selectedProcess.img} className="img" />
            <div className="text-container">
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-between",
                }}
              >
                <span style={{fontSize: 18, fontWeight: "bold"}}>
                  {selectedProcess.title}
                </span>
              </div>
              <div style={{fontSize: 14, textAlign: "justify"}}>
                {selectedProcess.text}
              </div>
            </div>
          </div>
        )}
      </Modal>

      <ParallaxHeader src={BannerImg} />
      {/* <div className="">
        <div style={{ backgroundImage: 'url('+BannerImg+')' , margin: 0}} className="investment-banner">
          <div style={{ backgroundColor: '#1B1D24', width: '100%', height: '100%', opacity: 0 }} />
        </div>
      </div> */}

      <div
        className="home-news-title-container margin-style-real"
        style={{marginTop: 50, marginBottom: "3rem"}}
      >
        {newsTitle.map((el) => (
          <div
            key={el.id}
            className="title-style"
            style={{width: "100%"}}
            onClick={() => setNewsTitleId(el.id)}
          >
            <span
              className={`${newsTitleId === el.id ? "text2" : "text1"}`}
              style={{textAlign: "center"}}
            >
              {el.text}
            </span>
            <div
              className={`${
                newsTitleId === el.id ? "line-style-active" : "line-style"
              }`}
            />
          </div>
        ))}
      </div>

      {newsTitleId === 1 && (
        <div className="margin-style-real">
          <div className="purchase-text-content">
            Зэс Эрдэнийн хувь ХХК нь гадаад худалдаа, гидрометаллургийн аргаар
            катодын зэс гарган авах үйл ажиллагаа эрхлэхээр 2010 оны 6-р сарын
            21-ний өдөр үүсгэн байгуулагдсан бөгөөд Орхон аймгийн Иргэдийн
            төлөөлөгчдийн хурал, Эрдэнэт Үйлдвэр ХХК-тэй 2013 оны 5-р сарын
            02-ны өдөр 3 талт хамтран ажиллах гэрээ байгуулж, Уулын баяжуулах
            Эрдэнэт үйлдвэр ТӨҮГ-ын 8а болон 12-р овоолгыг ашиглан
            гидрометаллургийн аргаар катодын цэвэр зэс боловсруулах үйлдвэрийн
            төсөл хэрэгжүүлэх нөхцөл бүрдсэн. ​
          </div>
          <div className="purchase-text-content">
            Зэс эрдэнийн хувь ХХК нь Эрдэнэт үйлдвэр ТӨҮГ-ын технологийн бус
            исэлдсэн хүдэр болон холимог хүдрийн овоолгод түшиглэн жилд 10.0
            мян.тн, 99.99%-ийн цэвэршилттэй катодын зэс үйлдвэрлэх хүчин
            чадалтай SX/EW үйлдвэрийг Орхон аймгийн Баян-Өндөр сумын Баянцагаан
            баг, Сархиа уулын зүүн урд энгэрт байгуулахаар, үйлдвэрийн бүтээн
            байгуулалтын ажлыг 2019 оноос
          </div>
          <div className="purchase-text-content" style={{marginTop: 30}}>
            2019 оноос Компанийн хөрөнгө оруулагч, удирдлагын баг өөрчлөгдөж
            Үндэсний хөрөнгө оруулагч болон уул уурхай, санхүүгийн чиглэлээр
            мэргэшсэн мэргэжлийн баг төслийг амжилттай хэрэгжүүлэх нөхцөлийг
            бүрдүүлэн, төслийн бүтээн байгуулалтын ажлыг амжилттай хэрэгжүүлж
            байна.​Зэс Эрдэнийн хувь ХХК нь гадаад худалдаа, гидрометаллургийн
            аргаар катодын з
          </div>
        </div>
      )}

      {newsTitleId === 2 && (
        <>
          <div className="margin-style-real" style={{marginTop: "2rem"}}>
            Зэс эрдэнийн хувь ХХК нь худалдан авалтыг тендер болон хялбаршуулсан
            сонгон шалгаруулалтын зарчмаар ханган нийлүүлэгчийн сонгон
            шалгаруулалтыг хийдэг ба дараах үе шатны дагуу явуулна.
          </div>

          <div
            className="workers-choice-list  margin-style-real"
            style={{gap: "1.2rem"}}
          >
            {processData.map((el, index) => (
              <div
                key={el.id}
                className="aboutUs-vision-box-workplace"
                onClick={() => {
                  screenSize.width < 887 && handleToggle(el)
                }}
                style={{
                  alignItems: "center",
                  height: "auto",
                  cursor: "pointer",
                }}
              >
                <div className="color-box" style={{width: 75, height: 70}}>
                  <img src={el.img} style={{width: 40, height: 40}} />
                </div>
                <div
                  className="title-style"
                  style={{textAlign: "center", fontSize: ".875rem"}}
                >
                  {index + 1}
                  {". "}
                  {el.title}
                </div>
                <div className="text-style">
                  <span dangerouslySetInnerHTML={{__html: el.text}}></span>
                </div>
              </div>
            ))}
          </div>
          <div className="margin-style-real" style={{marginTop: "2rem"}}>
            Худалдан авалт болон Тендерийн шалгаруулалтад оролцох хуулийн
            этгээд, иргэн бүрт адил, тэгш боломж олгон, бараа, ажил үйлчилгээ,
            түүнийг нийлүүлэгчид тавигдах шаардлагыг хангасан тохиолдолд
            дараагийн шатны шалгаруулалтад оруулах бөгөөд нийлүүлэгчийг сонгон
            шалгаруулах эцсийн шийдвэрийг шалгаруулалтын баг ямар нэгэн зуучлагч
            хувь хүний оролцоогүйгээр гаргадаг болно.
          </div>
        </>
      )}

      {newsTitleId === 3 && (
        <div className="workPlace-table" style={{marginBottom: 100}}>
          <TableContainer component={Paper}>
            <Table sx={{minWidth: 400}} aria-label="simple table">
              <TableHead>
                <TableRow>
                  <TableCell>
                    <span style={style.text}>№</span>
                  </TableCell>
                  <TableCell>
                    <span style={style.text}>Худалдан авалтын нэр</span>
                  </TableCell>
                  <TableCell align="center">
                    <span style={style.text}>Худалдан авалтын дугаар</span>
                  </TableCell>
                  <TableCell align="center">
                    <span style={style.text}>Нээгдсэн огноо</span>
                  </TableCell>
                  <TableCell align="center">
                    <span style={style.text}>Хаагдах огноо</span>
                  </TableCell>
                  <TableCell align="center">
                    <span style={style.text}>Төрөл</span>
                  </TableCell>
                  <TableCell align="center">
                    <span style={style.text}>Төлөв</span>
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {listData.map((row, index) => (
                  <TableRow
                    key={row.id}
                    sx={{"&:last-child td, &:last-child th": {border: 0}}}
                  >
                    <TableCell component="th" scope="row">
                      {index + 1}
                    </TableCell>
                    <TableCell width={200} component="th" scope="row">
                      {row.name}
                    </TableCell>
                    <TableCell width={200} align="center">
                      {row.code}
                    </TableCell>
                    <TableCell align="center">
                      {moment(row.start_date).format("YYYY.MM.D")}
                    </TableCell>
                    <TableCell align="center">
                      {moment(row.end_date).format("YYYY.MM.D")}
                    </TableCell>
                    <TableCell align="center">{row.type}</TableCell>
                    <TableCell align="center">
                      {row.status === 1 ? (
                        <Link
                          to={`/purchase-detail/${row.id}`}
                          className={`purchase-table-button`}
                        >
                          Нээлттэй
                        </Link>
                      ) : (
                        <div className={`purchase-table-button isActive`}>
                          Хаагдсан
                        </div>
                      )}
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </div>
      )}

      <OrgComponent />
    </React.Fragment>
  )
}
