import { NavLink as Link } from 'react-router-dom';
import styled from 'styled-components';

export const NavAnother = styled.nav`
  top: 0;
  background: transparent;
  height: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0.875rem 3%;
  z-index: 100;
  
`


export const NavLinkAnother = styled(Link)`
  color: rgba(255, 255, 255, 0.838);
  padding-top: .25rem;
  display: flex;
  font-size: 12px;
  font-weight: 500;
  justify-content: center;
  flex-direction: column;
  text-decoration: none;
  height: 100%;
  cursor: pointer;
  transition: .2s ease-out;
  &.white{
    &:hover{
    color: black !important;
    transition: .2s ease-in;
    .underline{
      opacity: 0;
      background: black !important;
      transition: .3s ease-in
    }
   
    }
  }
  &.white{
    &.active{
    color: black !important;
    transition: .2s ease-in;
    .underline{
      opacity: 100%;
      background: black !important;
      transition: .3s ease-in
    }
    }  
    }
  .underline{
    width: .25rem;
    height: .25rem;
    background: white;
    margin-top: .3rem;
    border-radius: 50%;
    opacity: 0%;
    transition: .2s ease-in
  }
  
  &.active {
    color: white;
    transition: .2s ease-in
  }
  &:hover {
    color: white;
    transition: .3s ease-in;
    .underline{
      opacity: 0;
      transition: .3s ease-in
    }
  }
  
  @media screen and (max-width: 1024px) {
    font-size: 12px;
  }
`

export const TopListContAnother = styled.div`
  ${'' /* background: #AF6C52; */}
  background:white;
  padding: 12px;
  visibility: hidden;
  position: absolute;
  margin-top: 25%;
  ${'' /* margin-left: -25px; */}
  ${'' /* width: 18rem */}
  height: 143px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  @media screen and (max-width: 886px) {
    width: 240px;
    height: 300px;
    transform: translate(-200px,10px);
  }
`

export const AboutUsAnother = styled(Link)`
  color: rgba(255, 255, 255, 0.838);
  display: flex;
  font-size: 12px;
  font-weight: regular;
  align-items: center;
  text-decoration: none;
  height: 100%;
  cursor: pointer;
  
  &.active {
    font-weight: bold;
  }
  &:hover {
    font-weight: bold;
  }
  
  @media screen and (max-width: 1024px) {
    font-size: 12px;
  }
`

export const HomeTopContainerAnother = styled.div`
  flex: none;
  height: 100%;
  display: inline-flex;
  box-sizing: border-box;
  margin: 0;
  min-width: 0;
  position: relative;
  &:hover ${TopListContAnother} {
    visibility: visible;
  }
`

export const NavListLinkAnother = styled(Link)`
  color: #FFFFFF;
  display: flex;
  font-size: 12px;
  font-weight: regular;
  align-items: center;
  text-decoration: none;
  padding: 0 1rem;
  height: 100%;
  cursor: pointer;
  margin-top: 5px;
  margin-bottom: 5px;
  
  &:hover {
    font-weight: bold;
  }
  &.active {
    font-weight: bold;
  }
`

export const NavMenuAnother = styled.div`
  display: flex;
  flex-direction: row;
  width: 70vw;
  
`
