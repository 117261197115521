import React from 'react'
import {motion} from 'framer-motion'

function ProbCard(props) {
  const item = {
    hidden: { opacity: 0 },
    show: { opacity: 1 }
  }
  return (
    <motion.div className='prob-card'  
    // whileHover={{ cursor: "pointer",
    //  rotateZ: "720deg", type: "spring"
      // }}
     variants={item}>
      <div className='title'>{props.title}</div>
      <div className='logo'></div>
      <div className='text'>{props.text}</div>
    </motion.div>
  )
}

export default ProbCard