import React, {useEffect, useState} from "react";
import {ReactComponent as FbSVG} from "../../asset/fb.svg";
import {ReactComponent as YoutubeSVG} from "../../asset/youtube.svg";
import {ReactComponent as TwitSVG} from "../../asset/twt.svg";
import {useLocation} from "react-router-dom";
import LocImg from "../../asset/location-pin@2x.png";
import PhoneImg from "../../asset/phone-call (1)@2x.png";
import EmailImg from "../../asset/Group 40796@2x.png";

export default function BottomBar() {
  const [changeStatus, setChangeStatus] = useState(true);
  const location = useLocation();

  useEffect(() => {
    getStatus(location);
  }, [location]);

  const getStatus = async (data) => {
    if (data.pathname === '/login') {
      setChangeStatus(false);
    } else {
      setChangeStatus(true);
    }
  }

  return(
    <React.Fragment>
      {changeStatus && 
      <>
      
      <div className="bottom-copy-right">
        <div className="bottom-style margin-style-real">
          <div style={{color: '#000000', fontSize: 14}}>ЗЭС ЭРДЭНИЙН ХУВЬ ХХК © 2022 Бүх эрх хуулиар хамгаалагдсан.</div>
          <div style={{display: 'flex', flexDirection: 'row'}}>
            <a href={'https://www.facebook.com/zeserdene.erdenet'} target={'_blank'} rel="noreferrer">
              <FbSVG className="topBar-icon-style"/>
            </a>
            <a href={'https://www.youtube.com/channel/UCYHNoOdN5itmTvCAp6XGFjw'} target={'_blank'} rel="noreferrer">
              <YoutubeSVG className="topBar-icon-style"/>
            </a>
            <a href={'https://www.facebook.com/zeserdene.erdenet'} target={'_blank'} rel="noreferrer">
              <TwitSVG className="topBar-icon-style"/>
            </a>
          </div>
        </div>
      </div>
      </>}
    </React.Fragment>
  )
}
