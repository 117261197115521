import React from "react"
import {ParallaxBanner} from "react-scroll-parallax"
import {ParallaxProvider} from "react-scroll-parallax"
import {motion} from "framer-motion"

function ParallaxHeader(props) {
  const background = {
    image: props.src,
    translateY: [0, 50],
    opacity: [1, 0.3],
    style: props.style,
    scale: [1.05, 1, "easeOutCubic"],
    shouldAlwaysCompleteAnimation: true,
  }
  const headline = {
    translateY: [0, 30],
    scale: [1, 1.1, "easeOutCubic"],
    shouldAlwaysCompleteAnimation: true,
    expanded: false,
    children: (
      <motion.div
        variants={{
          visible: {
            transition: {
              staggerChildren: 0.025,
            },
          },
        }}
        className="inset center"
        style={{
          position: "absolute",
          inset: 0,
          top: 0,
          left: 0,
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <motion.h1
          variants={{
            hidden: {
              y: "200%",
              transition: {ease: [0.455, 0.03, 0.515, 0.955], duration: 0.85},
            },
            visible: {
              y: 0,
              transition: {ease: [0.455, 0.03, 0.515, 0.955], duration: 0.75},
            },
          }}
          className="headline white"
          style={{
            fontSize: "3rem",
            color: "white",
            fontWeight: 500,
            display: "flex",
            alignItems: "center",
            gap: "1rem",
          }}
        >
          <div style={{width: "6rem", height: "100%"}}>
            {/* <img style={{width: "100%", height: "100%"}} src={logo}/> */}
          </div>
          {props.text ? props.text : ""}
        </motion.h1>
      </motion.div>
    ),
  }
  const gradientOverlay = {
    opacity: [0, 1, "easeOutCubic"],
    shouldAlwaysCompleteAnimation: true,
    expanded: false,
    children: (
      <div
        className="gradient inset"
        style={{
          background: "linear-gradient(rgba(14, 62, 151, 0.5) 50%, black)",
          position: "absolute",
          inset: 0,
        }}
      />
    ),
  }

  return (
    <ParallaxProvider>
      <ParallaxBanner
        layers={[background, props.text && headline, gradientOverlay]}
        className="full"
        style={{height: "50vh"}}
      />
    </ParallaxProvider>
  )
}

export default ParallaxHeader
