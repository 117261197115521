import React, {useEffect, useState} from "react";
import 'react-alice-carousel/lib/alice-carousel.css';
import HomeBanner from "../../asset/HomeBanner.jpg";
import HomeBanner1 from "../../asset/HomeBanner1.jpg";
import HomeBanner2 from "../../asset/HomeBanner2.jpg";
import HomeBanner3 from "../../asset/HomeBanner3.jpg";
import LocImg from "../../asset/location-pin@2x.png";
import PhoneImg from "../../asset/phone-call (1)@2x.png";
import EmailImg from "../../asset/Group 40796@2x.png";
import HomeBanner4 from "../../asset/Home2.png";
import {ToastContainer} from "react-toastify";
import NewsComponentHome from "../../component/News/NewComponentHome";
import OrgComponent from "./OrgComponent";
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation ,Autoplay, Pagination,} from "swiper";
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
export default function Home() {
  const [screenSize, getDimension] = useState({
    dynamicWidth: window.innerWidth,
    dynamicHeight: window.innerHeight
  });
  const setDimension = () => {
    getDimension({
      dynamicWidth: window.innerWidth,
      dynamicHeight: window.innerHeight
    })
  }

  useEffect(() => {
    window.addEventListener('resize', setDimension);

    return(() => {
      window.removeEventListener('resize', setDimension);
    })
  }, [screenSize])

  useEffect(() => {
    setDimension();
    window.scrollTo(0, 0)
  }, [])
  return (
    <div className="home">
      <ToastContainer />
      <Swiper navigation={true}
      style={{marginTop: -70}}
       autoplay={{
          delay: 2500,
          disableOnInteraction: false,
        }}
        pagination={{
          clickable: true,
        }}
       modules={[Autoplay, Pagination, Navigation]} className="mySwiper">
        <SwiperSlide>
        <img src={HomeBanner}/>
        </SwiperSlide>
        <SwiperSlide>  
        <img src={HomeBanner2}/>
        </SwiperSlide>
        <SwiperSlide>
        <img src={HomeBanner3}/>
        </SwiperSlide>
        <SwiperSlide>
        <img src={HomeBanner4}/>
        </SwiperSlide>
      </Swiper>
      <div>
        <NewsComponentHome />
      </div>
      <OrgComponent/>
        <div className="bottom-bar margin-style-real" id="contact-me">
          <div className="btn-about-texts" >
            <div style={{ display: 'flex', flexDirection: 'column' }}>
              <div style={{ color: '#1E1E1E', fontSize: 20, fontWeight: 'bold', marginBottom: 20 }}>Улаанбаатар</div>
              <div style={{ display: 'flex', flexDirection: 'row' }}>
                {/* <img src={LocImg} className="icon-style"/> */}
                <div className="address-text" style={{ color: '#1E1E1E', fontSize: 14, marginLeft: 20 }}></div>
              </div>
              <div style={{ display: 'flex', flexDirection: 'row', marginTop: 25 }}>
                <img src={PhoneImg} className="icon-style"/>
                <div style={{ width: 220, color: '#1E1E1E', fontSize: 14, marginLeft: 20 }}>(+976) 77179977</div>
              </div>
              <div style={{ display: 'flex', flexDirection: 'row', marginTop: 35 }}>
                <img src={EmailImg} style={{ height: 14, width: 17 }}/>
                <div style={{ width: 220, color: '#1E1E1E', fontSize: 14, marginLeft: 20 }}>info@zes-erdene.mn</div>
              </div>
            </div>
            <div style={{ display: 'flex', flexDirection: 'column' }}>
              <div style={{ color: '#1E1E1E', fontSize: 20, fontWeight: 'bold', marginBottom: 20 }}>Эрдэнэт</div>
              <div style={{ display: 'flex', flexDirection: 'row' }}>
                <img src={LocImg} className="icon-style"/>
                <div className="address-text" style={{ color: '#1E1E1E', fontSize: 14, marginLeft: 20}}>Монгол Улс, Орхон аймаг,
                  Баян-Өндөр сум, Цагаанчулуут,
                  11-р баг, 7-р хороолол,
                  Спорт цогцолбор</div>
              </div>
              <div style={{ display: 'flex', flexDirection: 'row', marginTop: 25 }}>
                <img src={PhoneImg} className="icon-style"/>
                <div style={{ display: 'flex', flexDirection: 'column' }}>
                  <div style={{ width: 220, color: '#1E1E1E', fontSize: 14, marginLeft: 20 }}>(+976) 70358008</div>
                  <div style={{ width: 220, color: '#1E1E1E', fontSize: 14, marginLeft: 20 }}>(+976) 70358009</div>
                </div>
              </div>
              <div style={{ display: 'flex', flexDirection: 'row', marginTop: 20 }}>
                <img src={EmailImg} style={{ height: 14, width: 17 }}/>
                <div style={{ width: 220, color: '#1E1E1E', fontSize: 14, marginLeft: 20 }}>info@zes-erdene.mn</div>
              </div>
            </div>
          </div>
        </div>
    </div>
  )
}
