import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import { render } from "react-dom";
import './navbar.scss'

function Navbar(props) {
 const {setActive, active, changeStatus}= props
  return (
    <>
      {/* <SideMenu/> */}
    <div>
        <button onClick={()=>setActive(!active)} className={`hamburgerMenu ${active? "is-active": ""} ${!changeStatus && "is-white"}`}>
            <div className='bar'/>
        </button>
        <div className={`mobile-nav ${active? "is-active": ""}`} >
        <div className='mobile-navLinks-container'>
        <Link onClick={()=>setActive(false)} className='mobile-navLinks' to="/about">КОМПАНИЙН ТУХАЙ</Link>
        <Link onClick={()=>setActive(false)} className='mobile-navLinks' to="/management">КОМПАНИЙН УДИРДЛАГА</Link>
        <Link onClick={()=>setActive(false)} className='mobile-navLinks' to="/news">МЭДЭЭ МЭДЭЭЛЭЛ</Link>
        <Link onClick={()=>setActive(false)} className='mobile-navLinks' to="/construction">БҮТЭЭН БАЙГУУЛАЛТ</Link>
        <Link onClick={()=>setActive(false)} className='mobile-navLinks' to="/work-place">АЖЛЫН БАЙР</Link>
        <Link onClick={()=>setActive(false)} className='mobile-navLinks' to="/purchase">ХУДАЛДАН АВАЛТ</Link>
        <Link onClick={()=>setActive(false)} className='mobile-navLinks' to="/investment">ХУВЬЦАА ЭЗЭМШИГЧИД</Link>
        <Link onClick={()=>setActive(false)} className='mobile-navLinks' to="/environment">БАЙГАЛЬ ОРЧИН</Link>
        </div>
    </div>
    </div>
    </>
  )
}

export default Navbar