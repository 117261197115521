import React, {useEffect, useState} from "react";
import {Link} from "react-router-dom";
import ArrowImg from "../../asset/arrow.png";
import NoImg from "../../asset/no-image.png";
import WorkService from "../../services/workService";
import NewsCard from "../../pages/News/NewsCard";


export default function NewsComponentHome() {
  const [newsList, setNewsList] = useState([]);

  
  const [limit, setLimit]= useState()
  const  baseURL = "/api/zes-service/uploads/";
  const [windowSize, setWindowSize]= useState(getWindowSize())

  useEffect(()=>{
    getData()
    configureLimit()
      function handleWindowResize(){
          setWindowSize(getWindowSize())
      }
      window.addEventListener("resize", handleWindowResize);
      return()=>{
          window.removeEventListener('resize', handleWindowResize)
      }
  },[])

  function configureLimit(){
    if(windowSize.innerWidth <2560 && windowSize.innerWidth> 2000){
      setLimit(130)
    } else if(windowSize.innerWidth >2560){
      setLimit(140)
    } else if (windowSize.innerWidth <2000 && windowSize.innerWidth> 1800 ){
      setLimit(120)
    } else if (windowSize.innerWidth <1800 && windowSize.innerWidth> 1520 ){
      setLimit(100)
    }else if (windowSize.innerWidth <1520 && windowSize.innerWidth> 1048 ){
      setLimit(80)
    } else if (windowSize.innerWidth <1048 && windowSize.innerWidth> 886 ){
      setLimit(60)
    } else if (windowSize.innerWidth <886  && windowSize.innerWidth> 680 ){
      setLimit(120)
    } else if (windowSize.innerWidth <680  && windowSize.innerWidth> 520 ){
      setLimit(90)
    } else if (windowSize.innerWidth <520  && windowSize.innerWidth> 416 ){
      setLimit(70)
    } else if (windowSize.innerWidth <416  && windowSize.innerWidth> 310 ){
      setLimit(50)
    }
  }
  function getWindowSize(){
      const {innerWidth, innerHeight}= window;
      return {innerWidth, innerHeight}
  }



  const getData = async () => {
    let lastList = [];
    let specialList = [];
    let investList = [];

    const result = await WorkService.getNewsList();
    result?.data.forEach(data => {
      data.image_url = baseURL + data.file_name;
      if (data.type === 0) {
        specialList.push(data);
      }
      if (data.type === 1) {
        lastList.push(data)
      }
      if (data.type === 2) {
        investList.push(data);
      }
    });
    setNewsList(result?.data);
  }

   if (newsList?.length === 0) return null
    return(
     <React.Fragment>

       <div className="margin-style-real">
       <div style={{ display: 'flex', justifyContent: "flex-start", alignItems: "center", flexDirection: "column", marginTop: "5rem", marginBottom: "3rem", gap: "1rem" }}>
        <div className="container-title-style-main">МЭДЭЭ, МЭДЭЭЛЭЛ <div className="underline"/></div>   
      </div>
         <div className="newsHome-container" style={{display: "flex", justifyContent: "space-between", marginBottom: "2.5rem"}}>
           {newsList.slice(0, 2).map(element => {
               return (
                <NewsCard limit={limit} createdAt={element.createdAt} title={element.title} content={element.content} id={element.id}/>
               );
           })}
         </div>
         <div style={{width: "100%", display: "flex", justifyContent: "center", }}><button className="newNewsButton"><Link style={{color: "white", textDecoration: "none"}} to="/news">Бүх мэдээллийг харах</Link></button></div>
       </div>

     </React.Fragment>
   )
}
