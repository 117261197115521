import React from 'react'
import {Tabs} from 'antd'
const { TabPane } = Tabs;

function TabComponent({items, list, setKey}) {
    const onChange=(key)=>{
        setKey(key)
}
return (
    <Tabs
    centered={true}
    size="large"
    style={{ display: "flex", justifyContent: "space-between"}}
    defaultActiveKey='1'
    onChange={onChange}
    items={items}
    >
    {list.map((item, index)=>(
        <TabPane
        className='tabPanel'
        tab={
            <div className='tab-span'>
                {item.title}
            </div>
        }
        key={index+1}
        >
        {item.element}
        </TabPane>
    ))}    
    </Tabs>
  )
}

export default TabComponent