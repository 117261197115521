import React from 'react'
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend,
} from 'chart.js';
import { Bar } from 'react-chartjs-2';
import './investment.scss'

function ChartComponent() {
    ChartJS.register(
        CategoryScale,
        LinearScale,
        BarElement,
        Title,
        Tooltip,
        Legend
      );
    const options = {
        responsive: true,
        plugins: {
          legend: {
            position: 'top'
          },
          title: {
            display: true,
            text: '2021 оны санхүүгийн үзүүлэлт',
          },
          elements: {
            borderWidth: 1
          },
          scales: {
            xAxes: [{
                barPercentage: 0.2
            }]
        }
        },
      };
      const labels = ['January', 'February', 'March', 'April', 'May', 'June', 'July'];
      const data = {
        labels,
        datasets: [
          {
            label: 'Dataset 1',
            data: [200,344,234,363,234,32,434,324],
            backgroundColor: '#E75711',
          },
          {
            label: 'Dataset 2',
            data: [40,454,34,363,234,325,424,324],
            backgroundColor: '#4F4F4F',
          },
        ],
      };
  return (
    <div className='margin-style-real chartComponent'>
        <Bar className='bar'  maintainAspectRatio={true}  options={options} data={data} />
        <Bar  className='bar'  maintainAspectRatio={true} options={options} data={data} />
    </div>
  )
}

export default ChartComponent