import * as React from "react";
import { motion } from "framer-motion";
import { Link } from "react-router-dom";
import { useHistory } from "react-router-dom";

const variants = {
  open: {
    y: 0,
    opacity: 1,
    transition: {
      y: { stiffness: 1000, velocity: -100 }
    }
  },
  closed: {
    y: 50,
    opacity: 0,
    transition: {
      y: { stiffness: 1000 }
    }
  }
};

const colors = ["#FF008C", "#D309E1", "#9C1AFF", "#7700FF", "#4400FF"];

export const MenuItem = ({ i, text, to, toggle }) => {
  const style = { border: `2px solid ${colors[i]}` };

  return (
    <motion.li
    className="motion-li"
      variants={variants}
      whileHover={{ scale: 1.1 }}
      whileTap={{ scale: 0.95 }}
    ><Link onClick={()=>toggle(false)} to={to} className="link">
      <div className="text-placeholder" >
       {text}
      </div></Link>
    </motion.li>
  );
};
