import React from 'react'
import long from '../../asset/long.png'
import Marquee from 'react-marquee-slider'
import times from "lodash/times";
import { useState } from 'react';
import arZalaat from '../../asset/arZalaat.png'
import chmm from '../../asset/chmm.png'
import erdenet from '../../asset/erdenet.png'
import gowi from '../../asset/gowi.png'
import hanerchim from '../../asset/hanerchim.png'
import landTest from '../../asset/landTest.png'
import msltt from '../../asset/msltt.png'
import NMP from '../../asset/NMP.png'
import shigi from '../../asset/shigi.png'
import SmartPlus from '../../asset/SmartPlus.png'
import orem from '../../asset/orem.png'
import orhon from '../../asset/orhonHynalt.png'

const list=[arZalaat, chmm, erdenet, gowi, hanerchim, landTest, msltt, NMP, shigi,SmartPlus, orem, orhon]

function SliderOrg() {
  return (
    <div style={{marginTop: "3rem"}}>
        <Marquee velocity={20}>
            {list.map((item, index)=>(
                <img className='marquee-img' style={{objectFit: "scale-down", height: "5rem", width: "5rem", marginRight: "5rem"}} src={item} key={index}/>
            ))}
        </Marquee>
    </div>
  )
}

export default SliderOrg