import React, {useEffect, useState} from "react"
import {Link} from "react-router-dom"
import WorkService from "../../services/workService"
import LatestNewsCard from "./LatestNewsCard"
import NewsCard from "./NewsCard"
import "./newsStyle.scss"
export default function NewsList() {
  const [limit, setLimit] = useState()
  const [newsList, setNewsList] = useState([])
  const [windowSize, setWindowSize] = useState(getWindowSize())

  useEffect(() => {
    getData()
    configureLimit()
    function handleWindowResize() {
      setWindowSize(getWindowSize())
    }
    window.addEventListener("resize", handleWindowResize)
    return () => {
      window.removeEventListener("resize", handleWindowResize)
    }
  }, [])

  function configureLimit() {
    if (windowSize.innerWidth < 2560 && windowSize.innerWidth > 2000) {
      setLimit(130)
    } else if (windowSize.innerWidth > 2560) {
      setLimit(140)
    } else if (windowSize.innerWidth < 2000 && windowSize.innerWidth > 1800) {
      setLimit(120)
    } else if (windowSize.innerWidth < 1800 && windowSize.innerWidth > 1520) {
      setLimit(100)
    } else if (windowSize.innerWidth < 1520 && windowSize.innerWidth > 1048) {
      setLimit(80)
    } else if (windowSize.innerWidth < 1048 && windowSize.innerWidth > 886) {
      setLimit(60)
    } else if (windowSize.innerWidth < 886 && windowSize.innerWidth > 680) {
      setLimit(120)
    } else if (windowSize.innerWidth < 680 && windowSize.innerWidth > 520) {
      setLimit(90)
    } else if (windowSize.innerWidth < 520 && windowSize.innerWidth > 416) {
      setLimit(70)
    } else if (windowSize.innerWidth < 416 && windowSize.innerWidth > 310) {
      setLimit(50)
    }
  }
  function getWindowSize() {
    const {innerWidth, innerHeight} = window
    return {innerWidth, innerHeight}
  }

  const baseURL = "/api/zes-service/uploads/"
  useEffect(() => {
    getData()
  }, [])

  const getData = async () => {
    let lastList = []
    let specialList = []
    let investList = []

    const result = await WorkService.getNewsList()

    result?.data?.forEach((data) => {
      data.image_url = baseURL + data.file_name
      if (data.type === 0) {
        specialList.push(data)
      }
      if (data.type === 1) {
        lastList.push(data)
      }
      if (data.type === 2) {
        investList.push(data)
      }
    })
    setNewsList(result?.data)
  }
  return (
    <div className="margin-style-real">
      <div
        style={{
          display: "flex",
          justifyContent: "flex-start",
          flexDirection: "column",
          marginTop: "5rem",
          marginBottom: "3rem",
          gap: "1rem",
        }}
      >
        <div className="container-title-style-main">МЭДЭЭ МЭДЭЭЛЭЛ</div>
        <div
          style={{backgroundColor: "#BF8975", height: "2px", width: "14rem"}}
        />
      </div>
      <div className="newsContainer">
        <div className="left-container">
          {newsList.map((item, index) => (
            <NewsCard limit={limit} key={index} {...item} />
          ))}
        </div>
        <div className="right-container">
          <div className="title">Сүүлд нэмэгдсэн</div>
          <div className="cards">
            {newsList.map((item, index) => (
              <LatestNewsCard limit={60} key={index} {...item} />
            ))}
          </div>
        </div>
      </div>
      <div
        style={{
          width: "100%",
          display: "flex",
          justifyContent: "center",
          marginTop: "3rem",
        }}
      >
        <button className="newNewsButton">
          <Link style={{color: "white", textDecoration: "none"}} to="/news">
            Цааш харах
          </Link>
        </button>
      </div>
    </div>
  )
}
