import React from 'react'
import { useState } from 'react'

function ConstructionCard({src, shadow, index, margin, text, color}) {
 const [active, setActive]= useState(false)
  return (
    <div style={{ 
      // backgroundImage: 'url('+src+')', 
      background: color,
    marginTop: 40*margin }} onClick={()=>setActive(true)} className={`constructor-subCont-img ${active ? "active": ""}`}>
    <div className="shadow" onClick={()=>setActive(false)}>
      {shadow.map((item, index)=>(
        <div>{item}</div>
      ))}
    </div>
    <div style={{ display: 'flex', width: '100%', justifyContent: 'flex-end' }}>
        <div style={{ width: 50, height: 50, backgroundColor: '#1B1D24', display: 'flex', justifyContent: 'center', alignItems: 'center', zIndex:11}}>
          <span style={{ color: '#FFFFFF', fontSize: 13 }}>0{index+1}</span>
        </div>
    </div>
    <div className="text">{text}</div>
    </div>
  )
}

export default ConstructionCard