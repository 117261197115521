import React, {useEffect, useState, useRef} from "react";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import TableBody from "@mui/material/TableBody";
import TableContainer from "@mui/material/TableContainer";
import {DateInput, CheckBox, Form, FormField, TextInput} from 'grommet';
import PlusImg from "../../asset/plusIcon.png";
import { Modal } from "antd";
import Select from 'react-select'
import {Container, Row, Col} from 'react-grid-system';

import {
    skillList,
    degreeList,
    workDirection,
    workLocation,
    workPosition,
    genderSelect,
    familyDefinition, Country
} from "./staticData";
import WorkService from "../../services/workService";
import moment from "moment";
import {toast, ToastContainer} from "react-toastify";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

export default function RequestForm() {

    const [elSchoolList, setElSchoolList] = useState([]);
    const [unSchoolList, setUnSchoolList] = useState([]);
    const [courseSchoolList, setCourseSchoolList] = useState([]);
    const [lanSchoolList, setLanSchoolList] = useState([]);
    const [expSchoolList, setExpSchoolList] = useState([]);
    const [prizeList, setPrizeList] = useState([]);
    const [sportList, setSportList] = useState([]);
    const [skillListData, setSkillListData] = useState([]);
    const [defSchoolList, setDefSchoolList] = useState([]);
    const [familyList, setFamilyList] = useState([]);
    const [workCompanyPosition, setWorkCompanyPosition] = useState('');
    const [branch, setBranch] = useState('');
    const [value, setValue] = useState(workLocation[0]);
    const [valueDir, setValueDir] = useState(workDirection[0]);
    const [valuePos, setValuePos] = useState(workPosition[0]);
    const [valueGender, setValueGender] = useState(genderSelect[0]);
    const [maxSalary, setMaxSalary] = useState('');
    const [minSalary, setMinSalary] = useState('');
    const [availableDate, setAvailableDate] = useState(new Date())
    const [bornDate, setBornDate] = useState(new Date())
    const [checked, setChecked] = useState(true);
    const [open, setOpen] = React.useState(false);
    const [isVisible, setIsVisible] = useState(false);
    const [isVisible1, setIsVisible1] = useState(false);
    const [isVisible2, setIsVisible2] = useState(false);
    const [isVisible3, setIsVisible3] = useState(false);
    const [showPrize, setShowPrize] = useState(false);
    const [showSport, setShowSport] = useState(false);
    const [showSkill, setShowSkill] = useState(false);
    const [selectedType, setSelectedType] = useState(null);
    const [personData, setPersonData] = useState({
        surname: '',
        lastName: '',
        firstName: '',
        regNumber: '',
        phone1: '',
        phone2: '',
        email: '',
        bornPlace: '',
        address: '',
        facebook: '',
        instagram: '',
        twitter: '',
        linkid: '',
    });
    const [modalPosition, setModalPosition] = useState();
    const [profession, setProfession] = useState();
    const [gpa,         setGpa] = useState();
    const [modalSchool, setModalSchool] = useState('');
    const [modalDirection, setModalDirection] = useState('');
    const [modalStartDate, setModalStartDate] = useState(new Date());
    const [modalEndDate, setModalEndDate] = useState(new Date());
    const [modalDegree, setModalDegree] = useState(degreeList[0]);
    const [modalRate, setModalRate] = useState('');
    const [speak, setSpeak] = useState(skillList[9]);
    const [listen, setListen] = useState(skillList[9]);
    const [write, setWrite] = useState(skillList[9]);
    const [read, setRead] = useState(skillList[9]);
    const [lanName, setLanName] = useState('');
    const [expCompanyName, setExpCompanyName] = useState('');
    const [expCompanyTitle, setCompanyTitle] = useState('');
    const [expCompanyDir, setCompanyDir] = useState('');
    const [expCompanyRes, setCompanyRes] = useState('');
    const [defFullName, setDefFullName] = useState('');
    const [defCompanyName, setDefCompanyName] = useState('');
    const [defCompanyTitle, setDefCompanyTitle] = useState('');
    const [defPhone, setDefPhone] = useState('');
    const [defEmail, setDefEmail] = useState('');

    const [prizeName, setPrizeName] = useState('');
    const [prizeYear, setPrizeYear] = useState('');
    const [prizeOrg,  setPrizeOrg] = useState('');

    const [sportName,   setSportName] = useState('');
    const [sportYear,   setSportYear] = useState('');
    const [sportDegree, setSportDegree] = useState('');

    const [skillName,   setSkillName] = useState('');
    const [skillBad,     setSkillBad] = useState('');
    const [skillGood,   setSkillGood] = useState('');

    const [hrefId, setHrefId] = useState(null);
    const [famDet, setFamDet] = useState('');
    const [famLastName, setFamLastName] = useState('');
    const [famFirstName, setFirstName] = useState('');
    const [famRegNo, setFamRegNo] = useState('');
    const [famCurWork, setFamCurWork] = useState('');
    const [famPhone, setFamPhone] = useState('');
    const [resultData, setResultData] = useState(null);

    const fileRefAvatar = useRef();
    const fileRefCertificate = useRef();
    const fileRefCitizenIdCard = useRef();


    useEffect(() => {
        // window.scrollTo(0, 0)
    }, [])

    useEffect(() => {
        const location = window.location.href;
        const id = location.substring(location.lastIndexOf('/') + 1);
        setHrefId(id);
        getData(id);
    }, [window.location.href]);

    const getData = async (id) => {
        const result = await WorkService.getWorkById(id);
        setResultData(result[0]);
        setBranch(result[0].branch);
        setWorkCompanyPosition(result[0].position);
        setValue(result[0].location);
    }

    const openModal = (type) => {
        setSelectedType(type);
        setOpen(true);
    }

    const setData = () => {
        if (selectedType === 1) {
            let list = [...elSchoolList];
            let data = {
                where: modalPosition.value,
                name: modalSchool,
                startDate: modalStartDate,
                endDate: modalEndDate,
                degree: modalDegree.value,
                rate: modalRate,
                course_info: modalDirection,
            }

            list.push(data);
            setElSchoolList(list);
            setOpen(false);
        }
        if (selectedType === 2) {
            let list = [...unSchoolList];
            let data = {
                where: modalPosition.value,
                name: modalSchool,
                startDate: modalStartDate,
                endDate: modalEndDate,
                degree: modalDegree.value,
                rate: modalRate,
            }

            list.push(data);
            setUnSchoolList(list);
            setOpen(false);
        }
        if (selectedType === 3) {
            let list = [...courseSchoolList]
            let data = {
                where: modalPosition.value,
                course_name: modalSchool,
                start_date: modalStartDate,
                end_date: modalEndDate,
                course_info: modalDirection,
                certificate_no: modalRate,
            }
            list.push(data);
            setCourseSchoolList(list);
            setOpen(false);
        }

        setModalSchool('');
        setModalDirection('');
        setModalStartDate(new Date());
        setModalEndDate(new Date());
        setModalDegree(degreeList[0]);
        setModalRate('');
    }

    const setLanguage = () => {
        let list = [...lanSchoolList];
        let data = {
            name: lanName,
            speak: speak.value,
            listen: listen.value,
            write: write.value,
            read: read.value
        }

        setLanName('');
        list.push(data);
        setLanSchoolList(list);
        setIsVisible(false);
    }

    const setExpCompany = () => {
        let list = [...expSchoolList];

        let data = {
            name: expCompanyName,
            title: expCompanyTitle,
            startDate: modalStartDate,
            endDate: modalEndDate,
            direction: expCompanyDir,
            reason: expCompanyRes,
        }
        list.push(data);
        setExpSchoolList(list);
        setExpCompanyName('');
        setCompanyTitle('');
        setCompanyTitle('');
        setCompanyDir('');
        setCompanyRes('');
        setIsVisible1(false);
    }

    const setDefCompany = () => {
        let list = [...defSchoolList];
        let data = {
            fullName: defFullName,
            name: defCompanyName,
            position: defCompanyTitle,
            phone: defPhone,
            email: defEmail,
        }
        list.push(data);
        setDefSchoolList(list);
        setDefFullName('');
        setDefCompanyName('');
        setDefCompanyTitle('');
        setDefPhone('');
        setDefEmail('');
        setIsVisible2(false);
    }

    const setFamilyData = () => {
        let list = [...familyList];
        let data = {
            det: famDet.value,
            first_name: famFirstName,
            last_name: famLastName,
            reg_no: famRegNo,
            cur_work: famCurWork,
            phone: famPhone,
        }
        list.push(data);
        setFamilyList(list);
        setFirstName('');
        setFamLastName('');
        setFamRegNo('');
        setFamCurWork('');
        setFamPhone('');
        setIsVisible3(false);
    }
    const validEmail = new RegExp(
        '^[a-zA-Z0-9._:$!%-]+@[a-zA-Z0-9.-]+.[a-zA-Z]$'
    );

    const validRegno = new RegExp(
        '^[а-яА-яөӨүҮйё]{2}[0-9]{8}$'
    );
    const validPhone = new RegExp('^[0-9]{8,11}$');


    const changeSurName = (event) => {
        if (event.target.value !== null) {
            let human = {...personData};
            human.surname = event.target.value;
            setPersonData(human);
        }
    }

    const changeLastName = (event) => {
        if (event.target.value !== null) {
            let human = {...personData};
            human.lastName = event.target.value;
            setPersonData(human);
        }
    }

    const changeFirstName = (event) => {
        if (event.target.value !== null) {
            let human = {...personData};
            human.firstName = event.target.value;
            setPersonData(human);
        }
    }

    const changeRegNumber = (event) => {
        if (event.target.value !== null) {
            let human = {...personData};
            human.regNumber = event.target.value.toUpperCase();
            setPersonData(human);
        }
    }

    const changeBornPlace = (event) => {
        if (event.target.value !== null) {
            let human = {...personData};
            human.bornPlace = event.target.value;
            setPersonData(human);
        }
    }

    const changePhone1 = (event) => {
        if (event.target.value !== null) {
            let human = {...personData};
            human.phone1 = event.target.value;
            setPersonData(human);
        }
    }

    const changePhone2 = (event) => {
        if (event.target.value !== null) {
            let human = {...personData};
            human.phone2 = event.target.value;
            setPersonData(human);
        }
    }

    const changeEmail = (event) => {
        if (event.target.value !== null) {
            let human = {...personData};
            human.email = event.target.value;
            setPersonData(human);
        }
    }

    const changeAddress = (event) => {
        if (event.target.value !== null) {
            let human = {...personData};
            human.address = event.target.value;
            setPersonData(human);
        }
    }

    const changeFaceBook = (event) => {
        if (event.target.value !== null) {
            let human = {...personData};
            human.facebook = event.target.value;
            setPersonData(human);
        }
    }

    const changeInsta = (event) => {
        if (event.target.value !== null) {
            let human = {...personData};
            human.instagram = event.target.value;
            setPersonData(human);
        }
    }

    const changeTwitter = (event) => {
        if (event.target.value !== null) {
            let human = {...personData};
            human.twitter = event.target.value;
            setPersonData(human);
        }
    }

    const changeLinked = (event) => {
        if (event.target.value !== null) {
            let human = {...personData};
            human.linkid = event.target.value;
            setPersonData(human);
        }
    }

    const handleChangeSchool = (event) => {
        setModalSchool(event.target.value);
    }

    const handleChangeDir = (event) => {
        setModalDirection(event.target.value);
    }

    const handleChangeRate = (event) => {
        setModalRate(event.target.value);
    }

    const handleChangeLan = (event) => {
        setLanName(event.target.value);
    }

    const handleExpName = (event) => {
        setExpCompanyName(event.target.value);
    }

    const handleExpTitle = (event) => {
        setCompanyTitle(event.target.value);
    }

    const handleExpDir = (event) => {
        setCompanyDir(event.target.value);
    }

    const handleExpRes = (event) => {
        setCompanyRes(event.target.value);
    }

    const handleDefFullName = (event) => {
        setDefFullName(event.target.value);
    }

    const handleDefName = (event) => {
        setDefCompanyName(event.target.value);
    }

    const handleDefTitle = (event) => {
        setDefCompanyTitle(event.target.value);
    }

    const handleDefPhone = (event) => {
        setDefPhone(event.target.value);
    }

    const handleDefEmail = (event) => {
        setDefEmail(event.target.value);
    }

    const handleWorkCompany = (event) => {
        setWorkCompanyPosition(event.target.value);
    }

    const handleMinSalary = (event) => {
        setMinSalary(event.target.value);
    }

    const handleMaxSalary = (event) => {
        setMaxSalary(event.target.value);
    }

    const removeElItem = (data) => {
        let list = [...elSchoolList];
        const index = list.findIndex(element => element.name === data.name);
        list.splice(index, 1);
        setElSchoolList(list);
    }

    const removeFamilyItem = (data) => {
        let list = [...familyList];
        const index = list.findIndex(element => element.name === data.name);
        list.splice(index, 1);
        setFamilyList(list);
    }
    const removePrizeItem = (data) => {
        let list = [...prizeList];
        const index = list.findIndex(element => element.name === data.name);
        list.splice(index, 1);
        setPrizeList(list);
    }
    const removeSportItem = (data) => {
        let list = [...sportList];
        const index = list.findIndex(element => element.name === data.name);
        list.splice(index, 1);
        setSportList(list);
    }
    const removeSkillItem = (data) => {
        let list = [...skillListData];
        const index = list.findIndex(element => element.name === data.name);
        list.splice(index, 1);
        setSkillListData(list);
    }

    const removeUnItem = (data) => {
        let list = [...elSchoolList];
        const index = list.findIndex(element => element.name === data.name);
        list.splice(index, 1);
        setElSchoolList(list);
    }

    const removeCourseItem = (data) => {
        let list = [...courseSchoolList];
        const index = list.findIndex(element => element.name === data.name);
        list.splice(index, 1);
        setCourseSchoolList(list);
    }

    const removeLanItem = (data) => {
        let list = [...lanSchoolList];
        const index = list.findIndex(element => element.name === data.name);
        list.splice(index, 1);
        setLanSchoolList(list);
    }

    const removeExpItem = (data) => {
        let list = [...expSchoolList];
        const index = list.findIndex(element => element.name === data.name);
        list.splice(index, 1);
        setExpSchoolList(list);
    }

    const removeDefItem = (data) => {
        let list = [...defSchoolList];
        const index = list.findIndex(element => element.name === data.name);
        list.splice(index, 1);
        setDefSchoolList(list);
    }

    const saveData = async () => {

        if (!validRegno.test(personData.regNumber)) {
            return toast("Регистрийн дугаараа оруулна уу!", {type: 'error'});
        }
        const candidateResult = await WorkService.checkCandidate({
            work_definition_id: hrefId,
            reg_number: personData.regNumber
        });
        if(candidateResult.data.sent === 1){
            return toast('Та өмнө нь энэ ажлын байран дээр мэдээлэл илгээсэн байна!', {type: 'error'})
        }else {
            // expSchoolList.push({expYear: personData.expYear,expReason: personData.expReason})
            // console.log(expSchoolList);
            // return
            if (personData.address.length < 2) {
                return toast('Оршин суугаа хаягын мэдээллээ оруулна уу!', {type: 'error'})
            }
            if (personData.bornPlace.length < 2) {
                return toast('Төрсөн газрын мэдээллээ оруулна уу!', {type: 'error'})
            }
            if (personData.firstName.length < 2) {
                return toast('Өөрийн нэрийг оруулна уу!', {type: 'error'})
            }
            if (personData.lastName.length < 2) {
                return toast('Эцэг эхийн нэрээ оруулна уу!', {type: 'error'})
            }
            if (!validPhone.test(personData.phone1)) {
                return toast('Өөрийн утасны дугаараа оруулна уу!', {type: 'error'})
            }
            if (!validPhone.test(personData.phone2)) {
                return toast('Яаралтай үед холбоо барих хүний дугаар оруулна уу!', {type: 'error'})
            }
            if (personData.surname.length < 2) {
                return toast('Мэдээлэл дутуу байна ахин шалгана уу!', {type: 'error'})
            }

            if (!validEmail.test(personData.email)) {
                return toast("И-мэйл хаяг буруу байна !", {type: 'error'});
            }

            if (familyList.length < 1){
                return toast("Гэр бүлийн тухай мэдээллээ оруулна уу!", {type: 'error'});
            }
            if (elSchoolList.length < 1){
                return toast("Боловсрол эзэмшсэн байдлын тухай мэдээллээ оруулна уу!", {type: 'error'});
            }
            if (expSchoolList.length < 1){
                return toast("Ажлын туршлагатай холбоотой мэдээллээ оруулна уу !", {type: 'error'});
            }
            if (defSchoolList.length < 1){
                return toast("Ажлын байдлын тодорхойлолт өгөх хүний мэдээллээ оруулна уу !", {type: 'error'});
            }
            if (skillListData.length < 1){
                return toast("Ур чадвар, давуу болон сул талын мэдээллээ оруулна уу!", {type: 'error'});
            }

            let dataAvatar = null;
            let dataCertificate = null;
            let dataCitizenIdCard = null;

            // if (fileRefAvatar.current.size < 20000001) {
            //     return toast("Цээж зурагны хэмжээ их байна", {type: 'error'});
            // }
            // if (fileRefCertificate.current.size < 20000001) {
            //     return toast("Боловсролын дипломын хэмжээ их байна", {type: 'error'});
            // }
            // if (fileRefCitizenIdCard.current.size < 20000001) {
            //     return toast("Иргэний үнэмлэхний хуулбарын хэмжээ их байна", {type: 'error'});
            // }

            if (fileRefAvatar.current) {
                let fileData = new FormData();
                fileData.append("file", fileRefAvatar.current);

                const res = await WorkService.uploadFile(fileData);
                console.log(res);
                dataAvatar = res.data.id;
            }
            if (fileRefCertificate.current) {
                let fileData = new FormData();
                fileData.append("file", fileRefCertificate.current);
                const res = await WorkService.uploadFile(fileData);
                dataCertificate = res.data.id;
            }
            if (fileRefCitizenIdCard.current) {
                let fileData = new FormData();
                fileData.append("file", fileRefCitizenIdCard.current);
                const res = await WorkService.uploadFile(fileData);
                dataCitizenIdCard = res.data.id;
            }

            const data = {
                work_definition_id: hrefId,
                work_place: workCompanyPosition,
                branch: branch,
                location: value,
                info_channel: valueDir.value,
                position: valuePos.value,
                available_date: availableDate,
                max_salary: maxSalary,
                min_salary: minSalary,
                sur_name: personData.surname,
                last_name: personData.lastName,
                first_name: personData.firstName,
                register: personData.regNumber,
                gender: valueGender.value,
                born_place: personData.bornPlace,
                born_date: bornDate,
                phone: personData.phone1,
                emergency_phone: personData.phone2,
                email: personData.email,
                address: personData.address,
                social: [
                    {name: 'facebook', value: personData.facebook},
                    {name: 'twitter', value: personData.twitter},
                    {name: 'instagram', value: personData.instagram},
                    {name: 'linkedin', value: personData.linkid}
                ],
                family: familyList,
                education: elSchoolList,
                skill_develop: courseSchoolList,
                language: lanSchoolList,
                experience: expSchoolList,
                old_leader: defSchoolList,
                prize: prizeList,
                sport: sportList,
                skill: skillListData,
                avatar_file_id: dataAvatar,
                certificate_file_id: dataCertificate,
                citizen_id_card_file_id: dataCitizenIdCard,
            }

            const result = await WorkService.addCadidate(data);
            if (result.success) {
                if (result.code === 300) {
                    return toast("Хэрэглэгч бүртгэлтэй байна.", {type: 'success'});
                } else {
                    return toast("Амжилттай бүртгэллээ", {type: 'success'});
                }
            }
        }

    }

    const changeFileRefAvatar = event => {
        fileRefAvatar.current = event.target.files[0];
    };
    const changeFileRefCertificate = event => {
        fileRefCertificate.current = event.target.files[0];
    };
    const changeFileRefCitizenIdCard = event => {
        fileRefCitizenIdCard.current = event.target.files[0];
    };

    const style = {
        textall: {fontFamily: 'Roboto', fontSize: '15'},
        warningContainer: {
            backgroundColor: '#FFE8E6',
            display: 'flex',
            flexDirection: 'column',
            padding: '2%'
        },
        warningTextInfo: {
            color: '#FC0202',
            fontWeight: '400',
            fontSize: '25px'
        },
        rowDirection: {display: 'flex', flexDirection: 'row', paddingTop: '10px'},
        numberContainer: {
            backgroundColor: '#FC0202',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            width: '20px',
            minWidth: '20px',
            minHeight: '20px',
            height: '20px',
            borderRadius: '20px',
            color: '#FFFFFF',
            fontWeight: 'bold',
            fontWize: '12px',
        },
        warningText: {
            color: '#666',
            fontWeight: 400,
            marginLeft: '20px'
        },
        labelStyle: {display: 'flex', flexDirection: 'row', marginTop: '2%'},

        labelStyleLong: {display: 'flex', flexDirection: 'row', marginTop: '1%'},
        selectStyleLong: {width: '100%', height: 38, borderRadius: 5, border: '0.5px solid hsl(0, 0%, 80%)', marginTop: '1%'},
        inputStyleNoLabelLong: {
            width: '100%',
            height: 38,
            borderRadius: 5,
            border: '0.5px solid hsl(0, 0%, 80%)',
            marginTop: '1%'
        },
        selectStyle: {width: '100%', height: 38, borderRadius: 5, border: '0.5px solid hsl(0, 0%, 80%)', marginTop: '2%'},
        selectStyleNoLabel: {width: '100%', height: 38, borderRadius: 5, marginTop: '2%'},
        inputStyle: {
            width: '100%',
            height: 38,
            borderRadius: 5,
            border: '0.5px solid hsl(0, 0%, 80%)',
            marginTop: '2%'
        },
        inputStyleNoLabel: {
            width: '100%',
            height: 38,
            borderRadius: 5,
            border: '0.5px solid hsl(0, 0%, 80%)',
            marginTop: '2%'
        },
        cpt2: {paddingTop: '2%'},

    }

    return (
        <React.Fragment>
            <div style={style.textall}>

                <Modal open={open} onCancel={()=>setOpen(false)} className="form-modal"  footer={null}>
                    <div className="modal-content">
                        <div className="modal-header">
                            {selectedType === 1 && <div className="title-style">Боловсрол</div>}
                            {selectedType === 3 && <div className="title-style">Мэргэжил дээшлүүлсэн байдал</div>}
                          
                        </div>

                        <div className="modal-body">
                            <Row>
                                <Col sm={6}>
                                    <div className="form-control-label">Байршил:</div>
                                    <div className="">
                                        <Select
                                            options={Country}
                                            placeholder="сонгох"
                                            value={modalPosition}
                                            onChange={(value) => setModalPosition(value)}
                                        />
                                    </div>
                                </Col>
                                <Col sm={6}>
                                    {selectedType === 1 && <div className="form-control-label">Сургуулийн нэр:</div>}
                                    {selectedType === 3 && <div className="form-control-label">Байгууллагын нэр:</div>}
                                    <div className="form-control">
                                        <input type="text" placeholder="Нэр" value={modalSchool}
                                               onChange={handleChangeSchool}
                                               className="form-input"/>
                                    </div>
                                </Col>
                                <Col sm={6}>
                                    <div className="form-control-label">Элссэн он:</div>
                                    <DatePicker
                                        selected={modalStartDate}
                                        onChange={(date) => setModalStartDate(date)}
                                        peekNextMonth
                                        showMonthDropdown
                                        showYearDropdown
                                        dropdownMode="select"
                                        className="date-input"
                                    />
                                </Col>
                                <Col sm={6}>
                                    <div className="form-control-label">Төгссөн он:</div>
                                    <DatePicker
                                        selected={modalEndDate}
                                        onChange={(date) => setModalEndDate(date)}
                                        peekNextMonth
                                        showMonthDropdown
                                        showYearDropdown
                                        dropdownMode="select"
                                        className="date-input"
                                    />
                                </Col>

                                {selectedType === 1 &&<Col sm={6}>
                                    <div className="form-control-label">Боловсролын зэрэг:</div>
                                    <Select
                                        options={degreeList}
                                        value={modalDegree}
                                        onChange={(value) => setModalDegree(value)}
                                    />
                                </Col>}

                                <Col sm={6}>
                                    <div
                                        className="form-control-label">{selectedType === 3 ? 'Чиглэл:' : 'Мэргэжил'}</div>
                                    <div className="form-control">
                                        <input type="text" placeholder={selectedType === 3 ? 'Чиглэл:' : 'Мэргэжил'}
                                               value={modalDirection}
                                               onChange={handleChangeDir}
                                               className="form-input"/>
                                    </div>
                                </Col>
                                <Col sm={6}>
                                    {selectedType === 1 && <div className="form-control-label">Үнэлгээ:</div>}
                                    {selectedType === 3 && <div className="form-control-label">Сертификатын дугаар:</div>}
                                    <div className="form-control">
                                        <input type={selectedType === 1 ? "number" : "text"}
                                               placeholder="Үнэлгээ"
                                               value={modalRate}
                                               onChange={handleChangeRate}
                                               className="form-input"/>
                                    </div>
                                </Col>
                            </Row>
                        </div>
                        <div className="modal-footer">
                            <div className="btn-style">
                                <div className="border-style" onClick={() => setData()}>
                                    Хадгалах
                                </div>
                            </div>
                        </div>
                    </div>
                </Modal>

                <Modal  footer={null} open={isVisible} onCancel={()=>setIsVisible(false)} className="form-modal">
                    <div className="modal-content">
                        <div className="modal-header"> 
                            <div className="title-style">Гадаад хэлний мэдлэг</div>
                        </div>
                        <div className="modal-body">
                            <Row>
                                <Col sm={4}>
                                    <div className="form-control-label">Гадаад хэлний нэр</div>
                                    <div className="form-control">
                                        <input type="text" placeholder="Нэр" value={lanName} onChange={handleChangeLan}
                                               className="form-input"/>
                                    </div>
                                </Col>
                                <Col sm={2}>
                                    <div className="form-control-label">Сонсох</div>
                                    <div className="">
                                        <Select
                                            options={skillList}
                                            value={listen}
                                            onChange={(value) => setListen(value)}
                                        />
                                    </div>
                                </Col>
                                <Col sm={2}>
                                    <div className="form-control-label">Ярих</div>
                                    <div className="">
                                        <Select
                                            options={skillList}
                                            value={speak}
                                            onChange={(value) => setSpeak(value)}
                                        />
                                    </div>
                                </Col>
                                <Col sm={2}>
                                    <div className="form-control-label">Бичих</div>
                                    <div className="">
                                        <Select
                                            options={skillList}
                                            value={write}
                                            onChange={(value) => setWrite(value)}
                                        />
                                    </div>
                                </Col>
                                <Col sm={2}>
                                    <div className="form-control-label">Унших</div>
                                    <div className="">
                                        <Select
                                            options={skillList}
                                            value={read}
                                            onChange={(value) => setRead(value)}
                                        />
                                    </div>
                                </Col>
                            </Row>
                        </div>
                        <div className="modal-footer">
                            <div className="btn-style">
                                <div className="border-style" onClick={() => setLanguage()}>
                                    Хадгалах
                                </div>
                            </div>
                        </div>
                    </div>
                </Modal>

                <Modal open={isVisible1} onCancel={()=>setIsVisible1(false)} className="form-modal"  footer={null}>
                    <div className="modal-content">
                        <div className="modal-header">
                            <div className="title-style">Таны ажлын туршлага</div>
                        </div>
                        <div className="modal-body">
                            <div className="form-group">
                                <div className="form-control-label">Байгууллагын нэр:</div>
                                <div className="form-control">
                                    <input type="text" placeholder="Нэр" value={expCompanyName} onChange={handleExpName}
                                           className="form-input"/>
                                </div>
                            </div>
                            <div className="form-group">
                                <div className="form-control-label">Албан тушаал:</div>
                                <div className="form-control">
                                    <input type="text" placeholder="Албан тушаал" value={expCompanyTitle}
                                           onChange={handleExpTitle}
                                           className="form-input"/>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-md-6">
                                    <div className="form-group">
                                        <div className="form-control-label">Орсон он:</div>
                                        {/*<div className="form-control">*/}
                                        {/*  <DateInput*/}
                                        {/*    format="mm/dd/yyyy"*/}
                                        {/*    value={modalStartDate}*/}
                                        {/*    onChange={({ value }) => {setModalStartDate(value)}}*/}
                                        {/*  />*/}
                                        <DatePicker
                                            selected={modalStartDate}
                                            onChange={(date) => setModalStartDate(date)}
                                            peekNextMonth
                                            showMonthDropdown
                                            showYearDropdown
                                            dropdownMode="select"
                                            className="date-input"
                                        />
                                        {/*</div>*/}
                                    </div>
                                </div>
                                <div className="col-md-6">
                                    <div className="form-group">
                                        <div className="form-control-label">Гарсан он:</div>
                                        {/*<div className="form-control">*/}
                                        {/*  <DateInput*/}
                                        {/*    format="mm/dd/yyyy"*/}
                                        {/*    value={modalEndDate}*/}
                                        {/*    onChange={({ value }) => {setModalEndDate(value)}}*/}
                                        {/*  />*/}
                                        <DatePicker
                                            selected={modalEndDate}
                                            onChange={(date) => setModalEndDate(date)}
                                            peekNextMonth
                                            showMonthDropdown
                                            showYearDropdown
                                            dropdownMode="select"
                                            className="date-input"
                                        />
                                        {/*</div>*/}
                                    </div>
                                </div>
                            </div>
                            <div className="form-group">
                                <div className="form-control-label">Ажлын үүрэг:</div>
                                <div className="form-control">
                                    <input type="text" placeholder="үүрэг" value={expCompanyDir} onChange={handleExpDir}
                                           className="form-input"/>
                                </div>
                            </div>
                            <div className="form-group">
                                <div className="form-control-label">Ажлаас гарсан шалтгаан:</div>
                                <div className="form-control">
                                    <input type="text" placeholder="шалтгаан" value={expCompanyRes}
                                           onChange={handleExpRes}
                                           className="form-input"/>
                                </div>
                            </div>
                        </div>
                        <div className="modal-footer">
                            <div className="btn-style">
                                <div className="border-style" onClick={() => setExpCompany()}>
                                    Хадгалах
                                </div>
                            </div>
                        </div>
                    </div>
                </Modal>

                <Modal open={isVisible2} onCancel={()=>setIsVisible2(false)} className="form-modal"  footer={null}>
                    <div className="modal-content">
                        <div className="modal-header">
                            <div className="title-style">Таны ажил байдлын тодорхойлолт өгөх хүний мэдээлэл</div>
                           
                        </div>
                        <div className="modal-body">
                            <Row>
                                <Col sm={4}>
                                    <div className="form-control-label">Овог нэр:</div>
                                    <div className="form-control">
                                        <input type="text" placeholder="Нэр" value={defFullName}
                                               onChange={handleDefFullName}
                                               className="form-input"/>
                                    </div>
                                </Col>
                                <Col sm={4}>
                                    <div className="form-control-label">Байгууллагын нэр:</div>
                                    <div className="form-control">
                                        <input type="text" placeholder="Нэр" value={defCompanyName} onChange={handleDefName}
                                               className="form-input"/>
                                    </div>
                                </Col>
                                <Col sm={4}>
                                    <div className="form-control-label">Албан тушаал:</div>
                                    <div className="form-control">
                                        <input type="text" placeholder="Тушаал" value={defCompanyTitle}
                                               onChange={handleDefTitle}
                                               className="form-input"/>
                                    </div>
                                </Col>
                                <Col sm={4}>
                                    <div className="form-control-label">Утас:</div>
                                    <div className="form-control">
                                        <input type="number" placeholder="Утас" value={defPhone} onChange={handleDefPhone}
                                               className="form-input"/>
                                    </div>
                                </Col>
                                <Col sm={4}>
                                    <div className="form-control-label">Имэйл:</div>
                                    <div className="form-control">
                                        <input type="text" placeholder="Имэйл" value={defEmail} onChange={handleDefEmail}
                                               className="form-input"/>
                                    </div>
                                </Col>
                            </Row>
                        </div>
                        <div className="modal-footer">
                            <div className="btn-style">
                                <div className="border-style" onClick={() => setDefCompany()}>
                                    Хадгалах
                                </div>
                            </div>
                        </div>
                    </div>
                </Modal>

                <Modal open={isVisible3} onCancel={()=>setIsVisible3(false)} className="form-modal"  footer={null}>
                    <div className="modal-content">
                        <div className="modal-header">
                            <div className="title-style">Гэр бүлийн байдал</div>
                        </div>
                        <div className="modal-body">
                            <Row>
                                <Col sm={4}>
                                    <div className="form-control-label">Таны хэн болох:</div>
                                    <Select
                                        options={familyDefinition}
                                        placeholder="сонгох"
                                        value={famDet}
                                        onChange={(value) => setFamDet(value)}
                                    />
                                </Col>
                                <Col sm={4}>
                                    <div className="form-control-label">Овог:</div>
                                    <div className="form-control">
                                        <input type="text" placeholder="Овог" value={famLastName}
                                               onChange={(e) => setFamLastName(e.target.value)}
                                               className="form-input"/>
                                    </div>
                                </Col>
                                <Col sm={4}>
                                    <div className="form-control-label">Нэр:</div>
                                    <div className="form-control">
                                        <input type="text" placeholder="Нэр" value={famFirstName}
                                               onChange={(e) => setFirstName(e.target.value)}
                                               className="form-input"/>
                                    </div>
                                </Col>
                                <Col sm={4} style={style.cpt2}>
                                    <div className="form-control-label">Регистр:</div>
                                    <div className="form-control">
                                        <input type="text" placeholder="Регистр" value={famRegNo}
                                               onChange={(e) => setFamRegNo(e.target.value)}
                                               className="form-input"/>
                                    </div>
                                </Col>

                                <Col sm={4} style={style.cpt2}>
                                    <div className="form-control-label">Эрхэлж буй ажил:</div>
                                    <div className="form-control">
                                        <input type="text" placeholder="ажил" value={famCurWork}
                                               onChange={(e) => setFamCurWork(e.target.value)}
                                               className="form-input"/>
                                    </div>
                                </Col>
                                <Col sm={4} style={style.cpt2}>
                                    <div className="form-control-label">Утас:</div>
                                    <div className="form-control">
                                        <input type="number" placeholder="Утас" value={famPhone}
                                               onChange={(e) => setFamPhone(e.target.value)}
                                               className="form-input"/>
                                    </div>
                                </Col>
                            </Row>
                        </div>
                        <div className="modal-footer">
                            <div className="btn-style">
                                <div className="border-style" onClick={() => setFamilyData()}>
                                    Хадгалах
                                </div>
                            </div>
                        </div>
                    </div>
                </Modal>

                <Modal open={isVisible2} onCancel={()=>setIsVisible2(false)} className="form-modal"  footer={null}>
                    <div className="modal-content">
                        <div className="modal-header">
                            <div className="title-style">Таны ажил байдлын тодорхойлолт өгөх хүний мэдээлэл</div>
                        </div>
                        <div className="modal-body">
                            <Row>
                                <Col sm={4}>
                                    <div className="form-control-label">Овог нэр:</div>
                                    <div className="form-control">
                                        <input type="text" placeholder="Нэр" value={defFullName}
                                               onChange={handleDefFullName}
                                               className="form-input"/>
                                    </div>
                                </Col>
                                <Col sm={4}>
                                    <div className="form-control-label">Байгууллагын нэр:</div>
                                    <div className="form-control">
                                        <input type="text" placeholder="Нэр" value={defCompanyName} onChange={handleDefName}
                                               className="form-input"/>
                                    </div>
                                </Col>
                                <Col sm={4}>
                                    <div className="form-control-label">Албан тушаал:</div>
                                    <div className="form-control">
                                        <input type="text" placeholder="Тушаал" value={defCompanyTitle}
                                               onChange={handleDefTitle}
                                               className="form-input"/>
                                    </div>
                                </Col>
                                <Col sm={4}>
                                    <div className="form-control-label">Утас:</div>
                                    <div className="form-control">
                                        <input type="number" placeholder="Утас" value={defPhone} onChange={handleDefPhone}
                                               className="form-input"/>
                                    </div>
                                </Col>
                                <Col sm={4}>
                                    <div className="form-control-label">Имэйл:</div>
                                    <div className="form-control">
                                        <input type="text" placeholder="Имэйл" value={defEmail} onChange={handleDefEmail}
                                               className="form-input"/>
                                    </div>
                                </Col>
                            </Row>
                        </div>
                        <div className="modal-footer">
                            <div className="btn-style">
                                <div className="border-style" onClick={() => setDefCompany()}>
                                    Хадгалах
                                </div>
                            </div>
                        </div>
                    </div>
                </Modal>
                <Modal open={showPrize} onCancel={() => setShowPrize(false)} className="form-modal"  footer={null}>
                    <div className="modal-content">
                        <div className="modal-header">
                            <div className="title-style">Гавъяа шагнал</div>
                        </div>
                        <div className="modal-body">
                            {/*Шагналын нэр	Шагнагдсан он	Олгосон байгууллага	Үйлдэл*/}
                            <Row>
                                <Col sm={4}>
                                    <div className="form-control-label">Шагналын нэр</div>
                                    <div className="form-control">
                                        <input type="text" placeholder="Нэр" value={prizeName}
                                               onChange={(e) => setPrizeName(e.target.value)}
                                               className="form-input"/>
                                    </div>
                                </Col>
                                <Col sm={4}>
                                    <div className="form-control-label">Шагнагдсан он</div>
                                    <div className="form-control">
                                        <input type="number" placeholder="Нэр" value={prizeYear} className="form-input" onChange={(e) => setPrizeYear(e.target.value)}/>
                                    </div>
                                </Col>
                                <Col sm={4}>
                                    <div className="form-control-label">Олгосон байгууллага</div>
                                    <div className="form-control">
                                        <input type="text" placeholder="Олгосон байгууллага" value={prizeOrg}
                                               onChange={(e) => setPrizeOrg(e.target.value)}
                                               className="form-input"/>
                                    </div>
                                </Col>
                            </Row>
                        </div>
                        <div className="modal-footer">
                            <div className="btn-style">
                                <div className="border-style" onClick={(event) => {
                                    let list = [...prizeList];
                                    let data = {
                                        name: prizeName,
                                        date: prizeYear,
                                        organization: prizeOrg
                                    }
                                    list.push(data);
                                    setPrizeList(list)
                                    setShowPrize(false)
                                    setPrizeName('')
                                    setPrizeOrg('')
                                }}>
                                    Хадгалах
                                </div>
                            </div>
                        </div>
                    </div>
                </Modal>
                <Modal open={showSport} onCancel={() => setShowSport(false)} className="form-modal"  footer={null}>
                    <div className="modal-content">
                        <div className="modal-header">
                            <div className="title-style">Гавъяа шагнал</div>
                        </div>
                        <div className="modal-body">
                            {/*Шагналын нэр	Шагнагдсан он	Олгосон байгууллага	Үйлдэл*/}
                            <Row>
                                <Col sm={4}>
                                    <div className="form-control-label">Төрөл</div>
                                    <div className="form-control">
                                        <input type="text" placeholder="Төрөл" value={sportName}
                                               onChange={(e) => setSportName(e.target.value)}
                                               className="form-input"/>
                                    </div>
                                </Col>
                                <Col sm={4}>
                                    <div className="form-control-label">Хичээллэсэн жил</div>
                                    <div className="form-control">
                                        <input type="number" placeholder="Хичээллэсэн жил" value={sportYear} className="form-input"
                                               onChange={(e) => setSportYear(e.target.value)}/>
                                    </div>
                                </Col>
                                <Col sm={4}>
                                    <div className="form-control-label">Зэрэг шагнал</div>
                                    <div className="form-control">
                                        <input type="text" placeholder="Зэрэг шагнал" value={sportDegree}
                                               onChange={(e) => setSportDegree(e.target.value)}
                                               className="form-input"/>
                                    </div>
                                </Col>
                            </Row>
                        </div>
                        <div className="modal-footer">
                            <div className="btn-style">
                                <div className="border-style" onClick={(event) => {
                                    let list = [...sportList];
                                    let data = {
                                        name: sportName,
                                        year: sportYear,
                                        degree: sportDegree
                                    }
                                    list.push(data);
                                    setSportList(list)
                                    setShowSport(false)
                                    setSportName('')
                                    setSportYear('')
                                    setSportDegree('')
                                }}>
                                    Хадгалах
                                </div>
                            </div>
                        </div>
                    </div>
                </Modal>

                <Modal open={showSkill} onCancel={() => setShowSkill(false)} className="form-modal"  footer={null}>
                    <div className="modal-content">
                        <div className="modal-header">
                            <div className="title-style">Ур чадвар</div>
                        </div>
                        <div className="modal-body">
                            <Row>


                                <Col sm={4}>
                                    <div className="form-control-label">Ур чадвар</div>
                                    <div className="form-control">
                                        <input type="text" placeholder="Ур чадвар" value={skillName}
                                               onChange={(e) => setSkillName(e.target.value)}
                                               className="form-input"/>
                                    </div>
                                </Col>
                                <Col sm={4}>
                                    <div className="form-control-label">Давуу тал</div>
                                    <div className="form-control">
                                        <input type="text" placeholder="Давуу тал" value={skillGood} className="form-input"
                                               onChange={(e) => setSkillGood(e.target.value)}/>
                                    </div>
                                </Col>
                                <Col sm={4}>
                                    <div className="form-control-label">Сул тал</div>
                                    <div className="form-control">
                                        <input type="text" placeholder="Сул тал" value={skillBad}
                                               onChange={(e) => setSkillBad(e.target.value)}
                                               className="form-input"/>
                                    </div>
                                </Col>
                            </Row>
                        </div>
                        <div className="modal-footer">
                            <div className="btn-style">
                                <div className="border-style" onClick={(event) => {
                                    let list = [...skillListData];
                                    let data = {
                                        name: skillName,
                                        good: skillGood,
                                        bad: skillBad
                                    }
                                    list.push(data);
                                    setSkillListData(list)
                                    setShowSkill(false)
                                    setSkillName('')
                                    setSkillGood('')
                                    setSkillBad('')
                                }}>
                                    Хадгалах
                                </div>
                            </div>
                        </div>
                    </div>
                </Modal>
                <ToastContainer/>

                <div className="request-form">
                    <div style={style.warningContainer}>
                        <span style={style.warningTextInfo}>Санамж</span>
                        <div style={style.rowDirection}>
                            <div style={style.numberContainer}>1</div>
                            <div style={style.warningText}>Та анкет бөглөхдөө Google Chrome, Mozilla Firefox интернет
                                хөтөчүүд хамгийн сайн
                                тохирохыг анхаарна уу.
                            </div>
                        </div>
                        <div style={style.rowDirection}>
                            <div style={style.numberContainer}>2</div>
                            <div style={style.warningText}>Анкетыг бөглөхдөө бүх асуултанд тодорхой хариулж, үг
                                товчлохгүй байхыг хүсье.
                            </div>
                        </div>

                        <div style={style.rowDirection}>
                            <div style={style.numberContainer}>3</div>
                            <div style={style.warningText}>Та зөвхөн Крилл үсгийн фонтоор бичнэ үү.</div>
                        </div>
                        <div style={style.rowDirection}>
                            <div style={style.numberContainer}>4</div>
                            <div style={style.warningText}>Улаан (*) тэмдэглэсэн талбарыг заавал бөглөх шаардлагатайг
                                анхаарна уу.
                            </div>
                        </div>
                        <div style={style.rowDirection}>
                            <div style={style.numberContainer}>5</div>
                            <div style={style.warningText}>Анкетанд зураг, мэргэжлийн диплом, гэрчилгээний хуулбарыг
                                заавал хавсаргах шаардлагатайг анхаарна уу. Хавсаргах файлын хэмжээ 1mb-с ихгүй байна
                            </div>
                        </div>
                        <div style={style.rowDirection}>
                            <div style={style.numberContainer}>6</div>
                            <div style={style.warningText}>Анкетын мэдээлэл болон хавсаргавал зохих баримт, материал
                                дутуу тохиолдолд сонгон шалгаруулалтад оруулах боломжгүй болохыг анхаарна уу
                            </div>
                        </div>
                        <div style={style.rowDirection}>
                            <div style={style.numberContainer}>7</div>
                            <div style={style.warningText}>Таны ирүүлсэн мэдээлэл, баримтыг буцаан олгохгүй бөгөөд
                                мэдээллийн нууцыг хадгалах болно
                            </div>
                        </div>
                        <div style={style.rowDirection}>
                            <div style={style.numberContainer}>8</div>
                            <div style={style.warningText}>Компани анкетыг хүлээн авснаар ажилд орохыг хүсэгчийн өмнө
                                ямар нэгэн үүрэг, хариуцлага хүлээхгүй.
                            </div>
                        </div>
                    </div>
                    <form>

                    <Container style={style.cpt2}>
                        <Row>
                            <Col sm={4}>
                                <div style={style.labelStyle}>
                                    <span>Таны сонирхож буй ажлын байр:</span>
                                </div>
                                <input type="text" placeholder="Ажлын байр" value={workCompanyPosition}
                                       onChange={handleWorkCompany}
                                       style={style.inputStyle}
                                       disabled={true}
                                />

                            </Col>
                            <Col sm={4}>
                                <div style={style.labelStyle}>
                                    <span>Хэлтэс, алба, нэгж:</span>
                                </div>
                                <input type="text" placeholder="Хэлтэс, алба, нэгж" value={branch}
                                       onChange={(e) => setBranch(e.target.value)}
                                       style={style.inputStyle} disabled={true}
                                />
                            </Col>
                            <Col sm={4}>
                                <div style={style.labelStyle}>
                                    <span>Байршил:</span>
                                </div>
                                <input type="text" placeholder="Ажлын байр" value={value}
                                       onChange={(e) => setValue(e.target.value)}
                                       style={style.inputStyle} disabled={true}
                                />
                            </Col>

                            <Col sm={4}>
                                <div style={style.labelStyle}>
                                    <span>Мэдээлэл авсан суваг:</span>
                                    <span style={{color: 'red', marginLeft: 2}}>*</span>
                                </div>
                                <div style={style.selectStyle}>
                                    <Select
                                        options={workDirection}
                                        required
                                        value={valueDir}
                                        onChange={(value) => setValueDir(value)}
                                    />
                                </div>
                            </Col>
                            <Col sm={8}>
                                <div style={style.labelStyleLong}>
                                    <span>Та үндсэн мэргэжлээс гадна ямар чиглэлээр ажиллах сонирхолтой вэ?</span>
                                </div>
                                <div style={style.selectStyleLong}>
                                    <Select
                                        options={workPosition}
                                        value={valuePos}
                                        onChange={(value) => setValuePos(value)}
                                    />
                                </div>
                            </Col>
                            <Col sm={4}>
                                <div style={style.labelStyle}>
                                    <span>Ажилд орох боломжит хугацаа:</span>
                                    <span style={{color: 'red', marginLeft: 2}}>*</span>
                                </div>
                                <div style={style.selectStyle}>

                                    <DatePicker
                                        selected={availableDate}
                                        onChange={(date) => setAvailableDate(date)}
                                        peekNextMonth
                                        showMonthDropdown
                                        showYearDropdown
                                        dropdownMode="select"
                                        className="date-input"
                                    />
                                </div>
                            </Col>
                            <Col sm={4}>

                                <div style={style.labelStyle}>
                                    <span>Хүсч буй цалингийн доод хэмжээ:</span>
                                    <span style={{color: 'red', marginLeft: 2}}>*</span>
                                </div>
                                <input type="text" placeholder="1,500,000" value={minSalary} onChange={handleMinSalary}
                                       style={style.inputStyle}
                                />

                            </Col>
                            <Col sm={4}>

                                <div style={style.labelStyle}>
                                    <span>Хүсч буй цалингийн дээд хэмжээ:</span>
                                    <span style={{color: 'red', marginLeft: 2}}>*</span>
                                </div>
                                <input type="text" placeholder="5,000,000" value={maxSalary} onChange={handleMaxSalary}
                                       style={style.inputStyle}
                                />
                            </Col>
                        </Row>
                    </Container>


                    <div className="title-container">
                        <span>Хувийн мэдээлэл</span>
                        <span style={{color: 'red', marginLeft: 2}}>*</span>

                    </div>


                    <Container style={style.cpt2}>
                        <Row>
                            <Col sm={4}>
                                <input type="text" placeholder="Ургийн овог:" value={personData.surname}
                                       onChange={changeSurName}
                                       style={style.inputStyleNoLabel}
                                />
                            </Col>
                            <Col sm={4}>
                                <input type="text" placeholder="Эцэг эхийн нэр:" value={personData.lastName}
                                       onChange={changeLastName}
                                       style={style.inputStyleNoLabel}
                                />
                            </Col>
                            <Col sm={4}>
                                <input type="text" placeholder="Өөрийн нэр:" value={personData.firstName}
                                       onChange={changeFirstName}
                                       style={style.inputStyleNoLabel}
                                />
                            </Col>
                            <Col sm={4}>
                                <input type="text" placeholder="Регистрийн дугаар: АА12345678"
                                       value={personData.regNumber}
                                       onChange={changeRegNumber}
                                       style={style.inputStyleNoLabel}/>
                            </Col>
                            <Col sm={4}>
                                <div style={style.selectStyleNoLabel}>
                                    <Select
                                        options={genderSelect}
                                        value={valueGender}
                                        onChange={(value) => {
                                            setValueGender(value)}}
                                    />
                                </div>
                            </Col>
                            <Col sm={4}>
                                <input type="text" placeholder="Төрсөн газар:" value={personData.bornPlace}
                                       onChange={changeBornPlace}
                                       style={style.inputStyleNoLabel}
                                />
                            </Col>
                            <Col sm={4}>
                                <div style={style.labelStyle}>
                                    <span>Төрсөн он сар өдөр:</span>
                                </div>
                                <DatePicker
                                    selected={bornDate}
                                    onChange={(date) => setBornDate(date)}
                                    peekNextMonth
                                    showMonthDropdown
                                    showYearDropdown
                                    dropdownMode="select"
                                    className="date-input"
                                />
                            </Col>
                        </Row>
                    </Container>


                    {/*<div className="field-container">*/}
                    {/*    <div style={{display: 'flex', flexDirection: 'row', marginTop: 10}}>*/}
                    {/*        <div style={{width: '48%', display: 'flex', flexDirection: 'column'}}>*/}
                    {/*            <div style={{display: 'flex', flexDirection: 'row', marginBottom: 20}}>*/}
                    {/*                <span>Төрсөн он сар өдөр:</span>*/}
                    {/*                <span style={{color: 'red', marginLeft: 2}}>*</span>*/}
                    {/*            </div>*/}

                    {/*        </div>*/}
                    {/*        <div style={{width: '48%', display: 'flex', flexDirection: 'column', marginLeft: 20}}>*/}
                    {/*            <div style={{display: 'flex', flexDirection: 'row', marginBottom: 20}}>*/}
                    {/*                <span>Цээж зураг:</span>*/}
                    {/*                <span style={{color: 'red', marginLeft: 2}}>*</span>*/}
                    {/*            </div>*/}
                    {/*            <div style={{*/}
                    {/*                height: 40,*/}
                    {/*                backgroundColor: "#F7F7F7",*/}
                    {/*                border: 0,*/}
                    {/*                marginRight: 5,*/}
                    {/*                borderRadius: 5*/}
                    {/*            }}>*/}
                    {/*                <input type="file" onChange={onFileChange}/>*/}
                    {/*            </div>*/}
                    {/*        </div>*/}
                    {/*    </div>*/}
                    {/*</div>*/}

                    <div className="title-container">
                        <span>Хаяг</span>
                        <span style={{color: 'red', marginLeft: 2}}>*</span>
                    </div>


                    <Container style={style.cpt2}>
                        <Row>
                            <Col sm={8}>
                                <input type="text" placeholder="Өөрийн оршин суугаа хаяг:" value={personData.address}
                                       onChange={changeAddress}
                                       style={style.inputStyleNoLabelLong}/>
                            </Col>
                            <Col sm={4}>
                                <input type="email" placeholder="И-мэйл:" value={personData.email}
                                       onChange={changeEmail}
                                       style={style.inputStyleNoLabel}/>
                            </Col>
                            <Col sm={4}>
                                <input type="number" placeholder="Өөрийн утасны дугаар:" value={personData.phone1}
                                       onChange={changePhone1}
                                       style={style.inputStyleNoLabel}
                                />
                            </Col>
                            <Col sm={4}>
                                <input type="number" placeholder="Яаралтай үед холбоо барих хүний дугаар:"
                                       value={personData.phone2} onChange={changePhone2}
                                       style={style.inputStyleNoLabel}
                                /></Col>


                            <Col sm={4}>
                                <input type="text" placeholder="FaceBook:" value={personData.facebook}
                                       onChange={changeFaceBook}
                                       style={style.inputStyleNoLabel}
                                /></Col>
                            <Col sm={4}>
                                <input type="text" placeholder="Instagram:" value={personData.instagram}
                                       onChange={changeInsta}
                                       style={style.inputStyleNoLabel}
                                /></Col>
                            <Col sm={4}>
                                <input type="text" placeholder="Twitter:" value={personData.twitter}
                                       onChange={changeTwitter}
                                       style={style.inputStyleNoLabel}
                                /></Col>
                            <Col sm={4}>
                                <input type="text" placeholder="Linked in:" value={personData.linkid}
                                       onChange={changeLinked}
                                       style={style.inputStyleNoLabel}
                                /></Col>

                        </Row>
                    </Container>

                    <div className="title-container">
                        <span>Гэр бүлийн байдал</span>
                        <span style={{color: 'red', marginLeft: 2}}>*</span>
                    </div>
                    <Container style={style.cpt2}>
                        <div className="plus-btn" onClick={() => setIsVisible3(true)}
                             style={{marginBottom: 20, float: 'right'}}>
                            <span className="text">Нэмэх</span>
                            <img src={PlusImg} className="icon" alt={''}/>
                        </div>

                        <TableContainer component={Paper}>
                            <Table sx={{minWidth: 400}} aria-label="simple table">
                                <TableHead>
                                    <TableRow>
                                        <TableCell><span style={{fontSize: 15}}>Таны хэн болох</span></TableCell>
                                        <TableCell align="left"><span style={{fontSize: 15}}>Овог</span></TableCell>
                                        <TableCell align="left"><span style={{fontSize: 15}}>Нэр</span></TableCell>
                                        <TableCell align="left"><span
                                            style={{fontSize: 15}}>Регистрийн дугаар</span></TableCell>
                                        <TableCell align="left"><span
                                            style={{fontSize: 15}}>Эрхэлж буй ажил</span></TableCell>
                                        <TableCell align="left"><span style={{fontSize: 15}}>Утас</span></TableCell>
                                        <TableCell align="left"><span style={{fontSize: 15}}>Үйлдэл</span></TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {familyList.map((row) => (
                                        <TableRow
                                            key={row.name}
                                            sx={{'&:last-child td, &:last-child th': {border: 0}}}
                                        >
                                            <TableCell component="th" scope="row">{row.det}</TableCell>
                                            <TableCell align="left">{row.last_name}</TableCell>
                                            <TableCell align="left">{row.first_name}</TableCell>
                                            <TableCell align="left">{row.reg_no}</TableCell>
                                            <TableCell align="left">{row.cur_work}</TableCell>
                                            <TableCell align="left">{row.phone}</TableCell>
                                            <TableCell align="left">
                                                <div className="remove-item"
                                                     onClick={() => removeFamilyItem(row)}>устгах
                                                </div>
                                            </TableCell>
                                        </TableRow>
                                    ))}
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </Container>

                    <div className="title-container">
                        <span>Боловсрол</span>
                    </div>

                    <Container style={style.cpt2}>
                        <Row style={style.cpt2}>
                            <Col sm={8}>
                                <span>Боловсрол эзэмшсэн байдал:</span><span
                                style={{color: 'red', marginLeft: 2}}>*</span>
                            </Col>
                            <Col sm={4}>
                                <div className="plus-btn" onClick={() => openModal(1)} style={{float: 'right'}}>
                                    <span className="text">Нэмэх</span>
                                    <img src={PlusImg} className="icon"/>
                                </div>
                            </Col>
                        </Row>
                        <TableContainer component={Paper}>
                            <Table sx={{minWidth: 400}} aria-label="simple table">
                                <TableHead>
                                    <TableRow>
                                        <TableCell><span style={{fontSize: 15}}>Хаана</span></TableCell>
                                        <TableCell align="left"><span
                                            style={{fontSize: 15}}>Сургуулийн нэр</span></TableCell>
                                        <TableCell align="left"><span
                                            style={{fontSize: 15}}>Элссэн огноо</span></TableCell>
                                        <TableCell align="left"><span
                                            style={{fontSize: 15}}>Төгссөн огноо</span></TableCell>
                                        <TableCell align="left"><span
                                            style={{fontSize: 15}}>Эзэмшсэн мэргэжил</span></TableCell>
                                        <TableCell align="left"><span
                                            style={{fontSize: 15}}>Боловсролын зэрэг</span></TableCell>
                                        <TableCell align="left"><span style={{fontSize: 15}}>Голч дүн</span></TableCell>
                                        <TableCell align="left"><span style={{fontSize: 15}}>Үйлдэл</span></TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {elSchoolList.map((row) => (
                                        <TableRow
                                            key={row.name}
                                            sx={{'&:last-child td, &:last-child th': {border: 0}}}
                                        >
                                            <TableCell component="th" scope="row">{row.where}</TableCell>
                                            <TableCell align="left">        {row.name}</TableCell>
                                            <TableCell align="left">{moment( row.startDate).format('YYYY.MM.D')}</TableCell>
                                            <TableCell align="left">{moment( row.endDate).format('YYYY.MM.D')}</TableCell>
                                            <TableCell align="left">        {row.course_info}</TableCell>
                                            <TableCell align="left">        {row.degree}</TableCell>
                                            <TableCell align="left">        {row.rate}</TableCell>

                                            <TableCell align="left">
                                                <div className="remove-item" onClick={() => removeElItem(row)}>устгах
                                                </div>
                                            </TableCell>
                                        </TableRow>
                                    ))}
                                </TableBody>
                            </Table>
                        </TableContainer>

                        <Row style={style.cpt2}>
                            <Col sm={8}>
                                <span>Мэргэжил дээшлүүлсэн байдал:</span>
                            </Col>
                            <Col sm={4}>
                                <div className="plus-btn" onClick={() => openModal(3)} style={{float: 'right'}}>
                                    <span className="text">Нэмэх</span>
                                    <img src={PlusImg} className="icon"/>
                                </div>
                            </Col>
                        </Row>

                        <TableContainer component={Paper}>
                            <Table sx={{minWidth: 400}} aria-label="simple table">
                                <TableHead>
                                    <TableRow>
                                        <TableCell><span style={{fontSize: 15}}>Хаана</span></TableCell>
                                        <TableCell align="left"><span style={{fontSize: 15}}>Сургууль, курс, дамжаа</span></TableCell>
                                        <TableCell align="left"><span style={{fontSize: 15}}>Элссэн он</span></TableCell>
                                        <TableCell align="left"><span style={{fontSize: 15}}>Төгссөн он</span></TableCell>
                                        <TableCell align="left"><span style={{fontSize: 15}}>Эзэмшсэн мэргэжил</span></TableCell>
                                        <TableCell align="left"><span style={{fontSize: 15}}>Сертификатын дугаар</span></TableCell>
                                        <TableCell align="left"><span style={{fontSize: 15}}>Үйлдэл</span></TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {courseSchoolList.map((row) => (
                                        <TableRow
                                            key={row.name}
                                            sx={{'&:last-child td, &:last-child th': {border: 0}}}
                                        >
                                            <TableCell component="th" scope="row">{row.where}</TableCell>
                                            <TableCell align="left">       {row.course_name}</TableCell>
                                            <TableCell align="left">{moment(row.start_date).format('YYYY.MM.D')}</TableCell>
                                            <TableCell align="left">{moment(row.end_date).format('YYYY.MM.D')}</TableCell>
                                            <TableCell align="left">{       row.course_info}</TableCell>
                                            <TableCell align="left">{       row.certificate_no}</TableCell>
                                            <TableCell align="left">
                                                <div className="remove-item"
                                                     onClick={() => removeCourseItem(row)}>устгах
                                                </div>
                                            </TableCell>
                                        </TableRow>
                                    ))}
                                </TableBody>
                            </Table>
                        </TableContainer>
                        <Row style={style.cpt2}>
                            <Col sm={8}>
                                <span>Гадаад хэлний мэдлэг:</span>
                            </Col>
                            <Col sm={4}>
                                <div className="plus-btn" onClick={() => setIsVisible(true)} style={{float: 'right'}}>
                                    <span className="text">Нэмэх +</span>
                                    <img src={PlusImg} className="icon"/>
                                </div>
                            </Col>
                        </Row>
                        <TableContainer component={Paper}>
                            <Table sx={{minWidth: 400}} aria-label="simple table">
                                <TableHead>
                                    <TableRow>
                                        <TableCell><span style={{fontSize: 15}}>Гадаад хэлний нэр</span></TableCell>
                                        <TableCell align="left"><span style={{fontSize: 15}}>Сонсох</span></TableCell>
                                        <TableCell align="left"><span style={{fontSize: 15}}>Ярих</span></TableCell>
                                        <TableCell align="left"><span style={{fontSize: 15}}>Бичих</span></TableCell>
                                        <TableCell align="left"><span style={{fontSize: 15}}>Унших</span></TableCell>
                                        <TableCell align="left"><span style={{fontSize: 15}}>Үйлдэл</span></TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {lanSchoolList.map((row) => (
                                        <TableRow
                                            key={row.name}
                                            sx={{'&:last-child td, &:last-child th': {border: 0}}}
                                        >
                                            <TableCell component="th" scope="row">{row.name}</TableCell>
                                            <TableCell align="left">{row.listen}</TableCell>
                                            <TableCell align="left">{row.speak}</TableCell>
                                            <TableCell align="left">{row.write}</TableCell>
                                            <TableCell align="left">{row.read}</TableCell>
                                            <TableCell align="left">
                                                <div className="remove-item" onClick={() => removeLanItem(row)}>устгах
                                                </div>
                                            </TableCell>
                                        </TableRow>
                                    ))}
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </Container>

                    <div className="title-container">
                        <div style={{display: 'flex', flexDirection: 'row'}}>
                            <span>Ажлын туршлага</span>
                        </div>
                    </div>
                    <Container style={style.cpt2}>
                        <Row>
                            <Col sm={4}>
                                <input type="number" placeholder="Ажилласан жил" value={personData.expYear}
                                       onChange={changeEmail} style={style.inputStyleNoLabel}/>
                            </Col>

                            <Col sm={8}>
                                <input type="text" placeholder="Хэрэв удаан хугацаанд ажил эрхлээгүй бол шалтгаанаа бичнэ үү" value={personData.expReason}
                                       onChange={changeAddress} style={style.inputStyleNoLabelLong}/>
                            </Col>
                        </Row>
                        <Row style={style.cpt2}>
                            <Col sm={8}>
                                <span>Таны ажлын туршлага:</span><span style={{color: 'red', marginLeft: 2}}>*</span>
                            </Col>
                            <Col sm={4}>
                                <div className="plus-btn" onClick={() => setIsVisible1(true)} style={{float: 'right'}}>
                                    <span className="text">Нэмэх</span>
                                    <img src={PlusImg} className="icon"/>
                                </div>
                            </Col>
                        </Row>

                        <TableContainer component={Paper}>
                            <Table sx={{minWidth: 400}} aria-label="simple table">
                                <TableHead>
                                    <TableRow>
                                        <TableCell><span style={{fontSize: 15}}>Байгууллагын нэр</span></TableCell>
                                        <TableCell align="left"><span
                                            style={{fontSize: 15}}>Албан тушаал</span></TableCell>
                                        <TableCell align="left"><span style={{fontSize: 15}}>Орсон он</span></TableCell>
                                        <TableCell align="left"><span
                                            style={{fontSize: 15}}>Гарсан он</span></TableCell>
                                        <TableCell align="left"><span
                                            style={{fontSize: 15}}>Ажилын үүрэг</span></TableCell>
                                        <TableCell align="left"><span
                                            style={{fontSize: 15}}>Ажиллаас гарсан шалтгаан</span></TableCell>
                                        <TableCell align="left"><span style={{fontSize: 15}}>Үйлдэл</span></TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {expSchoolList.map((row) => (
                                        <TableRow
                                            key={row.name}
                                            sx={{'&:last-child td, &:last-child th': {border: 0}}}
                                        >
                                            <TableCell component="th" scope="row">{row.name}</TableCell>
                                            <TableCell align="left">{row.title}</TableCell>
                                            <TableCell
                                                align="left">{moment(row.startDate).format('YYYY.MM.D')}</TableCell>
                                            <TableCell
                                                align="left">{moment(row.endDate).format('YYYY.MM.D')}</TableCell>
                                            <TableCell align="left">{row.direction}</TableCell>
                                            <TableCell align="left">{row.reason}</TableCell>
                                            <TableCell align="left">
                                                <div className="remove-item" onClick={() => removeExpItem(row)}>устгах
                                                </div>
                                            </TableCell>
                                        </TableRow>
                                    ))}
                                </TableBody>
                            </Table>
                        </TableContainer>

                        <Row style={style.cpt2}>
                            <Col sm={8}>
                                <span>Таны ажил байдлын тодорхойлолт өгөх хүний мэдээлэл:</span>
                            </Col>
                            <Col sm={4}>
                                <div className="plus-btn" onClick={() => setIsVisible2(true)} style={{float: 'right'}}>
                                    <span className="text">Нэмэх</span>
                                    <img src={PlusImg} className="icon"/>
                                </div>
                            </Col>
                        </Row>


                        <TableContainer component={Paper}>
                            <Table sx={{minWidth: 400}} aria-label="simple table">
                                <TableHead>
                                    <TableRow>
                                        <TableCell><span style={{fontSize: 15}}>Овог нэр</span></TableCell>
                                        <TableCell align="left"><span
                                            style={{fontSize: 15}}>Байгууллагын нэр</span></TableCell>
                                        <TableCell align="left"><span
                                            style={{fontSize: 15}}>Албан тушаал</span></TableCell>
                                        <TableCell align="left"><span style={{fontSize: 15}}>Утас</span></TableCell>
                                        <TableCell align="left"><span style={{fontSize: 15}}>Имэйл</span></TableCell>
                                        <TableCell align="left"><span style={{fontSize: 15}}>Үйлдэл</span></TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {defSchoolList.map((row) => (
                                        <TableRow
                                            key={row.name}
                                            sx={{'&:last-child td, &:last-child th': {border: 0}}}
                                        >
                                            <TableCell component="th" scope="row">{row.fullName}</TableCell>
                                            <TableCell align="left">{row.name}</TableCell>
                                            <TableCell align="left">{row.position}</TableCell>
                                            <TableCell align="left">{row.phone}</TableCell>
                                            <TableCell align="left">{row.email}</TableCell>
                                            <TableCell align="left">
                                                <div className="remove-item" onClick={() => removeDefItem(row)}>устгах
                                                </div>
                                            </TableCell>
                                        </TableRow>
                                    ))}
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </Container>

                    <div className="title-container">
                        <span>Гавъяа шагнал:</span>
                    </div>
                    <Container style={style.cpt2}>
                        <div className="plus-btn" onClick={() => setShowPrize(true)}
                             style={{marginBottom: 20, float: 'right'}}>
                            <span className="text">Нэмэх</span>
                            <img src={PlusImg} className="icon" alt={''}/>
                        </div>
                        <TableContainer component={Paper}>
                            <Table sx={{minWidth: 400}} aria-label="simple table">
                                <TableHead>
                                    <TableRow>
                                        <TableCell><span style={{fontSize: 15}}>Шагналын нэр</span></TableCell>
                                        <TableCell align="left"><span
                                            style={{fontSize: 15}}>Шагнагдсан он</span></TableCell>
                                        <TableCell align="left"><span style={{fontSize: 15}}>Олгосон байгууллага</span></TableCell>
                                        <TableCell align="left"><span style={{fontSize: 15}}>Үйлдэл</span></TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {prizeList.map((row) => (
                                        <TableRow key={row.name} sx={{'&:last-child td, &:last-child th': {border: 0}}}>
                                            <TableCell component="th" scope="row">{row.name}</TableCell>
                                            <TableCell align="left">{row.date}</TableCell>
                                            <TableCell align="left">{row.organization}</TableCell>
                                            <TableCell align="left">
                                                <div className="remove-item"
                                                     onClick={() => removePrizeItem(row)}>устгах
                                                </div>
                                            </TableCell>
                                        </TableRow>
                                    ))}
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </Container>

                    <div className="title-container">
                        <span>Урлаг, спортын авъяас</span>
                    </div>
                    <Container style={style.cpt2}>
                        <div className="plus-btn" onClick={() => setShowSport(true)}
                             style={{marginBottom: 20, float: 'right'}}>
                            <span className="text">Нэмэх</span>
                            <img src={PlusImg} className="icon" alt={''}/>
                        </div>
                        <TableContainer component={Paper}>
                            <Table sx={{minWidth: 400}} aria-label="simple table">
                                <TableHead>
                                    <TableRow>
                                        <TableCell><span style={{fontSize: 15}}>Төрөл</span></TableCell>
                                        <TableCell align="left"><span
                                            style={{fontSize: 15}}>Хичээллэсэн жил</span></TableCell>
                                        <TableCell align="left"><span
                                            style={{fontSize: 15}}>Зэрэг шагнал</span></TableCell>
                                        <TableCell align="left"><span style={{fontSize: 15}}>Үйлдэл</span></TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {sportList.map((row) => (
                                        <TableRow key={row.name} sx={{'&:last-child td, &:last-child th': {border: 0}}}>
                                            <TableCell component="th" scope="row">{row.name}</TableCell>
                                            <TableCell align="left">{row.year}</TableCell>
                                            <TableCell align="left">{row.degree}</TableCell>
                                            <TableCell align="left">
                                                <div className="remove-item"
                                                     onClick={() => removeSportItem(row)}>устгах
                                                </div>
                                            </TableCell>
                                        </TableRow>
                                    ))}
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </Container>

                    <div className="title-container">
                        <span>Ур чадвар, давуу болон сул тал</span>
                        <span style={{color: 'red', marginLeft: 2}}>*</span>
                    </div>
                    <Container style={style.cpt2}>
                        <div className="plus-btn" onClick={() => setShowSkill(true)}
                             style={{marginBottom: 20, float: 'right'}}>
                            <span className="text">Нэмэх</span>
                            <img src={PlusImg} className="icon" alt={''}/>
                        </div>
                        <TableContainer component={Paper}>
                            <Table sx={{minWidth: 400}} aria-label="simple table">
                                <TableHead>
                                    <TableRow>
                                        <TableCell><span style={{fontSize: 15}}>Ур чадвар</span></TableCell>
                                        <TableCell align="left"><span
                                            style={{fontSize: 15}}>Давуу тал</span></TableCell>
                                        <TableCell align="left"><span style={{fontSize: 15}}>Сул тал</span></TableCell>
                                        <TableCell align="left"><span style={{fontSize: 15}}>Үйлдэл</span></TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {skillListData.map((row) => (
                                        <TableRow key={row.name} sx={{'&:last-child td, &:last-child th': {border: 0}}}>
                                            <TableCell component="th" scope="row">{row.name}</TableCell>
                                            <TableCell align="left">{row.good}</TableCell>
                                            <TableCell align="left">{row.bad}</TableCell>
                                            <TableCell align="left">
                                                <div className="remove-item"
                                                     onClick={() => removeSkillItem(row)}>устгах
                                                </div>
                                            </TableCell>
                                        </TableRow>
                                    ))}
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </Container>

                    <div className="title-container">
                        <span>Хавсаргах материал</span>
                    </div>
                    <Container style={style.cpt2}>

                        <Row>
                            <Col sm={4}>
                                <div>
                                    <span>Цээж зураг</span>
                                    <span style={{color: 'red', marginLeft: 2}}>*</span>

                                </div>
                                <div style={style.selectStyle}>
                                    <input type="file" onChange={changeFileRefAvatar}/>
                                </div>
                            </Col>
                            <Col sm={4}>
                                <div>
                                    <span>Боловсролын диплом</span>
                                    <span style={{color: 'red', marginLeft: 2}}>*</span>

                                </div>
                                <div style={style.selectStyle}>
                                    <input type="file" onChange={changeFileRefCertificate}/>
                                </div>
                            </Col>
                            <Col sm={4}>
                                <div>
                                    <span>Иргэний үнэмлэхний хуулбар</span>
                                    <span style={{color: 'red', marginLeft: 2}}>*</span>

                                </div>
                                <div style={style.selectStyle}>
                                    <input type="file" onChange={changeFileRefCitizenIdCard}/>
                                </div>
                            </Col>

                        </Row>
                    </Container>

                    <div className="permission-container">
                        Энэхүү анкетад агуулагдаж буй мэдээллүүдийг үнэн зөв болохыг баталж байна. Мэдээлэлтэй холбоотой
                        үүсэж болзошгүй аливаа асуудлыг Монгол улсын хууль тогтоомжийн дагуу миний бие хариуцах болно.
                    </div>
                    <div className="check-box-style">
                        <CheckBox
                            checked={checked}
                            label="Нөхцлийг зөвшөөрч байна"
                            onChange={(event) => setChecked(event.target.checked)}
                        />
                    </div>
                    <div className="button-style">
                        {checked ?
                            <div className="button-box" onClick={() => saveData()}>
                                <span className="text">Анкет илгээх</span>
                            </div> : <div className="button-box-another"> <span className="text">Анкет илгээх</span></div>
                        }
                    </div>
                    </form>
                </div>
            </div>
        </React.Fragment>
    )
}
