import React, {useEffect, useState} from "react"
import BannerImg from "../../asset/no-image.png"
import {Link} from "react-router-dom"
import WorkService from "../../services/workService"
import moment from "moment"
import {Col, Row} from "react-grid-system"
import {Image} from "antd"
import {base} from "grommet"

const newsTitle = [
  {
    id: 1,
    text: "Сүүлд нэмэгдсэн",
    to: "/news",
  },
  {
    id: 2,
    text: "Онцлох мэдээ",
    to: "/news",
  },
  {
    id: 3,
    text: "Хувьцаа эзэмшигчдэд",
    to: "/news",
  },
  {
    id: 4,
    text: "Бүгдийг харах",
    to: "/news",
  },
]

export default function NewsDetail() {
  const [selectedData, setSelectedData] = useState(null)
  const [newsTitleId, setNewsTitleId] = useState(1)
  const [containsHtml, setContainsHtml] = useState(false)
  const  baseURL = "/api/zes-service/api/uploads/";
  // const baseURL = "http://zes-erdene.mn:3500/api/uploads/"

  useEffect(() => {
    const location = window.location.href
    const id = location.substring(location.lastIndexOf("/") + 1)
    getData(id)
  }, [])
  function ifContainsHTML(str) {
    const htmlRegex = /<[a-z][\s\S]*>/i
    return htmlRegex.test(str)
  }

  const getData = async (id) => {
    const {data} = await WorkService.getNewsById(id)
    setContainsHtml(ifContainsHTML(data[0]?.content))
    setSelectedData(data[0])
  }
  const createMarkup = (content) => {
    return {__html: content}
  }
  return (
    <React.Fragment>
      <div className="margin-style-real">
        <div style={{width: "100%", paddingTop: "5%"}}>
          <Row style={{width: "100%"}}>
            <Col  style={{width: "100%"}}>
              <div style={{fontWeight: 600, fontSize: 20, marginBottom: 16}}>{selectedData?.title}</div>
              <div style={{fontSize: 14, marginBottom: 16}}>
                {moment(selectedData?.createdAt).format("YYYY.MM.D")}
              </div>
              <div className="text-style">
                {containsHtml ? (
                  <div
                  
                    dangerouslySetInnerHTML={createMarkup(
                      selectedData?.content
                    )}
                  ></div>
                ) : (
                  selectedData?.content
                )}
              </div>
            </Col>
          </Row>
          <div>
            <div
              style={{
                display: "grid",
                gridTemplateColumns: "repeat(auto-fit, minmax(300px, 1fr))",
                marginTop: "2rem",
                rowGap: "1rem",
                columnGap: "1rem",
              }}
            >
              {selectedData?.files?.map((item, index) => (
                <div key={index} className="card">
                  <Image
                    style={{height: "10rem", objectFit: "cover"}}
                    src={baseURL + item.file_name}
                  />
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  )
}
