import React, {useState} from "react";
import "swiper/css";
import "swiper/css/pagination";
import BannerImg from "../../asset/HomeBanner3.jpg";
import NewsComponentHome from "../../component/News/NewComponentHome";
import OrgComponent from "../Home/OrgComponent";
import FileCard from "./FileCard";
import 'chart.js/auto';
import ChartComponent from "./Chart";
import ParallaxHeader from "../../component/ParallaxHeader";
import InvestmentCard from "./InvestmentCard";
import StatBar from "./StatBar";
import  PieChart from "./PieChart";
import BarStatChart from "./BarStatChart";

const newsTitle = [
  {
    id: 1,
    text: 'Хувьцаа эзэмшигчид'
  },
  {
    id: 2,
    text: 'Тайлан'
  },
  {
    id: 3,
    text: 'Хөгжлийн төв'
  },
  {
    id: 4,
    text: 'Хувьцаа эзэмшигчдийн хурал'
  }
]

export default function Investment() {

  const [newsTitleId, setNewsTitleId] = useState(1);
  return (
    <React.Fragment>
      <ParallaxHeader src={BannerImg}/>

      <div className="home-news-title-container  margin-style-real" style={{ marginTop: 50, marginBottom: "65px" }}>
        {newsTitle.map(el => (
          <div key={el.id} className="title-style" style={{width: "100%"}} onClick={() => setNewsTitleId(el.id)}>
            <span className={`${newsTitleId === el.id ? 'text2' : 'text1'}`} style={{textAlign:"center"}}>{el.text}</span>
            <div className={`${newsTitleId === el.id ? 'line-style-active' : 'line-style'}`}/>
          </div>
        ))}
      </div>
      {newsTitleId === 1 &&
      <div className="investmentStat margin-style-real">
        <div className="container-1">
          {[{company: "Бльюмон металс ххк", detail1: "57,413,267 ширхэг хувьцаа", detail2: "6,028.4 сая төгрөг", percent: "70%"},
          {company: "Орхон аймгийн 24.552 иргэн", detail1: "24,616,362 ширхэг хувьцаа", detail2: "2,524.7 сая төгрөг", percent: "30%"},
           ].map((item, index)=>(
            <InvestmentCard key={index} {...item} />
           ))}
        </div>
        <div className="text" > 
          <div>
          Нийт 82 сая
                ширхэг энгийн хувьцаатай.
                Нэгж хувьцааны нэрлэсэн үнэ: 105 төгрөг
          </div>
          <div>Төлөөлөн Удирдах Зөвлөл нь
                7 гишүүний бүрэлдэхүүнтэй
          </div>
        </div>
       
        <div className="container-2" style={{display: "flex"}}>
            {/* <StatBar/> */}
            <BarStatChart/>
            {/* <PieChart /> */}
        </div>
      </div>}

      {newsTitleId === 2 && 
      <>
      <ChartComponent/>
      <ChartComponent/>
      <div className="investmentContainer margin-style-real">
      <div>
      <h4>ЖИЛИЙН ТАЙЛАН</h4>
      <FileCard text="2021 оны жилийн тайлан" filetype="PDF" size="4.89mb"/>
      <FileCard text="2021 оны жилийн тайлан" filetype="PDF" size="4.89mb"/>
      <FileCard text="2021 оны жилийн тайлан" filetype="PDF" size="4.89mb"/>
      <FileCard text="2021 оны жилийн тайлан" filetype="PDF" size="4.89mb"/>
      </div>
      <div>
      <h4>АУДИТЛАГДСАН САНХҮҮГИЙН ТАЙЛАН</h4>
      <FileCard text="Аудитлагдсан санхүүгийн тайлан" filetype="PDF" size="4.89mb"/>
      <FileCard text="Аудитлагдсан санхүүгийн тайлан" filetype="PDF" size="4.89mb"/>
      <FileCard text="Аудитлагдсан санхүүгийн тайлан" filetype="PDF" size="4.89mb"/>
      <FileCard text="Аудитлагдсан санхүүгийн тайлан" filetype="PDF" size="4.89mb"/>
      </div>
      
      
      </div>
      </>
      }


      {newsTitleId === 3 && <div className="investment-content margin-style-real">
        <div className="title-style" style={{fontSize: "1.5rem"}}>ЗЭС ЭРДЭНИЙН ХУВЬ ХХК</div>
        <div className="text-style" style={{ marginTop: 40, fontSize: ".875rem" }}>Зэс Эрдэнийн
          хувь ХХК нь гадаад худалдаа, гидрометаллургийн аргаар катодын зэс гарган авах үйл ажиллагаа эрхлэхээр 2010 оны
          6-р сарын 21-ний өдөр үүсгэн байгуулагдсан бөгөөд Орхон аймгийн Иргэдийн төлөөлөгчдийн хурал, Эрдэнэт Үйлдвэр
          ХХК-тэй 2013 оны 5-р сарын 02-ны өдөр 3 талт хамтран ажиллах гэрээ байгуулж, Уулын баяжуулах Эрдэнэт үйлдвэр
          ТӨҮГ-ын 8а болон 12-р овоолгыг ашиглан гидрометаллургийн аргаар катодын цэвэр зэс боловсруулах үйлдвэрийн
          төсөл хэрэгжүүлэх нөхцөл бүрдсэн.
        </div>
        <div className="text-style" style={{ marginTop: 5, fontSize: ".875rem"  }}>Зэс эрдэнийн
          хувь ХХК нь Эрдэнэт үйлдвэр ТӨҮГ-ын технологийн бус исэлдсэн хүдэр болон холимог хүдрийн овоолгод түшиглэн
          жилд 10.0 мян.тн, 99.99%-ийн цэвэршилттэй катодын зэс үйлдвэрлэх хүчин чадалтай SX/EW үйлдвэрийг Орхон аймгийн
          Баян-Өндөр сумын Баянцагаан баг, Сархиа уулын зүүн урд энгэрт байгуулахаар, үйлдвэрийн бүтээн байгуулалтын
          ажлыг 2019 оноос эхлэн эрчимжүүлэн шат дараатайгаар гүйцэтгэж байна.
        </div>
        <div className="text-style" style={{ marginTop: 20, fontSize: ".875rem"  }}>2019 оноос
          Компанийн хөрөнгө оруулагч, удирдлагын баг өөрчлөгдөж Үндэсний хөрөнгө оруулагч болон уул уурхай, санхүүгийн
          чиглэлээр мэргэшсэн мэргэжлийн баг төслийг амжилттай хэрэгжүүлэх нөхцөлийг бүрдүүлэн, төслийн бүтээн
          байгуулалтын ажлыг амжилттай хэрэгжүүлж байна.
        </div>
      </div>}

      {newsTitleId === 4 && <div style={{display: 'flex', justifyContent: 'center', marginTop: "2rem", marginBottom: 75}}>
        <div className="container-title-style-main">ХУВЬЦАА ЭЗЭМШИГЧДИЙН ЭЭЛЖИТ ХУРЛЫН ЗАР</div>
      </div>}

      {newsTitleId === 4 && <div className="margin-style-real" style={{display: 'flex', flexDirection: 'column'}}>
        <div className="investment-meeting-container">
          <div className="text1">Хаяг:</div>
          <div className="text2">Орхон аймаг, Баян-Өндөр сум, 11-р баг, Цагаанчулуут, 7-р хороолол , Спорт цогцолбор </div>
        </div>
        <div className="investment-line"/>
        <div className="investment-meeting-container">
          <div className="text1">Утас:</div>
          <div className="text2">70358008, 70358009 </div>
        </div>
        <div className="investment-line"/>
        <div className="investment-meeting-container">
          <div className="text1">И-мэйл:</div>
          <div className="text2">info@zes-erdene.mn</div>
        </div>
        <div className="investment-line"/>
        <div className="investment-meeting-container">
          <div className="text1">Хувьцаа Эзэмшигчдийн Хурал Хуралдах Өдөр, Цаг:</div>
          <div className="text2">2024 оны 4 сарын 30-ны өдөр 10:00 цаг </div>
        </div>
        <div className="investment-line"/>
        <div className="investment-meeting-container">
          <div className="text1">Хурал хуралдах газар /хаяг, байршил/:</div>
          <div className="text2">Орхон аймаг, Баян-Өндөр сум, Уурхайчин соёлын ордны хурлын танхим</div>
        </div>
        <div className="investment-line"/>
        <div className="investment-meeting-container">
          <div className="text1" style={{ width: 450 }}>Хуралд Оролцох Эрхтэй Хувьцаа Эзэмшигчдийн Нэрсийн Жагсаалт Гаргах Бүртгэлийн Өдөр:</div>
          <div className="text2">2024 оны 4 сарын 30-ны өдөр</div>
        </div>
        <div className="investment-line"/>
        <div className="investment-meeting-container">
          <div className="text1">Хувьцаа эзэмшигчдийн хурлын хэлэлцэх асуудал:</div>
          <div className="text2" style={{ display: 'flex', flexDirection: 'column' }}>
            <span style={{ marginBottom: 5 }}>Компанийн 2023 оны үйл ажиллагааны болон санхүүгийн тайлангийн талаарх Төлөөлөн Удирдах Зөвлөлийн дүгнэлтийг хэлэлцэн батлах.</span>
          </div>
        </div>
        <div className="investment-line"/>
        <div className="investment-meeting-container">
          <div className="text1" style={{ width: 450 }}>Хувьцаа эзэмшигчдийн хурлаас гарах шийдвэрийн төсөл, түүнтэй холбогдох баримт бичигтэй танилцах журам, газар, ажиллах цаг: </div>
          <div className="text2">Хувьцаа эзэмшигчид 2024 оны 4 дүгээр сарын 05-наас 2024 оны 4 дүгээр сарын 29-ны өдрийн 15:00 цаг хүртэл Орхон аймаг, Баян-Өндөр сум, 11-р баг, Цагаанчулуут, 7-р хороолол , Спорт цогцолбор  байранд ирж хурлын баримт бичигтэй танилцаж, хурлаас гарах шийдвэрт Саналын хуудсаар урьдчилан саналаа өгч болно. 
Хурал зохион байгуулах комисс болон Тооллогын комисс Даваа-Баасан гаригт 10:00-13:00, 14:00-18:00 цагийн хооронд ажиллана. 
</div>
        </div>
        <div className="investment-line"/>
        <div className="investment-meeting-container">
          <div className="text1" style={{ width: 450 }}>Хурал Зохион Байгуулах Комиссын Дарга, Гишүүдийн Мэдээлэл:</div>
          <div className="text2" style={{ display: 'flex', flexDirection: 'column' }}>
            <span style={{ marginBottom: 5, display: "flex", justifyContent: "space-between" }}><span>Б.Мөнхбаатар</span>	<span>Гүйцэтгэх захирал</span></span>
            <span style={{ marginBottom: 5, display: "flex", justifyContent: "space-between" }}><span>Б.Дэлгэржаргал</span>	<span>Санхүү, үйл ажиллагаа хариуцсан захирал</span></span>
            <span style={{ marginBottom: 5, display: "flex", justifyContent: "space-between" }}><span>Г.Лхагвацэцэг</span>		<span>Захиргааны менежер /ТУЗ-ийн нарийн бичгийн дарга</span></span>
            <span style={{ marginBottom: 5 , display: "flex", justifyContent: "space-between"}}><span>Д.Бадамгарав</span>		<span>Ахлах нягтлан бодогч</span></span>
            <span style={{ marginBottom: 5, display: "flex", justifyContent: "space-between" }}><span>Ц.Даваадорж</span>		<span>Захиргаа хүний нөөцийн менежер</span></span>
            <span style={{ marginBottom: 5 , display: "flex", justifyContent: "space-between"}}><span>М.Цэнгэлмаа</span> 		<span>Хүний нөөцийн ажилтан</span></span>
            <span style={{ marginBottom: 5 , display: "flex", justifyContent: "space-between"}}><span>Д.Алтанзул</span>		<span>Бичиг хэрэг, архивын ажилтан </span></span>
            <span style={{ marginBottom: 5, display: "flex", justifyContent: "space-between" }}><span>Э.Батдэлгэр</span>		<span>Хангамжийн ахлах ажилтан</span></span>
            <span style={{ marginBottom: 5 , display: "flex", justifyContent: "space-between"}}><span>П.Батболд</span>		<span>ХАБЭАБО-ны хэлтсийн менежер</span></span>
            <span style={{ marginBottom: 5, display: "flex", justifyContent: "space-between" }}><span>С.Энхбат</span>		<span>Гүйцэтгэх захирлын туслах</span></span>
            <span style={{ marginBottom: 5 , display: "flex", justifyContent: "space-between"}}><span>Б.Сансар</span>		<span>Байгаль орчны мэргэжилтэн</span></span>
            <span style={{ marginBottom: 5, display: "flex", justifyContent: "space-between" }}><span>Б.Энхтуяа</span>		<span>Хувьцаа эзэмшигчийн ажлын албаны менежер</span></span>
          </div>
        </div>
        <div className="investment-line"/>
        <div className="investment-meeting-container">
          <div className="text1">Нэмэлт Мэдээлэл:</div>
          <div className="text2">Компанийн тухай хуулийн 66 дугаар зүйлийн 66.1 дэх хэсэгт заасны дагуу санхүүгийн жил дууссанаас хойш 60 хоногийн дотор Компанийн энгийн хувьцааны 5 буюу түүнээс дээш хувийг эзэмшиж буй хувьцаа эзэмшигчээс Хувьцаа эзэмшигчдийн хурлаар хэлэлцүүлэх асуудлын жагсаалтад оруулах нэмэлт санал болон Төлөөлөн удирдах зөвлөл, тооллогын комиссын гишүүнд нэр дэвшүүлэх хүний талаарх бичгээр гаргасан санал ирээгүй болохыг дурьдаж байна.</div>
        </div>
        <div className="investment-line"/>
      </div>}


      {newsTitleId === 4 && <div style={{display: 'flex', justifyContent: 'center', marginTop: 160, marginBottom: 50}}>
        <span className="container-title-style-main">ХУВЬЦАА ЭЗЭМШИГЧДИЙН ХУРЛЫН МАТЕРИАЛ</span>
      </div>}

      {newsTitleId === 4 &&
        <div className="margin-style-real">
      <h4>АУДИТЛАГДСАН САНХҮҮГИЙН ТАЙЛАН</h4>
      <FileCard text="2022" filetype="PDF" size="4.89mb"/>
      <FileCard text="2022" filetype="PDF" size="4.89mb"/>
      <FileCard text="2022" filetype="PDF" size="4.89mb"/>
      <FileCard text="2022" filetype="PDF" size="4.89mb"/>
      </div>
      }
      <div>
        <NewsComponentHome />
      </div>

      

    </React.Fragment>
  )
}
