import React, {useEffect, useState} from "react";
import { DateInput, CheckBox, } from 'grommet';
import Select from 'react-select'
import { unitMeasurement, currencyType, choiceType, unitMeasurementTime } from "./staticData";
import WorkService from "../../services/workService";
import moment from "moment";
import {toast, ToastContainer} from "react-toastify";
import { Modal } from "antd";
import {Country, mainType, workDirectionData} from "../Login/staticData";
import {useHistory} from "react-router-dom";

export default function PurchaseAnket() {
  const [workCompanyPosition, setWorkCompanyPosition] = useState('');
  const [unitValue, setUnitValue] = useState(unitMeasurement[0]);
  const [unUitValueTime, setUnitValueTime] = useState(unitMeasurementTime[0]);
  const [unitCurrency, setUnitCurrency] = useState(currencyType[0]);
  const [noatValue, setNoatValue] = useState(choiceType[0])
  const [availableDate, setAvailableDate] = useState((new Date()).toLocaleDateString())
  const [purchaseName, setPurchaseName] = useState('');
  const [purchaseServiceName, setPurchaseServiceName] = useState('');
  const [purchaseFashion, setPurchaseFashion] = useState('');
  const [purchaseIndo, setPurchaseIndo] = useState('');
  const [purchaseCountry, setPurchaseCountry] = useState('');
  const [purchaseQuantity, setPurchaseQuantity] = useState(1);
  const [purchaseEachPrice, setPurchaseEachPrice] = useState(0);
  const [purchaseUnitMin, setPurchaseUnitMin] = useState(0);
  const [sumAmount, setSumAmount] = useState('');
  const [paymentCond, setPaymentCond] = useState('');
  const [purchaseWorkService, setPurchaseWorkService] = useState('');
  const [warrantyPeriod, setWarrantyPeriod] = useState('');
  const [warrantyTerms, setWarrantyTerms] = useState('');
  const [userCode, setUserCode] = useState('');
  const [hrefId, setHrefId] = useState(null);
  const [visible1, setVisible1] = useState(false);
  const [visible2, setVisible2] = useState(false);
  const [visible3, setVisible3] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [checkedPerson, setCheckedPerson] = useState(true);
  const [checkedCompany, setCheckedCompany] = useState(false);
  const [foriegnInvestTrue, setForiegnInvestTrue] = useState(true);
  const [foriegnInvestFalse, setForiegnInvestFalse] = useState(false);
  const [modalPosition, setModalPosition] = useState('');
  const [mainName, setMainName] = useState('');
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [compType, setCompType] = useState(mainType[0]);
  const [regNo, setRegNo] = useState('');
  const [dirWork, setDirWork] = useState('');
  const [chairManNumber, setChairManNumber] = useState('');
  const [employeNumber, setEmployeNumber] = useState('');
  const [address, setAddress] = useState('');
  const [phone1, setPhone1] = useState('');
  const [phone2, setPhone2] = useState('');
  const [email, setEmail] = useState('');
  const [facebook, setFacebook] = useState('');
  const [instagram, setInstagram] = useState('');
  const [twitter, setTwitter] = useState('');
  const [linked, setLinked] = useState('');
  const [workDir, setWorkDir] = useState();
  const [dirCompany, setDirCompany] = useState('');
  const [dirSeller, setDirSeller] = useState('');
  const [dirRep, setDirRep] = useState('');
  const [dirDespor, setDirDespor] = useState('');
  const [workDirectionList, setWorkDirectionList] = useState([]);
  const [subWorkerList, setSubWorkerList] = useState([]);
  const [subFirstName, setSubFirstName] = useState('');
  const [subLastName, setSubLastName] = useState('');
  const [subPosition, setSubPosition] = useState('');
  const [subPhone, setSubPhone] = useState('');
  const [subEmail, setSubEmail] = useState('');
  const [typeId, setTypeId] = useState(1);
  const [supplier, setSupplier] = useState(null);
  const [isSent, setIsSent] = useState(false);
  let history = useHistory();

  useEffect(() => {
    const location = window.location.href;
    const id = location.substring(location.lastIndexOf('/') + 1);
    setHrefId(id);
    getData(id);
  }, [window.location.href])

  const getData = async (id) => {
    const result = await WorkService.getPurchaseId(id);
    setPurchaseName(result[0].name)
  }

  const handleUserCode = (event) => {
    setUserCode(event.target.value);
  }

  const handleWarrantyTerms = (event) => {
    setWarrantyTerms(event.target.value);
  }

  const handleWarrantyPeriod = (event) => {
    setWarrantyPeriod(event.target.value);
  }

  const handlePurchaseWorkService = (event) => {
    setPurchaseWorkService(event.target.value);
  }

  const handlePaymentCond = (event) => {
    setPaymentCond(event.target.value);
  }

  const handleSumAmount = (event) => {
    setSumAmount(event.target.value);
  }

  const handlePurchaseEachPrice = (event) => {
    setPurchaseEachPrice(event.target.value);
    setPurchaseUnitMin(event.target.value * purchaseQuantity)
  }

  const handlePurchaseQuantity = (event) => {
    setPurchaseQuantity(event.target.value);
    setPurchaseUnitMin(event.target.value * purchaseEachPrice)
  }

  const handlePurchaseCountry = (event) => {
    setPurchaseCountry(event.target.value);
  }

  const handlePurchaseIndo = (event) => {
    setPurchaseIndo(event.target.value);
  }

  const handlePurchaseFashion = (event) => {
    setPurchaseFashion(event.target.value);
  }

  const handlePurchaseServiceName = (event) => {
    setPurchaseServiceName(event.target.value);
  }

  const handlePurchaseName = (event) => {
    setPurchaseName(event.target.value);
  }

  const handleWorkCompany = (event) => {
    setWorkCompanyPosition(event.target.value);
  }

  const getSupplier = async () => {
    if (userCode === '') return toast("Хэрэглэгчийн код хоосон байна", { type: 'warning' });
    if (userCode.length !== 6)
      return toast("Хэрэглэгчийн код 6 оронтой биш байна!", { type: 'warning' });
    const supplierTmp = await WorkService.getSupplierById(userCode);
    // console.log(supplierTmp.data.is_sent);
    setIsSent(supplierTmp.data.is_sent)
    if (supplierTmp.data.supplier.length === 0){
      return toast("Хэрэглэгч бүртгэлгүй байна", { type: 'warning' });
    }
    await setSupplier(supplierTmp.data.supplier[0])
  }

  const savePurchaseBtn = async () => {
    if (!supplier) return toast("Хэрэглэгчийн мэдээлэл татагдаагүй байна", { type: 'warning' });
    if (purchaseServiceName === '') return toast("Бараа, ажил, үйлчилгээний нэр хоосон байна", { type: 'warning' });
    if (purchaseFashion === '') return toast("Загвар хоосон байна", { type: 'warning' });
    if (purchaseIndo === '') return toast("Үзүүлэлт хоосон байна", { type: 'warning' });
    if (purchaseCountry === '') return toast("Үйлдвэрлэсэн улс хоосон байна", { type: 'warning' });
    if (workCompanyPosition === '') return toast("Үйлдвэрлэгчийн нэр хоосон байна", { type: 'warning' });
    if (purchaseEachPrice === '') return toast("Нэгжийн үнэ хоосон байна", { type: 'warning' });
    if (purchaseUnitMin === '') return toast("Нийт үнэ хоосон байна", { type: 'warning' });
    if (sumAmount === '') return toast("Нийт үнийн санал хоосон байна", { type: 'warning' });
    if (paymentCond === '') return toast("Төлбөрийн нөхцөл хоосон байна", { type: 'warning' });
    if (purchaseWorkService === '') return toast("Нийлүүлэх газар, нөхцөл хоосон байна", { type: 'warning' });
    if (warrantyPeriod === '') return toast("Баталгаат хугацаа хоосон байна", { type: 'warning' });
    if (warrantyTerms === '') return toast("Баталгааны нөхцөл хоосон байна", { type: 'warning' });

    const aa = {
      type: typeId,
      purchase_id: hrefId,
      supplier_id: supplier.id,
      mark: 'mark',
      model: purchaseFashion,
      name: purchaseName,
      produce_name: purchaseServiceName,
      indicator: purchaseIndo,
      unit: unitValue.value,
      count: purchaseQuantity,
      unit_amount: purchaseEachPrice,
      total_amount: sumAmount,
      currency: unitCurrency.value,
      has_vat: noatValue.value,
      payment_condition: paymentCond,
      delivery_time: availableDate,
      warranty_time: '2024/01/01',
      attachment: '/uploads/document',
      user_code: userCode
    };

    const result = await WorkService.savePurchase(aa);
    if (result.success){
      return toast("Амжилттай бүртгэллээ", { type: 'success' });
    }
  }

  const handleClose = () => setIsOpen(false);
  const handleClose1 = () => setVisible1(false);
  const handleClose2 = () => setVisible2(false);
  const handleClose3 = () => setVisible3(false);

  const changeTrue = () => {
    if (!foriegnInvestTrue) {
      setForiegnInvestTrue(true);
      setForiegnInvestFalse(false);
    }
  };

  const changeFalse = () => {
    if (!foriegnInvestFalse) {
      setForiegnInvestTrue(false);
      setForiegnInvestFalse(true);
    }
  };

  const changePerson = () => {
    if (!checkedPerson) {
      setCheckedPerson(true);
      setCheckedCompany(false);
    }
  };

  const changeCompany = () => {
    if (!checkedCompany) {
      setCheckedPerson(false);
      setCheckedCompany(true);
    }
  };

  const saveData = async () => {
    if (checkedCompany) {
      if (mainName === '') return toast("Хуулийн этгээдийн нэр оруулна уу.", { type: 'warning' });
      if (!modalPosition.value) return toast("Харъяалал сонгоно уу.", { type: 'warning' });
      if (regNo === '') return toast("Регистрийн дугаараа оруулна уу.", { type: 'warning' });
      if (dirWork === '') return toast("Үйл ажиллагааны чиглэл оруулна уу.", { type: 'warning' });
      if (address === '') return toast("Хаягаа оруулна уу.", { type: 'warning' });
      if (phone1 === '') return toast("Утасны дугаараа оруулна уу.", { type: 'warning' });
      if (email === '') return toast("И-мэйл хаягаа оруулна уу.", { type: 'warning' });
      const org = {
        ent_type :  'Байгууллага',
        ent_option :  compType.value,
        foreign_investment :  foriegnInvestTrue ? 1 : 0,
        first_name :  mainName,
        country :  modalPosition.value,
        main_activity :  dirWork,
        shareholders_count :  chairManNumber,
        worker_count :  employeNumber,
        regno :  regNo,
        address :  address,
        phone1 :  phone1,
        phone2 :  phone2,
        email :  email,
        website :  '',
        social :  [
          {name : 'facebook', value: facebook},
          {name : 'twitter', value: twitter},
          {name : 'instagram', value: instagram},
          {name : 'linkedin', value: linked}
        ],
        activity :  [
          {name : 'Гадаад худалдаа', value: '1'},
          {name : 'Гадаад худалдаа', value: '2'},
          {name : 'Гадаад худалдаа', value: '3'}
        ],
        supply_brandName : workDirectionList,
        agent_info :  subWorkerList,
        can_supply :  [
          {name: ''},
          {name: ''},
          {name: ''},
          {name: ''},
        ]
      };

      const result = await WorkService.addSupplier(org);
      if (result.success){
        setIsOpen(false);
        setUserCode(result.data.generate_number);
        setVisible3(true);
        return toast("Амжилттай бүртгэлээ", { type: 'success' });
      }
    } else {
      if (mainName === '') return toast("Ургийн овгоо оруулна уу.", { type: 'warning' });
      if (firstName === '') return toast("Эцэг эхийн нэрээ оруулна уу.", { type: 'warning' });
      if (lastName === '') return toast("Өөрийн нэрээ оруулна уу.", { type: 'warning' });
      if (regNo === '') return toast("Регистрийн дугаараа оруулна уу.", { type: 'warning' });
      if (address === '') return toast("Хаягаа оруулна уу.", { type: 'warning' });
      if (phone1 === '') return toast("Утасны дугаараа оруулна уу.", { type: 'warning' });
      if (email === '') return toast("И-мэйл хаягаа оруулна уу.", { type: 'warning' });
      const ind = {
        ent_type :  'Иргэн',
        family_name :  mainName,
        first_name :  firstName,
        last_name :  lastName,
        country :  modalPosition,
        regno :  regNo,
        address :  address,
        phone1 :  phone1,
        phone2 :  phone2,
        email :  email,
        website :  '/',
        social :  [
          {name : 'facebook', value: facebook},
          {name : 'twitter', value: twitter},
          {name : 'instagram', value: instagram},
          {name : 'linkedin', value: linked}
        ],
        activity :  [],
        supply_brandName : workDirectionList,
        agent_info :  subWorkerList,
        can_supply :  [],
      };
      const result = await WorkService.addSupplier(ind);
      if (result.success){
        setIsOpen(false);
        setUserCode(result.data.generate_number);
        setVisible3(true);
        return toast("Амжилттай бүртгэллээ", { type: 'success' });
      }
    }
  }

  const addDirWork = () => {
    if (dirCompany === '') return toast("Үйлдвэрлэгч хоосон байна", { type: 'warning' });
    if (dirSeller === '') return toast("Барааны нэр хоосон байна", { type: 'warning' });
    if (dirRep === '') return toast("Жил хоосон байна", { type: 'warning' });
    let list = [...workDirectionList];
    let data = {
      produceType: dirCompany,
      name: dirSeller,
      year: dirRep,
    }
    list.push(data);
    setDirCompany('');
    setDirSeller('');
    setDirRep('');
    setWorkDirectionList(list);
    handleClose1();
  }

  const addInvestors = () => {
    if (subFirstName === '') return toast("Нэр хоосон байна", { type: 'warning' });
    if (subLastName === '') return toast("Овог хоосон нэр", { type: 'warning' });
    if (subPosition === '') return toast("Ажлын байр хоосон нэр", { type: 'warning' });
    if (subPhone === '') return toast("Утас хоосон нэр", { type: 'warning' });
    if (subEmail === '') return toast("И-мэйл хоосон нэр", { type: 'warning' });
    let list = [...subWorkerList];
    let data = {
      firstName: subFirstName,
      lastName: subLastName,
      position: subPosition,
      phone: subPhone,
      phone2: subPhone,
      email: subEmail
    }
    list.push(data);
    setSubWorkerList(list);
    setSubFirstName('');
    setSubLastName('');
    setSubPosition('');
    setSubPhone('');
    setSubEmail('');
    handleClose2();
  }

  const dirWorkItem = (data) => {
    let list = [...workDirectionList];
    const index = list.findIndex(element => element.name === data.name && element.direction === data.direction &&
      element.seller === data.seller && element.repo === data.repo && element.deso === data.deso);
    list.splice(index, 1);
    setWorkDirectionList(list);
  }

  const subWorkItem = (data) => {
    let list = [...subWorkerList];
    const index = list.findIndex(element => element.firstName === data.firstName && element.lastName === data.lastName);
    list.splice(index, 1);
    setSubWorkerList(list);
  }

  return(
    <React.Fragment>
      <Modal
        open={visible3}
        footer={null}
        onCancel={()=>setVisible3(false)}
       className="form-modal"
      >
        <div className="show-modal">
          <div style={{ fontWeight: 'bold', fontSize: 60 }}>{userCode}</div>
          <div style={{ margin: 20, textAlign: 'justify', fontSize: 15, color: "#EE2828" }}>
            Энэхүү код нь таны хэрэглэгчийн код ба зөвхөн нэг л удаа авах боломжтойг анхаарна уу. Та тэмдэглэж аваарай
          </div>
        </div>
      </Modal>
      <Modal
        open={visible2}
        footer={null}
        onCancel={()=>setVisible2(false)}
        className="form-modal"
      >
        <div className="login-sub-modal">
          <div style={{ width: '100%', display: 'flex', justifyContent: 'flex-end', marginTop: 30, cursor: "pointer" }}>
          </div>
          <input placeholder='Овог' className="input-style"
                 value={subLastName} onChange={(event) => setSubLastName(event.target.value)}/>
          <input placeholder='Нэр' className="input-style"
                 value={subFirstName} onChange={(event) => setSubFirstName(event.target.value)}/>
          <input placeholder='Албан тушаал' className="input-style"
                 value={subPosition} onChange={(event) => setSubPosition(event.target.value)}/>
          <input placeholder='Утас' className="input-style" type="number"
                 value={subPhone} onChange={(event) => setSubPhone(event.target.value)}/>
          <input placeholder='И-мэйл хаяг' className="input-style"
                 value={subEmail} onChange={(event) => setSubEmail(event.target.value)}/>
          <div className="btn" onClick={() => addInvestors()}>
            Нэмэх
          </div>
        </div>
      </Modal>
      <Modal
        open={visible1}
        footer={null}
        onCancel={()=>setVisible1(false)}
        className="form-modal"
      >
        <div className="login-sub-modal">
          <div style={{ width: '100%', display: 'flex', justifyContent: 'flex-end', marginTop: 30, cursor: "pointer" }}>
          </div>
          <input placeholder='Үйлдвэрлэгч' className="input-style"
                 value={dirCompany} onChange={(event) => setDirCompany(event.target.value)}/>
          <input placeholder='Барааны нэр' className="input-style"
                 value={dirSeller} onChange={(event) => setDirSeller(event.target.value)}/>
          <input placeholder='Үйл ажиллагаа эрхэлж буй хугацаа' className="input-style" type="number"
                 value={dirRep} onChange={(event) => setDirRep(event.target.value)}/>
          <div className="btn" onClick={() => addDirWork()}>
            Нэмэх
          </div>
        </div>
      </Modal>
      <Modal
        open={isOpen}
        footer={null}
        onCancel={()=>setIsOpen(false)}
        className="form-modal"
      >
        <div className="login-modal">
          <div className="modal-header">
            <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', width: '100%' }}>
              <div className="text">
                Оролцогчоор Бүртгүүлэх
              </div>
            </div>
            <div style={{ width: '100%', backgroundColor: '#707070', height: 1, marginTop: 36 }}/>
          </div>
          <div className="login-body">
            <div className="row-style">
              <div className="text1">Оролцогчийн төрөл:</div>
              <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                <div className={`checkBox-style ${checkedPerson ? 'isActive' : ''}`} onClick={() => changePerson()}/>
                <div className="text2">Иргэн</div>
              </div>
              <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                <div className={`checkBox-style ${checkedCompany ? 'isActive' : ''}`} onClick={() => changeCompany()}/>
                <div className="text2">Хуулийн Этгээд</div>
              </div>
            </div>
            {checkedPerson && <div className="bold-text">Иргэний мэдээлэл:</div>}
            {checkedCompany && <div className="bold-text">Хуулийн этгээдийн мэдээлэл:</div>}
            <input placeholder={checkedPerson ? 'Ургийн овог' : 'Хуулийн этгээдийн нэр'} className="input-style"
                   value={mainName} onChange={(event) => setMainName(event.target.value)}/>
            {checkedPerson && <input placeholder="Эцэг, эхийн нэр" className="input-style"
                                     value={lastName} onChange={(event) => setLastName(event.target.value)}/>}
            {checkedPerson && <input placeholder="Өөрийн нэр" className="input-style"
                                     value={firstName} onChange={(event) => setFirstName(event.target.value)}/>}
            {checkedCompany && <div style={{marginTop: 15}}>
              <Select
                options={mainType}
                value={compType}
                onChange={(value) => setCompType(value)}
              />
            </div>}
            {checkedCompany && <div className="row-style">
              <div className="text1">Гадаадын хөрөнгө оруулалттай эсэх:</div>
              <div style={{display: 'flex', flexDirection: 'row', alignItems: 'center'}}>
                <div className={`checkBox-style ${foriegnInvestTrue ? 'isActive' : ''}`} onClick={() => changeTrue()}/>
                <div className="text2">Тийм</div>
              </div>
              <div style={{display: 'flex', flexDirection: 'row', alignItems: 'center'}}>
                <div className={`checkBox-style ${foriegnInvestFalse ? 'isActive' : ''}`}
                     onClick={() => changeFalse()}/>
                <div className="text2">Үгүй</div>
              </div>
            </div>}
            {checkedCompany && <div style={{marginTop: 30}}>
              <Select
                options={Country}
                placeholder="Харъяалал"
                value={modalPosition}
                onChange={(value) => setModalPosition(value)}
              />
            </div>}
            <input placeholder="Регистрийн дугаар" className="input-style"
                   value={regNo} onChange={(event) => setRegNo(event.target.value)}/>
            {checkedCompany && <input placeholder="Үйл ажиллагааны чиглэл" className="input-style"
                                      value={dirWork} onChange={(event) => setDirWork(event.target.value)}/>}
            {checkedCompany && <div className="row-style">
              <input placeholder="Хувьцаа эзэмшигчийн тоо" className="input-style" style={{marginRight: 15}} type="number"
                     value={chairManNumber} onChange={(event) => setChairManNumber(event.target.value)}/>
              <input placeholder="Ажилчдын тоо" className="input-style" style={{marginLeft: 15}} type="number"
                     value={employeNumber} onChange={(event) => setEmployeNumber(event.target.value)}/>
            </div>}
            <div className="bold-text">Холбоо барих мэдээлэл:</div>
            <input placeholder="Хаяг" className="input-style"
                   value={address} onChange={(event) => setAddress(event.target.value)}/>
            <div className="row-style">
              <input placeholder="Утасны дугаар 1" className="input-style" style={{ marginRight: 15 }} type="number"
                     value={phone1} onChange={(event) => setPhone1(event.target.value)}/>
              <input placeholder="Утасны дугаар 2" className="input-style" style={{ marginLeft: 15 }} type="number"
                     value={phone2} onChange={(event) => setPhone2(event.target.value)}/>
            </div>
            <input placeholder="И-мэйл хаяг" className="input-style"
                   value={email} onChange={(event) => setEmail(event.target.value)}/>
            <div className="row-style">
              <input placeholder="Facebook" className="input-style" style={{ marginRight: 15 }}
                     value={facebook} onChange={(event) => setFacebook(event.target.value)}/>
              <input placeholder="Twitter" className="input-style" style={{ marginLeft: 15 }}
                     value={twitter} onChange={(event) => setTwitter(event.target.value)}/>
            </div>
            <div className="row-style">
              <input placeholder="Instagram" className="input-style" style={{ marginRight: 15 }}
                     value={instagram} onChange={(event) => setInstagram(event.target.value)}/>
              <input placeholder="Linkedin" className="input-style" style={{ marginLeft: 15 }}
                     value={linked} onChange={(event) => setLinked(event.target.value)}/>
            </div>
            <div className="bold-text">Үйл ажиллагааны мэдээлэл:</div>
            <div style={{marginTop: 15}}>
              <Select
                options={workDirectionData}
                placeholder="Үйл ажиллагааны чиглэл"
                value={workDir}
                onChange={({option}) => setWorkDir(option)}
              />
            </div>
            <div className="bold-text">Нийлүүлэх боломжтой бараа, ажил, үйлчилгээ:</div>
            <div className="plus-btn" onClick={() => setVisible1(true)}>
              Нэмэх
            </div>
            <div className="dir-work-container">
              {workDirectionList.length > 0 && workDirectionList.map((data, index) => (
                <div className="box" key={index}>
                  <div style={{ display: 'flex', width: '98%', justifyContent: 'flex-end', marginBottom: 10, marginTop: 10 }}>
                  </div>
                  <div className="container">
                    {/*<div style={{ display: 'flex', flexDirection: 'column' }}>*/}
                    {/*  <div style={{ fontSize: 15, color: '#000000', fontWeight: 500 }}>Үйл ажиллагааны чиглэл</div>*/}
                    {/*  <div style={{ fontSize: 15, opacity: 0.5, color: '#000000', marginTop: 10 }}>{data.direction}</div>*/}
                    {/*</div>*/}
                    <div style={{ display: 'flex', flexDirection: 'row', justifyContent: "space-between" }}>
                      <div style={{ fontSize: 15, color: '#000000', fontWeight: 500 }}>Үйлдвэрлэгч</div>
                      <div style={{ fontSize: 15, opacity: 0.5, color: '#000000', marginTop: 10 }}>{data.produceType}</div>
                    </div>
                    <div style={{ display: 'flex', flexDirection: 'row', justifyContent: "space-between" }}>
                      <div style={{ fontSize: 15, color: '#000000', fontWeight: 500 }}>Барааны нэр</div>
                      <div style={{ fontSize: 15, opacity: 0.5, color: '#000000', marginTop: 10 }}>{data.name}</div>
                    </div>
                    <div style={{ display: 'flex', flexDirection: 'row', justifyContent: "space-between" }}>
                      <div style={{ fontSize: 15, color: '#000000', fontWeight: 500 }}>Жил</div>
                      <div style={{ fontSize: 15, opacity: 0.5, color: '#000000', marginTop: 10 }}>{data.year}</div>
                    </div>
                  </div>
                </div>
              ))}
            </div>
            {checkedCompany && <div className="bold-text">Төлөөлөх эрх бүхий албан тушаалтны мэдээлэл:</div>}
            {checkedCompany && <div className="plus-btn" onClick={() => setVisible2(true)}>
              Нэмэх
            </div>}
            {checkedCompany && <div className="dir-work-container">
              {subWorkerList.length > 0 && subWorkerList.map((data, index) => (
                <div className="box" key={index}>
                  <div style={{ display: 'flex', width: '99%', justifyContent: 'flex-end', marginBottom: 10, marginTop: 10 }}>
                  </div>
                  <div className="container">
                    <div style={{ display: 'flex', flexDirection: 'row', justifyContent: "space-between" }}>
                      <div style={{ fontSize: 15, color: '#000000', fontWeight: 500 }}>Овог</div>
                      <div style={{ fontSize: 15, opacity: 0.5, color: '#000000', marginTop: 10 }}>{data.lastName}</div>
                    </div>
                    <div style={{ display: 'flex', flexDirection: 'row', justifyContent: "space-between" }}>
                      <div style={{ fontSize: 15, color: '#000000', fontWeight: 500 }}>Нэр</div>
                      <div style={{ fontSize: 15, opacity: 0.5, color: '#000000', marginTop: 10 }}>{data.firstName}</div>
                    </div>
                    <div style={{ display: 'flex', flexDirection: 'row', justifyContent: "space-between" }}>
                      <div style={{ fontSize: 15, color: '#000000', fontWeight: 500 }}>Албан тушаал</div>
                      <div style={{ fontSize: 15, opacity: 0.5, color: '#000000', marginTop: 10 }}>{data.position}</div>
                    </div>
                    <div style={{ display: 'flex', flexDirection: 'row', justifyContent: "space-between" }}>
                      <div style={{ fontSize: 15, color: '#000000', fontWeight: 500 }}>Утас</div>
                      <div style={{ fontSize: 15, opacity: 0.5, color: '#000000', marginTop: 10 }}>{data.phone}</div>
                    </div>
                    <div style={{ display: 'flex', flexDirection: 'row', justifyContent: "space-between" }}>
                      <div style={{ fontSize: 15, color: '#000000', fontWeight: 500 }}>И-мэйл</div>
                      <div style={{ fontSize: 15, opacity: 0.5, color: '#000000', marginTop: 10 }}>{data.email}</div>
                    </div>
                  </div>
                </div>
              ))}
            </div>}
            <div className="btn-container">
              <div className="btn-style" onClick={() => saveData()}>
                <div style={{ color: '#FFFFFF', fontSize: 14, fontWeight: 'bold' }}>Хадгалах</div>
              </div>
            </div>
          </div>
        </div>
      </Modal>
      <ToastContainer />
      <div className="request-form">
        <div className="warning-container">

          <span className="warning-text">Санамж</span>
          <div style={{ display: 'flex', flexDirection: 'row', marginTop: 10 }}>
            <div className="number-container">1</div>
            <div style={{ display: 'flex', flexDirection: 'column' }}>
              <div className="list-text-style">
                Та худалдан авалтын хүсэлт илгээхдээ өөрийн хэрэглэгчийн кодыг цуг илгээх хэрэгтэйг анхаарна уу.
              </div>
              <div className="text-btn-style" onClick={() => setIsOpen(true)}>
                Хэрэв таньд код байхгүй бол энд дарж авна уу.
              </div>
            </div>
          </div>
          <div style={{ display: 'flex', flexDirection: 'row', marginTop: 10 }}>
            <div className="number-container">2</div>
            <div className="list-text-style">Та анкет бөглөхдөө Google Chrome, Mozilla Firefox интернет хөтөчүүд хамгийн сайн тохирохыг анхаарна уу.</div>
          </div>
          <div style={{ display: 'flex', flexDirection: 'row', marginTop: 10 }}>
            <div className="number-container">3</div>
            <div className="list-text-style">Анкетыг бөглөхдөө бүх асуултанд тодорхой хариулж, үг товчлохгүй байхыг хүсье.</div>
          </div>
          <div style={{ display: 'flex', flexDirection: 'row', marginTop: 10 }}>
            <div className="number-container">4</div>
            <div className="list-text-style">Та зөвхөн Крилл үсгийн фонтоор бичнэ үү.</div>
          </div>
          <div style={{ display: 'flex', flexDirection: 'row', marginTop: 10 }}>
            <div className="number-container">5</div>
            <div className="list-text-style">Улаан (*) тэмдэглэсэн талбарыг заавал бөглөх шаардлагатайг анхаарна уу.</div>
          </div>
        </div>
        <div className="title-container">

          {supplier &&  <span>Хэрэглэгчийн нэр: <b>{supplier.first_name}</b></span> }
          {!supplier && <span>Хэрэглэгчийн кодоор мэдээлэл татах</span>}

        </div>
        <div className="field-container-row">
          <div style={{display: 'flex', flexDirection: 'column', width: '50%'}}>
            <br/>
            <div className="row-style">
              <input type="number" placeholder="код" value={userCode} onChange={handleUserCode}
                     style={{width: '90%', height: 40, backgroundColor: "#F7F7F7", border: 0, paddingLeft: 15, borderRadius: 5, marginRight: 15}}
              />
              {!supplier &&
              <div className="button-style">
                <div className="button-box" onClick={() => getSupplier()}>
                  <span className="text">Мэдээлэл татах</span>
                </div>
              </div>
              }
            </div>
            <br/>
          </div>
        </div>

        <br/>
        {!isSent &&
          <div>
          <div className="title-container">Мэдээлэл авсан суваг:</div>
          <div style={{ marginTop: 40, marginLeft: 70 }}>
            <CheckBox
                checked={typeId === 1}
                label="Компанийн вэб сайт"
                onChange={(event) => setTypeId(1)}
            />
          </div>
          <div style={{ marginTop: 20, marginLeft: 70 }}>
            <CheckBox
                checked={typeId === 2}
                label="Компанийн ажилтнаас"
                onChange={(event) => setTypeId(2)}
            />
          </div>
          <div style={{ marginTop: 20, marginLeft: 70 }}>
            <CheckBox
                checked={typeId === 3}
                label="Компанийн хувьцаа эзэмшигчээс"
                onChange={(event) => setTypeId(3)}
            />
          </div>
          <div style={{ marginTop: 20, marginLeft: 70 }}>
            <CheckBox
                checked={typeId === 4}
                label="Бусад"
                onChange={(event) => setTypeId(4)}
            />
          </div>
          <div style={{ marginTop: 40, marginLeft: 70, display: 'flex', flexDirection: 'column', width: '50%' }}>
            <div style={{ display: 'flex', flexDirection: 'column', marginBottom: 20 }}>
              <span>Худалдан авалтын нэр:</span>
              <span className="purchase-text-input">{purchaseName}</span>
            </div>
            {/*<input type="text" placeholder="нэр" value={purchaseName} onChange={handlePurchaseName}*/}
            {/*       style={{ width: '85%', height: 40, backgroundColor: "#F7F7F7", border: 0, paddingLeft: 15, borderRadius: 5 }}*/}
            {/*/>*/}
          </div>
          <div className="title-container" style={{ marginTop: 40 }}>
            <span style={{ color: '#666', fontWeight: 400, fontSize: 25 }}>Нийлүүлэх бараа, ажил үйлчилгээний мэдээлэл:</span>
          </div>
          <div className="field-container-row">
            <div style={{ display: 'flex', flexDirection: 'column', width: '50%' }}>
              <div style={{ display: 'flex', flexDirection: 'row', marginTop: 30, marginBottom: 20 }}>
                <span>Бараа, ажил, үйлчилгээний нэр:</span>
                <span style={{ color: 'red', marginLeft: 2 }}>*</span>
              </div>
              <input type="text" placeholder="нэр" value={purchaseServiceName} onChange={handlePurchaseServiceName}
                     style={{ width: '90%', height: 40, backgroundColor: "#F7F7F7", border: 0, paddingLeft: 15, borderRadius: 5 }}
              />
            </div>
            <div style={{ display: 'flex', flexDirection: 'column', width: '50%' }}>
              <div style={{ display: 'flex', flexDirection: 'row', marginTop: 30, marginBottom: 20 }}>
                <span>Загвар:</span>
                <span style={{ color: 'red', marginLeft: 2 }}>*</span>
              </div>
              <input type="text" placeholder="Загвар" value={purchaseFashion} onChange={handlePurchaseFashion}
                     style={{ width: '90%', height: 40, backgroundColor: "#F7F7F7", border: 0, paddingLeft: 15, borderRadius: 5 }}
              />
            </div>
          </div>
          <div className="field-container-row">
            <div style={{ display: 'flex', flexDirection: 'column', width: '50%' }}>
              <div style={{ display: 'flex', flexDirection: 'row', marginTop: 30, marginBottom: 20 }}>
                <span>Үзүүлэлт:</span>
                <span style={{ color: 'red', marginLeft: 2 }}>*</span>
              </div>
              <input type="text" placeholder="Үзүүлэлт" value={purchaseIndo} onChange={handlePurchaseIndo}
                     style={{ width: '90%', height: 40, backgroundColor: "#F7F7F7", border: 0, paddingLeft: 15, borderRadius: 5 }}
              />
            </div>
            <div style={{ display: 'flex', flexDirection: 'column', width: '50%' }}>
              <div style={{ display: 'flex', flexDirection: 'row', marginTop: 30, marginBottom: 20 }}>
                <span>Үйлдвэрлэсэн улс:</span>
                <span style={{ color: 'red', marginLeft: 2 }}>*</span>
              </div>
              <input type="text" placeholder="Үйлдвэрлэсэн улс" value={purchaseCountry} onChange={handlePurchaseCountry}
                     style={{ width: '90%', height: 40, backgroundColor: "#F7F7F7", border: 0, paddingLeft: 15, borderRadius: 5 }}
              />
            </div>
          </div>
          <div className="field-container-row">
            <div style={{ display: 'flex', flexDirection: 'column', width: '50%' }}>
              <div style={{ display: 'flex', flexDirection: 'row', marginTop: 30, marginBottom: 20 }}>
                <span>Үйлдвэрлэгчийн нэр:</span>
                <span style={{ color: 'red', marginLeft: 2 }}>*</span>
              </div>
              <input type="text" placeholder="нэр" value={workCompanyPosition} onChange={handleWorkCompany}
                     style={{ width: '90%', height: 40, backgroundColor: "#F7F7F7", border: 0, paddingLeft: 15, borderRadius: 5 }}
              />
            </div>
            <div style={{ display: 'flex', flexDirection: 'column', width: '46%' }}>
              <div style={{ display: 'flex', flexDirection: 'row', marginTop: 30, marginBottom: 20 }}>
                <span>Хэмжих нэгж:</span>
                <span style={{ color: 'red', marginLeft: 2 }}>*</span>
              </div>
              <Select
                  options={unitMeasurement}
                  placeholder="нэгж"
                  value={unitValue}
                  onChange={(value) => setUnitValue(value)}
              />
            </div>
          </div>
          <div className="field-container-row">
            <div style={{ display: 'flex', flexDirection: 'column', width: '50%' }}>
              <div style={{ display: 'flex', flexDirection: 'row', marginTop: 30, marginBottom: 20 }}>
                <span>Тоо, хэмжээ:</span>
                <span style={{ color: 'red', marginLeft: 2 }}>*</span>
              </div>
              <input type="number" placeholder="1" value={purchaseQuantity} onChange={handlePurchaseQuantity}
                     style={{ width: '90%', height: 40, backgroundColor: "#F7F7F7", border: 0, paddingLeft: 15, borderRadius: 5 }}
              />
            </div>
            <div style={{ display: 'flex', flexDirection: 'column', width: '50%' }}>
              <div style={{ display: 'flex', flexDirection: 'row', marginTop: 30, marginBottom: 20 }}>
                <span>Нэгжийн үнэ:</span>
                <span style={{ color: 'red', marginLeft: 2 }}>*</span>
              </div>
              <input type="number" placeholder="1,500,000" value={purchaseEachPrice} onChange={handlePurchaseEachPrice}
                     style={{ width: '90%', height: 40, backgroundColor: "#F7F7F7", border: 0, paddingLeft: 15, borderRadius: 5 }}
              />
            </div>
          </div>
          <div className="field-container-row">
            <div style={{ display: 'flex', flexDirection: 'column', width: '50%' }}>
              <div style={{ display: 'flex', flexDirection: 'row', marginTop: 30, marginBottom: 20 }}>
                <span>Нийт үнэ:</span>
                <span style={{ color: 'red', marginLeft: 2 }}>*</span>
              </div>
              <input type="text" placeholder="үнэ" value={purchaseUnitMin} disabled
                     style={{ width: '90%', height: 40, backgroundColor: "#F7F7F7", border: 0, paddingLeft: 15, borderRadius: 5 }}
              />
            </div>
          </div>
          <div className="title-container" style={{ marginTop: 40 }}>
            <span style={{ color: '#666', fontWeight: 400, fontSize: 25 }}>Үнийн санал, төлбөрийн нөхцөл:</span>
          </div>
          <div className="field-container-row">
            <div style={{ display: 'flex', flexDirection: 'column', width: '50%' }}>
              <div style={{ display: 'flex', flexDirection: 'row', marginTop: 30, marginBottom: 20 }}>
                <span>Нийт үнийн санал:</span>
                <span style={{ color: 'red', marginLeft: 2 }}>*</span>
              </div>
              <input type="text" placeholder="5,000,000" value={sumAmount} onChange={handleSumAmount}
                     style={{ width: '90%', height: 40, backgroundColor: "#F7F7F7", border: 0, paddingLeft: 15, borderRadius: 5 }}
              />
            </div>
          </div>
          <div className="field-container-row">
            <div style={{ display: 'flex', flexDirection: 'column', width: '46%', paddingRight: 55 }}>
              <div style={{ display: 'flex', flexDirection: 'row', marginTop: 30, marginBottom: 20 }}>
                <span>Валютын төрөл:</span>
                <span style={{ color: 'red', marginLeft: 2 }}>*</span>
              </div>
              <Select
                  options={currencyType}
                  placeholder="төрөл"
                  value={unitCurrency}
                  onChange={(value) => setUnitCurrency(value)}
              />
            </div>
            <div style={{ display: 'flex', flexDirection: 'column', width: '46%' }}>
              <div style={{ display: 'flex', flexDirection: 'row', marginTop: 30, marginBottom: 20 }}>
                <span>НӨАТ орсон эсэх:</span>
                <span style={{ color: 'red', marginLeft: 2 }}>*</span>
              </div>
              <Select
                  options={choiceType}
                  placeholder="төрөл"
                  value={noatValue}
                  onChange={(value) => setNoatValue(value)}
              />
            </div>
          </div>
          <div className="field-container-row">
            <div style={{ display: 'flex', flexDirection: 'column', width: '50%' }}>
              <div style={{ display: 'flex', flexDirection: 'row', marginTop: 30, marginBottom: 20 }}>
                <span>Төлбөрийн нөхцөл:</span>
                <span style={{ color: 'red', marginLeft: 2 }}>*</span>
              </div>
              <input type="text" placeholder="нөхцөл" value={paymentCond} onChange={handlePaymentCond}
                     style={{ width: '90%', height: 40, backgroundColor: "#F7F7F7", border: 0, paddingLeft: 15, borderRadius: 5 }}
              />
            </div>
          </div>
          <div className="title-container" style={{ marginTop: 40 }}>
            <span style={{ color: '#666', fontWeight: 400, fontSize: 25 }}>Нийлүүлэх хугацаа, нөхцөл:</span>
          </div>
          <div className="field-container-row">
            <div style={{ display: 'flex', flexDirection: 'column', width: '46%', paddingRight: 55 }}>
              <div style={{ display: 'flex', flexDirection: 'row', marginTop: 30, marginBottom: 20 }}>
                <span>Хэмжих нэгж:</span>
                <span style={{ color: 'red', marginLeft: 2 }}>*</span>
              </div>
              <Select
                  options={unitMeasurementTime}
                  value={unUitValueTime}
                  onChange={({ option }) => setUnitValueTime(option)}
              />
            </div>
            <div style={{ display: 'flex', flexDirection: 'column', width: '46%' }}>
              <div style={{ display: 'flex', flexDirection: 'row', marginTop: 30, marginBottom: 20 }}>
                <span>Нийлүүлэх хугацаа:(Нийлүүлэх хугацааг тодорхой тусгана):</span>
                <span style={{ color: 'red', marginLeft: 2 }}>*</span>
              </div>
              <DateInput
                  format="mm/dd/yyyy"
                  value={availableDate}
                  onChange={({ value }) => {setAvailableDate(value)}}
              />
            </div>
          </div>
          <div className="field-container-row">
            <div style={{ display: 'flex', flexDirection: 'column', width: '100%' }}>
              <div style={{ display: 'flex', flexDirection: 'row', marginTop: 30, marginBottom: 20 }}>
                <span>Нийлүүлэх газар, нөхцөл:(Бараа, ажил, үйлчилгээг нийлүүлэх газар, тээвэрлэлтийн нөхцөлийг тодорхой тусгана.):</span>
                <span style={{ color: 'red', marginLeft: 2 }}>*</span>
              </div>
              <textarea type="text" placeholder="Бараа, ажил, үйлчилгээг ..." value={purchaseWorkService} onChange={handlePurchaseWorkService}
                        style={{ width: '95%', height: 40, backgroundColor: "#F7F7F7", border: 0, paddingLeft: 15, borderRadius: 5 }}
              />
            </div>
          </div>
          <div className="title-container" style={{ marginTop: 40 }}>
            <span style={{ color: '#666', fontWeight: 400, fontSize: 25 }}>Баталгаа:</span>
          </div>
          <div className="field-container-row">
            <div style={{ display: 'flex', flexDirection: 'column', width: '50%' }}>
              <div style={{ display: 'flex', flexDirection: 'row', marginTop: 30, marginBottom: 20 }}>
                <span>Баталгаат хугацаа:</span>
                <span style={{ color: 'red', marginLeft: 2 }}>*</span>
              </div>
              <input type="text" placeholder="хугацаа" value={warrantyPeriod} onChange={handleWarrantyPeriod}
                     style={{ width: '90%', height: 40, backgroundColor: "#F7F7F7", border: 0, paddingLeft: 15, borderRadius: 5 }}
              />
            </div>
            <div style={{ display: 'flex', flexDirection: 'column', width: '50%' }}>
              <div style={{ display: 'flex', flexDirection: 'row', marginTop: 30, marginBottom: 20 }}>
                <span>Баталгааны нөхцөл:</span>
                <span style={{ color: 'red', marginLeft: 2 }}>*</span>
              </div>
              <input type="text" placeholder="нөхцөл" value={warrantyTerms} onChange={handleWarrantyTerms}
                     style={{ width: '90%', height: 40, backgroundColor: "#F7F7F7", border: 0, paddingLeft: 15, borderRadius: 5 }}
              />
            </div>
          </div>
          <div style={{ width: '100%', display: 'flex', justifyContent: 'flex-start', marginTop: 70 }}>
            <div className="button-style">
              <div className="button-box" onClick={() => savePurchaseBtn()}>
                <span className="text">Хүсэлт илгээх</span>
              </div>
            </div>
          </div>
        </div>
        }
        {isSent &&
          <div style={{width: '100%', display: 'flex', justifyContent: 'flex-start', marginTop: 70}}>
            <div className="button-style">
              <div className="button-box" onClick={() => history.goBack()}>
                <span className="text">Буцах</span>
              </div>
            </div>
          </div>
        }


      </div>
    </React.Fragment>
  )
}
