import React from 'react'
import {motion} from 'framer-motion'
import {Fade} from 'react-awesome-reveal'
function TimelineCard({text, logo}) {
  return (
    <Fade fraction={.5} delay={100} cascade damping={1}>
    <div className='TimelineCard'>
      <div className='text'>{text}</div>
      <div className='logo'>
        {(logo&& logo.length>0) &&
        logo.map((item, index)=>(
          <div>
            <img src={item}/>
          </div>
        ))
        }
      </div>
    </div>
    </Fade>
  )
}

export default TimelineCard