import React from 'react'

function InvestmentCard({company, detail1, detail2, percent}) {
  return (
    <div className='investmentCard'>
        <div className='company'>{company}</div>    
        <div className='detail'>
            <div>{detail1}</div>
            <div>{detail2}</div>
        </div> 
        <div className='percent'>{percent}</div>
    </div>
  )
}

export default InvestmentCard