import './App.css';
import React, {PureComponent, useEffect, useState} from 'react';
import "antd/dist/antd.css";
import ReactDOM from "react-dom";
import './styles/styles.scss';
import './styles/responsive..scss';
import {Route, Switch, BrowserRouter as Router, useLocation} from "react-router-dom";
import { HomeContainer } from "./pages/Home";
import { TopBar } from "./component/TopBar";
import { BottomContainer } from "./component/BottomBar";
import { AboutContainer } from "./pages/About";
import { ConstrucContainer } from "./pages/Construction";
import { NewsContainer } from "./pages/News";
import { ChairContainer } from "./pages/ChairMan";
import { InvesContainer } from "./pages/Investment";
import { WorkPlaceContainer } from "./pages/WorkPlace";
import { PurchaseContainer } from "./pages/Purchase";
import { AnketContainer } from "./pages/WorkPlace";
import { RequestForm } from "./pages/WorkPlace";
import { NewsDetail } from "./pages/News";
import { PurchaseDetail } from "./pages/Purchase";
import { LoginContainer } from "./pages/Login";
import { PurchaseAnket } from "./pages/Purchase";
import {UserContext} from "./UserContext";
import useFindUser from "./useFindUser";
import ComingSoon from "./pages/Home/ComingSoon";

import Environment from './pages/Environment/Environment';
import { SideMenu } from './component/SideMenu/SideMenu';
import { useScreenWidth } from './customHooks/useScreenWidth';
import NewPage from './NewPage'
 

function App() { 
  const {width}= useScreenWidth()
  return (
    <Router>
    {/* <NewPage/> */}
      {/* <UserContext.Provider value={{user, setUser, isLoading}}> */}
      {/* <Route path="/" component={ComingSoon} exact={true} /> */}
        {width< 887 ? <SideMenu/>:<TopBar />}
        <Switch>
          <Route path="/" component={HomeContainer} exact={true} />
          <Route path="/about" component={AboutContainer} />
          <Route path="/construction" component={ConstrucContainer} />
          <Route path="/news" component={NewsContainer} />
          <Route exact={true} path="/news-detail/:id?" component={NewsDetail} />
          <Route path="/management" component={ChairContainer} />
          <Route path="/investment" component={InvesContainer} />
          <Route path="/work-place" component={WorkPlaceContainer} />
          <Route path="/purchase" component={PurchaseContainer} />
          <Route path="/purchase-detail/:id?" component={PurchaseDetail} />
          <Route path="/send-purchase/:id?" component={PurchaseAnket} />
          <Route exact={true} path="/request-form/:id?" component={AnketContainer} />
          <Route path="/environment" component={Environment} />
          <Route exact={true} path="/send-form/:id?" component={RequestForm} />
          <Route path="/login" component={LoginContainer} />
        </Switch>
        <BottomContainer/>
      {/* </UserContext.Provider> */}
    </Router>
  );
}

const rootElement = document.getElementById("root");
ReactDOM.render(<App />, rootElement);


export default App;
