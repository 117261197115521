import React from 'react'
import {Timeline} from 'antd'
import TimelineCard from './TimelineCard'

function TimelineComponent({data, margin}) {
    return (
    <div className='timelineContainer' style={{marginTop: `${margin? margin: "-2rem"}`, margin: "0 15vw", padding: "0 1rem"}}>
        <Timeline mode='alternate' style={{marginTop: "4rem"}}>
        {data.map((item, index)=>(
            <Timeline.Item color='#AF6C52' dot={<div style={{height: ".5rem", width: ".5rem", background: "#AF6C52", borderRadius: "50%"}}/>} label={item.date}>
                <TimelineCard key={index} {...item}/>
            </Timeline.Item>
        ))}            
        </Timeline>
    </div>
  )
}

export default TimelineComponent