import React, {useEffect, useState} from "react"
import {Modal} from "antd"
import BackImg from "../../asset/managementBanner.png"
import chairman1 from "../../asset/2.jpg"
import chairman2 from "../../asset/u6.jpg"
import chairman3 from "../../asset/u3.jpg"
import chairman4 from "../../asset/u4.jpg"
import chairman5 from "../../asset/u5.jpg"
import chairman6 from "../../asset/1.jpg"
import chairman7 from "../../asset/u7.jpg"
import chairman8 from "../../asset/t4.jpg"
import chairman9 from "../../asset/batbold.png"
import chairman10 from "../../asset/munkhtuya.png"
import chairman11 from "../../asset/4.jpg"
import chairman12 from "../../asset/3.jpg"
import nomin from "../../asset/nomin.jpg"
import baterdene from "../../asset/bat-erdene.jpg"
import ariunbold from "../../asset/ariunbold.jpg"
import jadamba from '../../asset/jadamba.jpg'
import zero from "../../asset/zero.png"
import {Link} from "react-router-dom"
import ArrowImg from "../../asset/arrow.png"
import HomeBanner from "../../asset/HomeBanner.jpg"
import HomeBanner4 from "../../asset/Home2.png"
import HomeBanner1 from "../../asset/HomeBanner1.jpg"
import NewsComponent from "../../component/News/NewsComponent"
import NewsComponentHome from "../../component/News/NewComponentHome"
import OrgComponent from "../Home/OrgComponent"
import bg from "../../asset/bg.jpg"
import {AiOutlinePlus} from "react-icons/ai"
import {Slide, Fade} from "react-awesome-reveal"
import ParallaxHeader from "../../component/ParallaxHeader"
const newsTitle = [
  {
    id: 1,
    text: "Төлөөлөн удирдах зөвлөл",
  },
  {
    id: 2,
    text: "Удирдлагын баг",
  },
]

const newsData = [
  {
    id: 1,
    img: HomeBanner,
    date: "2022.05.07",
    title: "Зэс эрдэнэ ХХК",
    text: "Эрдэнэт үйлдвэрийн олборолт ашиглалтын явцад бий болсон техно....",
  },
  {
    id: 2,
    img: HomeBanner4,
    date: "2022.05.07",
    title: "7 Хоногын онцлох",
    text: "Эрдэнэт үйлдвэрийн олборолт ашиглалтын явцад бий болсон техно....",
  },
  {
    id: 3,
    img: HomeBanner1,
    date: "2022.05.07",
    title: "Эрдэнэт үйлдвэрий...",
    text: "Эрдэнэт үйлдвэрийн олборолт ашиглалтын явцад бий болсон техно....",
  },
]

const chairManList1 = [
  {
    id: 7,
    img: chairman7,
    name: "Г.ГАЛСАННЯМ",
    title: "ТУЗ-ийн гишүүн",
    text: (
      <span>
        Ноён Г.Галсанням нь 2019 оноос “Зэс эрдэнийн хувь” ХХК-нд хувьцаа
        эзэмшигч, ТУЗ-ийн даргаар томилогдон ажиллаж байна.
        <br style={{marginBottom: ".25rem"}} />
        Булган аймгийн 1-р арван жилийн бүрэн дунд сургууль, МУИС-ийг бизнесийн
        менежмент, МУИС-ийг бизнесийн менежментийн магистр зэрэгтэй. Компанийн
        засаглалын удирдлага, худалдан авах ажиллагааны А3 мэргэжил дээшлүүлсэн.{" "}
        <br style={{marginBottom: ".25rem"}} />
        “Нюьсоник” ХХК-нд Гүйцэтгэх захирал, ТУЗ-ийн дарга, “Наран Групп” дэд
        захирал, “Наран Эло” ХХК-нд Гүйцэтгэх захиралаар тус тус ажиллаж байсан.
        <br style={{marginBottom: ".25rem"}} />
        Компанийн засаглал, бизнес модель хөгжүүлэлт, төслийн удирдлага болон
        үнэлгээ, бизнесийн удирдлага,тендерийн үнэлгээ хяналтын удирдлагаар
        мэргэшсэн.
      </span>
    ),
  },
  {
    id: 1,
    img: chairman1,
    name: "Э.БАТБАЯР",
    title: "ТУЗ-ийн гишүүн",
    text:  `
    Э.Батбаяр  нь 2021 оноос “Зэс эрдэнийн хувь” ХХК-нд Санхүү хөрөнгө оруулалт хариуцсан захирал, 2021 оны 05 дугаар сараас Зэс эрдэнийн хувь ХХК-ийн ТУЗ-ийн гишүүүнээр томилогдон ажиллаж байна.
 
Тэрээр “Эрдэнэс Тавантолгой” ХК төслийн удирдлагын газрын захирал, “Эрдэнэс Тавантолгой” ХК IPO, хөрөнгийн зах зээлийн газрын захирал, Монгол Улсын Гадаад хэргийн яам, АНУ дахь Монгол Улсын элчин сайдын яаманд Худалдаа эдийн засгийн консул, Монгол Улсын Сангийн яам Орлогын газрын дарга, Монгол улсын хөрөнгийн биржид Санхүү эрхэлсэн захирал, Монгол Улсын Сангийн яаманд Татварын мэргэжилтэн, Санхүү эдийн засгийн сургуульд Багшаар тус тус ажиллаж байсан.
          Санхүү эдийн засгийн сургуулийг Маркетингийн менежмент чиглэлээр баклавр, Keller Graduate School of Management DeVry University, Иллинойс муж бизнесийн удирдлага магистр, АНУ, HARVARD KENNEDY SCHOOL Cambridge, Массачусетс муж, АНУ  Executive Course – Гүйцэтгэх удирдлагын курсыг  тус тус дүүргэсэн.

Зэс эрдэнийн хувь ХХК-ийн хөрөнгө оруулалтын үйл ажиллагааг хариуцан ажилладаг.

    `,
  },
  {
    id: 6,
    img: chairman6,
    name: "Б.МӨНХБААТАР",
    title: "ТУЗ-ийн гишүүн",
    text: `
    Б.Мөнхбаатар нь 2019 оноос Зэс эрдэнийн хувь ХХК-нд Гүйцэтгэх захирал, 2022 оны 04 дүгээр сараас Зэс эрдэнийн хувь ХХК-ийн Гүйцэтгэх захирал, ТУЗ-ийн гишүүнээр томилогдон ажиллаж байна.
Тэрээр “КПМ” ХХК-нд Ерөнхий захирал, “Гложекс” ХХК-нд Гүйцэтгэх захирал, ТУЗ-ийн гишүүн, Хувьцаа эзэмшигч, “Гложекс Холдинг” ХХК-нд Хувьцаа эзэмшигч, ТУЗ-ийн гишүүнээр тус тус ажиллаж байсан.
Улаанбаатар хотын 45-р бүрэн дунд сургууль, МУТИС-ийг үйлдвэрийн автоматжуулалтын инженер, Сингапур улсын Genetic Computer School, Institute of Computer Aided Automation техникийн шинжлэх ухааны магистр хамгаалсан. Чех улсанд TELSA фирмийн өндөр үелзэлийн хяналтын системийн сургалт, Израйль Улсын Advanced network system of KP Electronics Systems LTD сургалт, АНУ –н Rajant Kinetic Mesh Network, Rajant Corporation Arizona тус тус мэргэжил дээшлүүлсэн.
Зэс эрдэнийн хувь ХХК-ийн өдөр тутмын үйл ажиллагааг удирдан зохион байгуулах, хяналт тавих, удирдлагаар хангах чиг үүрэгтэй ажилладаг.
`
  },
  {
    id: 2,
    img: chairman2,
    name: "Д.РАГЧААХҮҮ",
    title: "ТУЗ-ийн гишүүн",
    text: (
      <span>
        Ноён Д.Рагчаахүү 2022 оноос “Зэс эрдэнийн хувь” ХХК-ийн ТУЗ-ийн
        гишүүнээр томилогдон ажиллаж байна.
        <br style={{marginBottom: ".25rem"}} />
        Улаанбаатар хотын 47-р бүрэн дунд сургууль,ТИС-ийг авто инженерийн
        мэргэжлээр баклаврын зэрэг хамгаалсан.
        <br style={{marginBottom: ".25rem"}} />
        “SBM” ХХК-ийг үүсгэн байгуулагч, Ерөнхий захирал, “Маргад-Аривжах” ХХК
        үүсгэн байгуулагч, Ерөнхий захирал, “Доргио Интернэйшнл” ХХК үүсгэн
        байгуулагч, Ерөнхий захирал, “Хос Мөнгөн Зам” ХХК ТУЗ-ийн дарга,
        “Партнер” ООО ОХУ Ерөнхий захиралаар тус тус ажиллаж байсан.
      </span>
    ),
  },
  {
    id: 4,
    img: chairman4,
    name: "Ч.МӨНХЖАРГАЛ",
    title: "ТУЗ-ийн гишүүн",
    text: (
      <span>
        Ноён Ч.Мөнхжаргал нь 2022 онд “Зэс эрдэнийн хувь” ХХК-ийн ТУЗ-ийн
        гишүүнээр томилогдон ажиллаж байна.
        <br style={{marginBottom: ".25rem"}} />
        Баянхонгор аймгийн 1-р арван жилийн бүрэн дунд сургууль, Украйн улсын
        цэргийн дээд сургууль, Удирдлагын академийг төгссөн.
        <br style={{marginBottom: ".25rem"}} />
        “Ноён Гэри” ХХК-нд Гүйцэтгэх захирал, МХЕГ-т тамгын даргаар тус тус
        ажиллаж байсан.
      </span>
    ),
  },
  {
    id: 5,
    img: chairman5,
    name: "Н.ТҮВШИНБАЯР",
    title: "ТУЗ-ийн гишүүн",
    text: (
      <span>
        Ноён Н.Түвшинбаяр нь 2016 оноос одоог хүртэл “Зэс эрдэнийн хувь” ХХК-ийн
        ТУЗ-ийн гишүүнээр томилогдон ажиллаж байна.
        <br style={{marginBottom: ".25rem"}} />
        Улаанбаатар хотын 24-р бүрэн дунд сургууль, Цэцэээ гүн дээд сургуулийг
        маркетингийн менежер, Худалдаа үйлдвэрлэлийн дээд сургуульд бараа түүхий
        эд брокерын мэргэжлээр мэргэжил дээшлүүлсэн.
        <br style={{marginBottom: ".25rem"}} />
        “Дөлгөөн Ундраа” ХХК-нд борлуулалтын менежер, “Молор Маргад” ХХК
        Гүйцэтгэх захиралаар ажиллаж байна.
      </span>
    ),
  },
  {
    id: 8,
    img: chairman3,
    name: "Б.ЭНХТУЯА",
    title: "ТУЗ-ийн гишүүн",
    text: (
      <span>
        Хадагтай Б.Энхтуяа нь 2011 оноос “Зэс эрдэнийн хувь” ХХК-нд Хувьцаа
        эзэмшигчдийн ажлын албанд баг хариуцсан менежер, Ерөнхий менежерээр
        ажиллаж байгаад 2022 онд ТУЗ-ийн гишүүнээр томилогдон ажиллаж байна.{" "}
        <br style={{marginBottom: ".25rem"}} />
        Хөвсгөл аймгийн Бүрэнтогтох сумын 1-р арван жилийн бүрэн дунд сургууль,
        Хөвсгөл Тамир сургалтын төвийг зохион байгуулагч, Улаанбаатар Соёмбо
        академийг бизнесийн удирдлагаар төгссөн.
        <br style={{marginBottom: ".25rem"}} />
        Тамир зочид буудал, зоогийн газар зохион байгуулагч, Богд зоогийн газар,
        газар тариалан хүлэмжийн аж ахуй эрхэлж байсан.
      </span>
    ),
  },
]

const chairManList2 = [
  {
    id: 6,
    img: chairman6,
    name: "Б.МӨНХБААТАР",
    title: "Гүйцэтгэх захирал",
    text: `
    Б.Мөнхбаатар нь 2019 оноос Зэс эрдэнийн хувь ХХК-нд Гүйцэтгэх захирал, 2022 оны 04 дүгээр сараас Зэс эрдэнийн хувь ХХК-ийн Гүйцэтгэх захирал, ТУЗ-ийн гишүүнээр томилогдон ажиллаж байна.
Тэрээр “КПМ” ХХК-нд Ерөнхий захирал, “Гложекс” ХХК-нд Гүйцэтгэх захирал, ТУЗ-ийн гишүүн, Хувьцаа эзэмшигч, “Гложекс Холдинг” ХХК-нд Хувьцаа эзэмшигч, ТУЗ-ийн гишүүнээр тус тус ажиллаж байсан.
Улаанбаатар хотын 45-р бүрэн дунд сургууль, МУТИС-ийг үйлдвэрийн автоматжуулалтын инженер, Сингапур улсын Genetic Computer School, Institute of Computer Aided Automation техникийн шинжлэх ухааны магистр хамгаалсан. Чех улсанд TELSA фирмийн өндөр үелзэлийн хяналтын системийн сургалт, Израйль Улсын Advanced network system of KP Electronics Systems LTD сургалт, АНУ –н Rajant Kinetic Mesh Network, Rajant Corporation Arizona тус тус мэргэжил дээшлүүлсэн.
Зэс эрдэнийн хувь ХХК-ийн өдөр тутмын үйл ажиллагааг удирдан зохион байгуулах, хяналт тавих, удирдлагаар хангах чиг үүрэгтэй ажилладаг.

    `,
  },
  {
    id: 1,
    img: chairman1,
    name: "Э.БАТБАЯР",
    title: "Санхүү хөрөнгө оруулалт хариуцсан дэд захирал",
    text: `
    Э.Батбаяр  нь 2021 оноос “Зэс эрдэнийн хувь” ХХК-нд Санхүү хөрөнгө оруулалт хариуцсан захирал, 2021 оны 05 дугаар сараас Зэс эрдэнийн хувь ХХК-ийн ТУЗ-ийн гишүүүнээр томилогдон ажиллаж байна.
 
Тэрээр “Эрдэнэс Тавантолгой” ХК төслийн удирдлагын газрын захирал, “Эрдэнэс Тавантолгой” ХК IPO, хөрөнгийн зах зээлийн газрын захирал, Монгол Улсын Гадаад хэргийн яам, АНУ дахь Монгол Улсын элчин сайдын яаманд Худалдаа эдийн засгийн консул, Монгол Улсын Сангийн яам Орлогын газрын дарга, Монгол улсын хөрөнгийн биржид Санхүү эрхэлсэн захирал, Монгол Улсын Сангийн яаманд Татварын мэргэжилтэн, Санхүү эдийн засгийн сургуульд Багшаар тус тус ажиллаж байсан.
          Санхүү эдийн засгийн сургуулийг Маркетингийн менежмент чиглэлээр баклавр, Keller Graduate School of Management DeVry University, Иллинойс муж бизнесийн удирдлага магистр, АНУ, HARVARD KENNEDY SCHOOL Cambridge, Массачусетс муж, АНУ  Executive Course – Гүйцэтгэх удирдлагын курсыг  тус тус дүүргэсэн.

Зэс эрдэнийн хувь ХХК-ийн хөрөнгө оруулалтын үйл ажиллагааг хариуцан ажилладаг.

    `,
  },
  {
    img: ariunbold,
    name: "Ц. Ариунболд",
    title: `Үйлдвэр, технологийн газрын дарга, төслийн удирдагч`,
    text: `
    Ц.Ариунболд нь 2021 оноос “Зэс эрдэнийн хувь” ХХК-нд Үйлдвэрлэл технологийн газрын дарга, төслийн удирдагчаар томилогдон ажиллаж байна. 

Тэрээр Тенхуан ХХК алт боловсруулах үйлдвэрт лаборант, Голожекс ХХК-нд баяжуулагч инженер, хэлтсийн дарга,  MSLT ХХК-нд төслийн удирдагч,  Жи Си Би ХХК-нд баяжуулагч инженерээр ажиллаж байсан.
ШУТИС-ийг уул уурхайн инженер, ашигт малтмал баяжуулалтын технолги  чиглэлээр бакалавр зэрэгтэй магистарунт, Австрали улсын Curtin university The business of mining мэргэжлээр онлайн курс төгссөн.
METSIM програм дээр ажилладаг. Алт, зэс, мөнгө, цайр, нүүрсний баяжуулах болон бутлуурийн үйлдвэрийн төсөл болосвруулах, Ухаа худаг, Бороогоулд ХХК-иудын үйлдвэрийн зураг төсөл дээр ажиллаж байсан туршлагатай.
Зэс эрдэнийн хувь ХХК-ийн технологийн хэлтэс, цахилгааны хэлтэс, механикийн хэлтэс, уур дулаан уусгалтын хэсэг хариуцан өдөр тутмын удирдлагаар ханган ажиллаж байна .

    `,
  },
  {
    img: baterdene,
    name: "Т. Бат-Эрдэнэ",
    title: "Үйлдвэрлэл, технологийн хэлтсийн дарга",
    text: `
    Т.Бат-Эрдэнэ нь 2021 оноос “Зэс эрдэнийн хувь” ХХК-нд Үйлдвэрлэл Технологийн хэлтэсийн даргаар томилогдон ажиллаж байна.
Тэрээр ТИС-ийг баяжуулагч инженер мэргэжлээр бакалавр зэрэг хамгаалсан.

Эрмин ХХК гидрометаллургийн аргаар катодын зэс гарган авах үйлдвэрт ээлжийн мастер, технологийн оператор,  ээлж хариуцсан ахлах мастераар ажиллаж байсан.

Үйлдвэрийн хэвийн ажиллагааг хариуцан шууд удирдагаар хангах, гарч байгаа бүртээгдэхүүнд хяналт тавих чиг үүрэгтэй ажиллаж байна.
`,
  },
  {
    id: 2,
    img: chairman9,
    name: "П.БАТБОЛД",
    title: "ХАБЭАБО-ны хэлтсийн менежер",
    text: `
    П.Батболд нь 2021 оны 04 дүгээр сараас Зэс эрдэнийн хувь ХХК-ийн ХАБЭАБО-ны хэлтсийн менежерээр томилогдон  ажиллаж байна.
Тэрээр Цагдаагийн байгууллагад ажиллаж байгаад МАК ХХК-нд ХАБЭА ажилтан, Эрдэнэс таван толгой ХК-нд ХАБЭА ажилтанаар тус тус ажиллаж байсан.
Их Засаг их сургуулийг Хууль зүйн бакалаврын зэрэгтэй, Цагдаагийн академийн мэргэжил дээшлүүлэх сургалтанд хамрагдан мэргэжил дээшлүүлсэн.
Зэс эрдэнийн хувь ХХК-нд хөдөлмөрийн аюулгүй байдал эрүүл ахуйн тухай хууль болон бусад хууль тогтоомж эрх зүйн акт, стандарт, байгууллагын дүрэм журмын биелэлтэд хяналт тавих, шаардлага зөвлөмж хүргүүлэх, ажлын байрны үзлэг шалгалт хийж үл тохирлыг арилгах, үйлдвэрийн үйл ажиллагаанаа байгаль орчинд үзүүлэх хор нөлөөг бууруулах үндсэн чиг үүрэгтэй ажилладаг.
`,
  },{
    img: jadamba,
    name: 'Д. Жадамба',
    title: 'Дулаан, ус хангамжийн хэлтсийн менежер'
  },
  {
    id: 4,
    img: chairman10,
    name: "Ц.МӨНХТУЯА",
    title: "Гадаад худалдааны менежер",
    text:
      `Ц.Мөнхтуяа нь 2021 оны 01 дүгээр сараас Гадаад худалдааны менежерийн албан тушаалд томилогдон ажиллаж байна. 
      Тэрээр  М Си Эс Электроник ХХК-аас ажлын гараагаа эхэлж, Ай Эс Си Монголия ХХК-д системийн администратор, КРМ ХХК-д Технологи, судалгаа хэлтсийн даргаар ажиллаж байжээ.
      Ц.Мөнхтуяа нь ШУТИС-ийн Компьютер Техник Менежментийн Сургуулийг компьютерийн техник хангамжийн инженер мэргэжлээр бакалавр, магистрийн зэрэгтэй төгссөн.
      Гадаад худалдааны менежер нь гадаад худалдан авалтыг хариуцан ажиллана.
      `
  },
  {
    img: nomin,
    name: "Б. Номин",
    title: "Ерөнхий нягтлан бодогч",
    text: `Б.Номин нь 2021 оноос Зэс эрдэнийн хувь ХХК-ийн Санхүүгийн хэлтэст Ерөнхий нягтлан бодогчоор томилогдон ажиллаж байна.
    Тэрээр  Улаанбаатар төмөр зам ХНН-ийн Төв засварын үйлдвэрт тооцооны нягтлан бодогч, Ерөнхий нягтлан бодогчоор ажилласан. ХААИС-ийн Захиргааны албанд Дотоод аудитороор ажиллаж байгаад 2018 оноос хойш Хятадын Хэйлунгжяны их сургуульд Эдийн засгийн чиглэлээр докторантаар суралцсан. 
    Монгол улсын их сургуулийг Нягтлан бодогч мэргэжлээр бакалаврын зэрэгтэй, Монгол улсын их сургуулийн Бизнесийн сургуулийн нягтлан бодох бүртгэл, хяналт шалгалт чиглэлээр магистрын зэрэгтэй төгссөн. Мэргэшсэн нягтлан бодогчийн зэрэгтэй.
    Зэс эрдэнийн хувь ХХК-ийн Санхүүгийн хэлтэс нь нягтлан бодох бүртгэлийн үйл ажиллагааг хариуцан ажилладаг.
    `,
  },
  {
    id: 5,
    img: chairman11,
    name: "Г.ЛХАГВАЦЭЦЭГ",
    title: "Захиргааны менежер ТУЗ-ийн нарийн бичгийн дарга",
    text: `Г.Лхагвацэцэг нь 2019 оноос “Зэс эрдэнийн хувь” ХХК-нд захиргааны менежер, 2022 оны 04 дүгээр сараас ТУЗ-ийн нарийн бичгийн даргаар томилогдон ажиллаж байна.
      Тэрээр ХААИС-ийг Маркетингийн менежерээр бакалавр зэрэг хамгаалсан. ХУИС-ийг гадаад хэлний орчуулагчаар төгссөн.
      Түшиг группд захиргааны менежер, Наран Электроникс ХХК-д хүний нөөцийн менежерээр ажиллаж байсан.
      Зэс эрдэнийн хувь ХХК-ийн үйл ажиллагаанд дэмжлэг үзүүлэх, зохион байгуулах, хэрэгжүүлэх чиг үүрэгтэй ажиллаж байна.
      
      `,
  },

]

export default function ChairMan() {
  const [selectedData, setSelectedData] = useState(chairManList1[0])
  const [newsTitleId, setNewsTitleId] = useState(1)
  const [open, setOpen] = React.useState(false)
  const handleOpen = () => setOpen(true)
  const handleClose = () => setOpen(false)

  // useEffect(() => {
  //   window.scrollTo(0, 0)
  // }, [])

  const onChange = (data) => {
    setSelectedData(data)
    handleOpen()
  }

  return (
    <React.Fragment>
      <Modal
        onCancel={() => setOpen(false)}
        footer={null}
        open={open}
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          zIndex: 1,
        }}
      >
        <div className="management-modal">
          <img src={selectedData.img} className="img" />
          <div className="text-container">
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
              }}
            >
              <span style={{fontSize: "1rem", fontWeight: "bold"}}>
                {selectedData.name}
              </span>
            </div>
            <span
              style={{
                fontSize: 16,
                color: "#AF6C52",
                marginTop: 10,
                marginBottom: 10,
              }}
            >
              {selectedData.title}
            </span>
            <div style={{fontSize: 13, textAlign: "justify", lineHeight: 1.8}}>
              {selectedData.text}
            </div>
          </div>
        </div>
      </Modal>
      <div className="management-container">
        <div
          style={{backgroundImage: "url(" + BackImg + ")"}}
          className="management-background"
        ></div>
        <div
          className="home-news-title-container"
          style={{paddingTop: "3rem", width: "100%"}}
        >
          {newsTitle.map((el) => (
            <div
              key={el.id}
              className="title-style"
              style={{
                width: "35%",
                justifyContent: "center",
                textAlign: "center",
              }}
              onClick={() => {
                setNewsTitleId(el.id)
                console.log("fdkjslksdf")
              }}
            >
              <span className={`${newsTitleId === el.id ? "text2" : "text1"}`}>
                {el.text}
              </span>
              <div
                style={{with: "100%"}}
                className={`${
                  newsTitleId === el.id ? "line-style-active" : "line-style"
                }`}
              />
            </div>
          ))}
        </div>
        <div>
          {newsTitleId === 1 && (
            <div className="margin-style-real">
              <div className="chairman-grid-container">
                <Fade triggerOnce cascade direction="up" damping={0.00}>
                  {chairManList1.map((item, index) => (
                    <div className="grid-item" onClick={() => onChange(item)}>
                      <div className="grid-img">
                        <div className="plus-sign">
                          <span>
                            <AiOutlinePlus />
                          </span>
                        </div>
                        <img src={item.img} />
                      </div>
                      <div className="grid-texts">
                        <div>{item.name}</div>
                        <div>{item.title}</div>
                      </div>
                    </div>
                  ))}
                </Fade>
              </div>
            </div>
          )}
          {newsTitleId === 2 && (
            <div className="margin-style-real">
              <div className="chairman-grid-container">
                <Fade triggerOnce cascade direction="up" damping={0.05}>
                  {chairManList2.map((item, index) => (
                    <div
                      key={index}
                      className="grid-item"
                      onClick={() => onChange(item)}
                    >
                      <div className="grid-img">
                        <div className="plus-sign">
                          <span>
                            <AiOutlinePlus />
                          </span>
                        </div>
                        <img src={item.img} />
                      </div>
                      <div className="grid-texts">
                        <div>{item.name}</div>
                        <div>{item.title}</div>
                      </div>
                    </div>
                  ))}
                </Fade>
              </div>
            </div>
          )}
        </div>
      </div>
      <OrgComponent />
      <div>
        <NewsComponentHome />
      </div>
    </React.Fragment>
  )
}
