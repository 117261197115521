export const unitMeasurement = [
  { value: 'Ширхэг', label: 'Ширхэг' },
  { value: 'Багц', label: 'Багц' },
  { value: 'Кг', label: 'Кг' },
  { value: 'Тонн', label: 'Тонн' },
  { value: 'Метр', label: 'Метр' },
  { value: 'Литр', label: 'Литр' },
  { value: 'Хоног', label: 'Хоног' },
  { value: 'Сар', label: 'Сар' },
  { value: 'Хүн/Өдөр', label: 'Хүн/Өдөр' },
  { value: 'Тонн/Км', label: 'Тонн/Км' },
];

export const currencyType = [
  { value: 'Төгрөг', label: 'Төгрөг' },
  { value: 'Ам.доллар', label: 'Ам.доллар' },
  { value: 'Юань', label: 'Юань' },
  { value: 'Евро', label: 'Евро' },
  { value: 'Рубль', label: 'Рубль' },
  { value: 'Бусад', label: 'Бусад' },
]

export const choiceType = [
  { value: 'Тийм', label: 'Тийм' },
  { value: 'Үгүй', label: 'Үгүй' },
]

export const unitMeasurementTime = [
  { value: 'Хуанлийн хоног', label: 'Хуанлийн хоног' },
  { value: 'Ажлын хоног', label: 'Ажлын хоног' },
]
