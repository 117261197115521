import React from 'react'
import {useHistory} from 'react-router-dom'
function LatestNewsCard({createdAt, title, content, id, limit}) {
    const navigate= useHistory()
  return (
    <div className='latestNews' onClick={()=>navigate.push(`news-detail/${id}`)}>
        <div>{title.substring(0, limit)}{title?.length>limit && "..."}</div>
        <p>{createdAt}</p>
    </div>
  )
}

export default LatestNewsCard