import React, {useState} from 'react'
import {Bar} from '@ant-design/plots'
import _ from 'lodash'
import { useEffect } from 'react'



const data= [{label: "0-18 нас", type: "Эрэгтэй", value: 32,},
{label: "0-18 нас", type: 'Эмэгтэй', value: 27,},
{label: "18-25 нас", type: "Эрэгтэй", value: 238,},
{label: "18-25 нас", type: 'Эмэгтэй', value: 124,},
{label:  "25-50 нас",type: "Эрэгтэй", value: 6644,},
{label:  "25-50 нас", type: 'Эмэгтэй', value: 7741,},
{label: "50+ нас", type: "Эрэгтэй", value: 3889,},
{label: "50+ нас", type: 'Эмэгтэй', value: 5775,},]

function BarStatChart() {
    const [sum, setSum]= useState({})

    useEffect(()=>{
        let sumMale= _.sumBy(data, function(o){
            if (o.type==="Эрэгтэй"){
              return o.value
            } 
        }) 
        let sumFemale= _.sumBy(data, function(o){
            if (o.type==="Эмэгтэй"){
              return o.value
            } 
        }) 
        setSum(prev=>{
            return {
                ...prev, male: sumMale, female: sumFemale
            }
        })
    },[])

    const percentCal= (e)=>{
        if (e.type==="Эрэгтэй"){
            return e.value/sum.male*100
        } else if (e.type==="Эмэгтэй"){
            return e.value/sum.female*100
        }
    }
   
    const config= {
        data,
        isGroup: true,
        xField: 'value',
        yField: 'label',
        seriesField: 'type',
        label: {
            position: 'middle',
            layout: [
              {
                type: 'interval-adjust-position',
              },
              {
                type: 'interval-hide-overlap',
              },
              {
                type: 'adjust-color',
              },
            ],
          },
        tooltip: {
            formatter: (e)=>{
                return {name: e.type, value: `${e.value} -  ${percentCal(e).toFixed(2)}%`}
            }
          }
    }
  return (
    <div style={{width: "100%", }}>
        <Bar  {...config} />
    </div>
  )
}

export default BarStatChart