import request from "./lib/request";

function getWorkList() {
  return request(({
    url: `/resources/workdefinition`,
    method: 'GET'
  }))
}

function addCadidate(data) {
  return request(({
    url: `/resources/Candidate`,
    method: 'POST',
    data: data
  }))
}

function checkCandidate(data) {
  return request(({
    url: `/data/candidate/check`,
    method: 'POST',
    data: data
  }))
}

function addSupplier(data) {
  return request(({
    url: `/resources/supplier`,
    method: 'POST',
    data: data
  }))
}

function getWorkById(id) {
  return request(({
    url: `/data/getWorkDefinition/${id}`,
    method: 'GET'
  }))
}

function getNewsList(){
  return request(({
    url: `/newsData/getData`,
    method: 'GET'
  }))
}

function getNewsById(id){
  return request(({
    url: `/newsData/getNewsById?id=${id}`,
    method: 'GET'
  }))
}

function getPurchase(){
  return request(({
    url: `/resources/purchase`,
    method: 'GET'
  }))
}

function getPurchaseId(id) {
  return request(({
    url: `/data/getPurchase/${id}`,
    method: 'GET'
  }))
}

function savePurchase(data) {
  return request(({
    url: `/resources/purchasedetail`,
    method: 'POST',
    data: data
  }))
}

function getProfile() {
  return request(({
    url: `/resources/news`,
    method: 'GET'
  }))
}

function uploadFile(body) {
  return request(({
    url: `/fileServices/uploadFile`,
    method: 'POST',
    data: body,
  }))
}

function getSupplierById(id) {
  return request(({
    url: `/data/getSupplierById/${id}`,
    method: 'GET'
  }))
}

const WorkService = {
  getWorkList,
  addCadidate,
  getWorkById,
  getNewsList,
  getNewsById,
  addSupplier,
  getProfile,
  getPurchase,
  getPurchaseId,
  savePurchase,
  uploadFile,
  checkCandidate,
  getSupplierById
}

export default WorkService;
