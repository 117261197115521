import React, {useEffect} from "react";
import Clip1IMG from "../../asset/Clip1.png";
import Clip2IMG from "../../asset/Clip.png";
import map1 from '../../asset/map1.png'
import BuildingIMG from "../../asset/truck.jpg";
import OrgComponent from "../Home/OrgComponent";
import { Fade } from "react-awesome-reveal";
import ParallaxHeader from "../../component/ParallaxHeader";
import { Image } from "antd";
import TimelineComponent from "../../component/Timeline";
import ProbCard from "./ProbCard";
import {motion} from 'framer-motion'

//Timeline pics

import one from './assets/1.png'
import two from './assets/2.png'
import three from './assets/3.png'
import four from './assets/4.png'
import five from './assets/5.png'
import six from './assets/6.png'
import seven from './assets/7.png'
import eight from './assets/8.png'
import nine from './assets/9.png'
import ten from './assets/13.png'
import eleven from './assets/17.png'
import twelve from './assets/11.png'
import fourteen from './assets/12.png'
import fifteen from './assets/15.gif'
import sixteen from './assets/27.jpg'
import eighteen from './assets/20.png.jpg'
import orkhon from './assets/18.png'
import seventeen from './assets/19.png'
import nineteen from './assets/20.png'
import twenty from './assets/21.png.jpg'
import twentytwo from './assets/22.png.jpg'
import twentythree from './assets/23.png'
import twentyfour from './assets/24.png'
import twentysix from './assets/26.png.jpg'
import './style.scss';
import ConstructionCard from "./ConstructionCard";

export default function Construction() {
  const container = {
    hidden: { opacity: 0 },
    show: {
      opacity: 1,
      transition: {
        staggerChildren: 0.2
      }
    }
  }

const data= [
  {date: "2019.11", text: 'Үйлдвэр барихад шаардлагатай 106.8 га газрыг 15 жилийн хугацаатай эзэмшихээр авав. ', logo: [one]},
  {date: "2020.01", text: 'Үйлдвэрийн бүх зураг төслийг Барилга хөгжлийн төвөөр батлуулав.  ', logo: [two]},
  {date: "2020.02", text: 'Төсөлд шаардлагатай тоног төхөөрөмж үйлдвэрлэгч болон  ажил гүйцэтгэх байгууллагуудын судалгааг хийж, гэрээ байгуулав.  ', logo: [three]},
  {date: "2020.02  ", text: 'Орхон аймгаас барилгын ажил эхлүүлэх зөвшөөрөл авав', logo: [four]},
  {date: "2020.07  ", text: 'Төслийн 450,000м3 газар шорооны ажил дуусав.  ', logo: [five]},
  {date: "2020.07 ", text: 'Цахилгаан гадна шугам татах ажил эхлэв.' ,logo: [six]},
  {date: "2020.08", text: 'Усны нөөцийн судалгааг хийв.', logo: [seven]},
  {date: "2021.01.01", text: '“Зэс Эрдэнийн хувь” ХХК төслийн хөрөнгө оруулалтыг бүрэн шийдвэрлэв.', logo: [eight]},
  {date: "2021.01.25", text: 'ТЭЗҮ тодотолгол гэрээ байгуулав.', logo: [nine]},
  {date: "2021.01.26", text: 'Үйлдвэрийн технологийн тоног төхөөрөмж нийлүүлэх, угсрах, суурилуулах, тохируулах захиалга хийв.', logo: [ten]},
  {date: "2021.02.01", text: 'Үйлдвэрийн дотоод цахилгаан хангамж, автоматжуулалтын тоног төхөөрөмж нийлүүлэх, угсрах, суурилуулах, тохируулах захиалга хийв.', logo: [eleven]},
  {date: "2021.02.09", text: 'Үйлдвэрийн тоног төхөөрөмжийн угсралт суурилуулалтын багаж захиалга хийв.', logo: [twelve]},
  {date: "2021.02.10", text: 'Гадаад шугамын насосны захиалга хийгдэв. SX үйлдвэрийн тоног төхөөрөмжийн захиалга хийгдэв.', logo: [twelve]},
  {date: "2021.02.24", text: 'Гүүрэн кран худалдах, худалдан авах захиалга хийв.', logo: [fourteen]},
  {date: "2021.02.10", text: 'EW үйлдвэрийн Анодын угсралтын ажил хийгдэв.', logo: [ten]},
  {date: "2021.03.03", text: 'Геомембран, геотекстил 200 мян.м2 захиалга хийв.', logo: [twelve]},
  {date: "2021.03.17", text: 'Нерж материалаар хийгдэх танк, үйлдвэрийн дотоод шугам хоолой худалдан захиалга хийв.', logo: [fifteen]},
  {date: "2021.03.23", text: 'Үйлдвэрийн барилгын суурийн ажил эхлэв.', logo: [sixteen]},
  {date: "2021.04.20", text: 'Төслийн Нуруулдан уусгах байгууламжийн газар шорооны ажил эхэлсэн.', logo: [five]},
  {date: "2021.04.21", text: 'Үйлдвэрийн барилгуудын газар шорооны ажил эхэлсэн.', logo: [five]},
  {date: "2021.05.01", text: 'SX/EW үйлдвэрийн технологийн тоног төхөөрөмжийн үйлдвэрлэл эхэлсэн.', logo: [ten]},
  {date: "2021.05.08", text: 'Үйлдвэрийн дотоод цахилгаан автоматжуулалтын самбарын угсралт эхэлсэн.', logo: [eleven]},
  {date: "2021.05.09", text: 'Орхон аймгийн мэргэжлийн хяналтын газраас шалгалт хийсэн.', logo: [orkhon]},
  {date: "2021.05.27", text: 'Геомембран геотекстилийн эхний ээлжийн материал талбай дээр буусан. ', logo: [twelve]},
  {date: "2021.06.01", text: 'Хүдэр тээвэрлэх технологийн замын ажил эхэлсэн.', logo: [seventeen]},
  {date: "2021.06.15", text: 'Уурын зуухны газар шорооны ажил эхэлсэн.', logo: [eighteen]},
  {date: "2021.07.01", text: 'Хүхрийн хүчлийн агуулахын барилга, тоног төхөөрөмжийн ажил эхлэв.', logo: [nineteen]},
  {date: "2021.09.02", text: 'Хүдэр тээвэрлэх зөвшөөрөл авав.', logo: [twenty]},
  {date: "2021.10.20", text: 'Цахилгаан хангамжийн өргөтгөлийн ажил эхлэв.', logo: [twentytwo, twentythree]},
  {date: "2021.10.28", text: 'Хүдэр бэлтгэх ажил эхлэв.', logo: [twentyfour]},
  {date: "2021.12.12", text: 'EW үйлдвэрийн технологийн тоног төхөөрөмжийн үйлдвэрлэл эхэлсэн.', logo: [twentysix]},
  {date: "2022.03.23", text: 'Үйлдвэрийн барилга угсралтын ажил эхлэв.', logo: [sixteen]},
]

const cardData= [
  {src: Clip1IMG, color: "#406fbc", text: "НУРУУЛДАН УУСГАХ БАЙГУУЛАМЖ", shadow: ["Нуруулдан уусгах талбай", "6 төрлийн сан", "Материал", "Тоног төхөөрөмж"]},
  {src: Clip2IMG, color: "#fbc40a", text: "SX+EW ҮЙЛДВЭР", shadow: ["SX+EW үйлдвэрийн тоног төхөөрөмж", "Цахилгаан автоматжуулалт", "Химийн бодисын агуулах", "Лаборатори"]},
  {src: Clip1IMG, color: "#74ac44", text: "БАРИЛГА БАЙГУУЛАМЖ", shadow: ["Үндсэн 4 барилга", "Туслах 6 барилга", "Хөгжлийн төв", "Барилгуудын дэд бүтэц"]},
]
const cardData2=[
  {src: Clip2IMG,color: "#ec7c34", text: "ДЭД БҮТЭЦ", shadow: ["Цахилгаан хангамж", "Дулаан хангамж", "Технологийн зам", "Гадна талбайн тохижилт, ногоон байгууламж"]},
  {src: Clip1IMG,color: "#5c9bd3", text: "ЭРГЭЛТИЙН ХӨРӨНГӨ", shadow: ["Хүдэр бэлтгэл", "Химийн бодис", "Бусад",]},
  {src: Clip2IMG,color: "#547ccc", text: "БАРИМТ БИЧИГ", shadow: ["ТЭЗҮ, тусгай зөвшөөрөл", "Барилгын зураг, төсөл", "Технологийн хяналт, үнэлгээ", "БОННҮ"]},
]
  return(
    <React.Fragment>
      <ParallaxHeader src={BuildingIMG}/>
      <div className="construction-map margin-style-real">
      <div className="title">КАТОДЫН ЗЭС БОЛОВСРУУЛАХ ҮЙЛДВЭРИЙН 
          БҮТЭЭН БАЙГУУЛАЛТЫН АЖИЛ
          </div>
        <div className="container1">
        <Image  src={map1}/> </div>
      </div>
      <Fade fraction={0} delay={1} cascade triggerOnce direction="up" damping={0.05}>
        <div className="margin-style-real">
          <div className="constructor-cont-1">
          {cardData.map((item, index)=>(
            <ConstructionCard {...item} index={index} margin={index}/>
          ))}
          </div>
        </div>
      </Fade>

      <Fade fraction={0} delay={1} cascade triggerOnce direction="up" damping={0.05}>
        <div className="margin-style-real">
          <div className="constructor-cont-1">
          {cardData2.map((item, index)=>(
            <ConstructionCard {...item} index={index+3} margin={index}/>
          ))}
          </div>
        </div>
      </Fade>

      <div className="construction-map margin-style-real">
        <div className="title">БҮТЭЭН БАЙГУУЛАЛТЫН ТӨЛӨВЛӨГӨӨ</div>
        <div className="container1">
        <Image  src={map1}/> </div>
      </div>
      <div className="construction-map margin-style-real" style={{marginBottom: "5rem"}}>
        <div className="title">ТУЛГАРЧ БУЙ СОРИЛТУУД</div>
        <motion.div className="container2" 
         variants={container}
         initial="hidden"
         animate="show"
        >
            {[{title: "I", text: "Ковид-19 цар тахал, хөл хорио"},
            {title: "II", text: "Дэлхийн зах зээл дэх түүхий эдийн үнийн өсөлт"},
            {title: "III", text: "МУ-ын Тээвэр, ложистикийн хүндрэл"},
            {title: "Шинээр...", text: "ОУ-ын тээвэр ложистик, Түүхий эдийн нийлүүлэлт, үнийн өсөлт, Гадаад валютын урсгал, нөөц, ханш"},
            ].map((item, index)=>(
              <ProbCard key={index} {...item}/>
            ))}
        </motion.div>
      </div>
      <TimelineComponent data={data}/>
      <OrgComponent/>
    </React.Fragment>
  )
}
