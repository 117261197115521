import { Image } from 'antd';
import React from 'react'


function StructureChart(props) {

  return (
    <div className='margin-style-real' style={{marginTop: "2rem"}}>
    <Image visible={true} src={props.src}/>
</div>
  )
}

export default StructureChart