import * as React from "react";
import { motion } from "framer-motion";
import { MenuItem } from "./MenuItem";

const variants = {
  open: {
    transition: { staggerChildren: 0.07, delayChildren: 0.2 }
  },
  closed: {
    transition: { staggerChildren: 0.05, staggerDirection: -1 }
  }
};

export const Navigation = ({toggle, isOpen}) => (
  <motion.ul className={`motion-ul  ${!isOpen ? "open" : ""}`} variants={variants}>
    {itemIds.map((item, index) => (
      <MenuItem toggle={toggle} i={index} {...item} key={index} />
    ))}
  </motion.ul>
);

const itemIds = [
    {text: "Нүүр хуудас", to: "/"},
    {text: "Компанийн тухай", to: "/about"},
    {text: "Компанийн удирдлага", to: "/management"},
    {text: "Мэдээ, мэдээлэл", to: "/news"},
    {text: "Бүтээн байгуулалт", to: "/construction"},
    {text: "Ажлын байр", to: "/work-place"},
    {text: "Худалдан авалт", to: "/purchase"},
    {text: "Хувьцаа эзэмшигчид", to: "/investment"},
    {text: "Байгаль орчин", to: "/environment"},
];
