import React, {useEffect, useState} from "react";
import NewsComponent from "../../component/News/NewsComponent";
import handShakeImg from "../../asset/handshake.png";
import ideaImg from "../../asset/idea.png";
import visionImg from "../../asset/shared-vision.png";
import GroupImg from "../../asset/Picture2.png";
import BackImg from "../../asset/managementBanner.png";
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation } from "swiper";
import "swiper/css";
import "swiper/css/navigation";
import HistoryImg1 from "../../asset/10.png";
import HistoryImg2 from "../../asset/11.png";
import HomeBanner from "../../asset/HomeBanner.jpg";
import HomeBanner4 from "../../asset/Home2.png";
import bg from '../../asset/Picture13jpg.jpg'
import erdenet from '../../asset/logos/Image 224.png'
import city from '../../asset/logos/Image 25.png'
import china from '../../asset/logos/Image 18.png'
import bgrimm from '../../asset/logos/Image 17.png'
import uulUurhai from '../../asset/logos/Image 20.png'
import baigal from '../../asset/logos/Repeat Grid 1.png'
import uildver from '../../asset/logos/Image 22.png'
import mongolUs from '../../asset/logos/Image 27.png'
import ferrostaal from '../../asset/logos/Image 24.png'
import germany from '../../asset/logos/Image 23.png'
import bluemon from '../../asset/logos/Image 29.png'
import parl from '../../asset/logos/Image 32.png'
import zes from '../../asset/logos/Group 40857.png'
import law from '../../asset/logos/Repeat Grid 2.png'
import b80 from '../../asset/logos/Image 31.png'
import HomeBanner1 from "../../asset/HomeBanner1.jpg";
import OrgComponent from "../Home/OrgComponent";
import NewsComponentHome from "../../component/News/NewComponentHome";
import { Parallax, ParallaxProvider } from "react-scroll-parallax";
import ParallaxHeader from "../../component/ParallaxHeader";
import TimelineComponent from '../../component/Timeline'
import './about.scss'
import TabComponent from "../../component/TabComponent";
import StructureChart from "./StructureChart";

const newsTitle = [
  {
    id: 1,
    text: 'Компанийн түүх'
  },
  {
    id: 2,
    text: 'Компанийн бүтэц, зохион байгуулалт'
  }
]

const data= [
  {date: "2010.06.21", text: '"Зэс Эрдэнийн Хувь" ХХК нь Орхон аймгийн 22 мянган иргэний хувь нийлүүлсэн хөрөнгөөр үүсгэн байгуулагдсан.', logo: []},
  {date: "2013.05.02", text: '“Эрдэнэт” ТӨҮГ, Орхон аймагтай төсөл хэрэгжүүлэхээр хамтран ажиллах гэрээ байгуулсан.', logo: [uildver, city]},
  {date: "2014.08.03", text: '“Beijing Geological Research Institute of Mining and Metallurgy” (BGRIMM) төслийн ТЭЗҮ-г боловсруулсан.', logo: [china, bgrimm]},
  {date: "2014.09.05", text: '“Комплекс Инженеринг Сервис” ХХК-аар Инженер геологийн судалгааны ажил гүйцэтгэв.', logo: []},
  {date: "2015", text: 'ТЭЗҮ-г Уул уурхайн яамны дэргэдэх “Эрдэс баялагийн мэргэжлийн зөвлөл”-өөр батлуулсан.', logo: [uulUurhai]},
  {date: "2015", text: 'Байгаль орчны нөлөөллийн нарийвчилсан үнэлгээний тайланг БОАЖЯ-р батлуулсан.' ,logo: [baigal]},
  {date: "2015.10.30", text: '8а, 12-р овоолгыг 20 жил ашиглах тухай “Исэлдсэн хүдрийн овоолго ашиглах” №5/277-15 тоот гэрээг байгуулсан.', logo: [uildver]},
  {date: "2015", text: 'Ус ашиглах боломжит нөөцийн дүгнэлтийг БОАЖЯ-ны харьяа “Монгол Ус” ТӨҮГ-аас гаргуулсан. ', logo: [mongolUs]},
  {date: "2016", text: 'Германы “Ferrostaal групп”-ээр ТЭЗҮ-ийг шинэчлэн боловсруулсан.', logo: [germany, ferrostaal]},
  {date: "2016.04", text: 'Бльюмон Групп-ээс Хөрөнгө оруулалт авч компанийн 70% хувьцаа эзэмшүүлхээр гэрээ хийгдэн үйл ажиллагаа явуулж эхэлсэн.', logo: [bluemon]},
  {date: "", text: '“Эрдэнэт Үйлдвэр” ТӨҮГ-аас “Зэс Эрдэнийн Хувь” ХХК-нд №5/277-15 тоот гэрээг цуцлах албан мэдэгдэл ирсэн.', logo: [uildver]},
  {date: "2019.07.18", text: '“Зэс Эрдэнийн Хувь” ХХК-ийн удирдлагад өөрчлөлт орж мэргэжлийн хүмүүсээс бүрдсэн баг ажиллаж эхэлсэн.', logo: []},
  {date: "2019.10", text: 'Орхон аймгийн Засаг даргын газартай хамтарч ажиллахаар тохиролцов.', logo: []},
  {date: "2019.11.07", text: '“Зэс Эрдэнийн Хувь” ХХК анхан шатны шүүхэд ялалт байгуулсан.', logo: [parl]},
  {date: "2019.11", text: 'Үйлдвэрийн бүтээн байгуулалтын бэлтгэл ажил эхэлсэн.', logo: [zes]},
  {date: "2020.01.07", text: '“Зэс Эрдэнийн Хувь” ХХК Давж Заалдах Шатны Шүүхэд ялалт байгуулсан.', logo: [parl]},
  {date: "2020.01.31", text: '“Эрдэнэт Үйлдвэр” ТӨҮГ Улсын Дээд шүүхийн Иргэний хэргийн танхимд гомдол гаргасан.', logo: [law]},
  {date: "2020.04", text: 'Төслийн бүтээн байгуулалтын ажил эхлэв.', logo: [zes]},
  {date: "2020.07.31", text: '“Эрдэнэт Үйлдвэр” ТӨҮГ, “Зэс Эрдэнийн Хувь” ХХК “Эвлэрлийн гэрээ” байгуулан хамтран ажиллахаар болсон.', logo: [uildver, zes]},
  {date: "2020.08.13", text: '“Эрдэнэт Үйлдвэр” ТӨҮГ-аас Улсын Дээд Шүүхэд гаргасан гомдлоо татаж авах хүсэлтийг гаргасан.', logo: [uildver]},
  {date: "2020.01.23", text: '"Төслийн хөрөнгө оруулалтын асуудлыг шийдвэрлэв.', logo: [bluemon, b80]},
  {date: "2021.11.30", text: '“Эрдэнэт Үйлдвэр” ТӨҮГ, “Зэс Эрдэнийн Хувь” ХХК “Эвлэрлийн гэрээ”-нд нэмэлт өөрчлөлт оруулав.', logo: [uildver, zes]},
]

export default function About() {

  const [newsTitleId, setNewsTitleId] = useState(1);
  const items=[
    {label: "Компанийн түүх",
     key: "1",
     children: <TimelineComponent/>,
    },
    {label: "Компанийн бүтэц, зохион байгуулалт",
     key: "2",
     children: "",
    },
    ]

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])
  return (
    <ParallaxProvider>
    <ParallaxHeader  style={{filter: "brightness(80%)"}} src={bg}/>
      {/* <div style={{ backgroundImage: 'url('+bg+')', marginTop: 100 }}  className="management-background">
      </div> */}
      <div className="home-third-container margin-style-real">
        <span className="home-second-title-text2">ЗЭС ЭРДЭНИЙН ХУВЬ ХХК</span>
        <div className="text">
        <span>
          Зэс Эрдэнийн хувь ХХК нь гадаад худалдаа, гидрометаллургийн аргаар катодын зэс гарган авах үйл ажиллагаа эрхлэхээр 2010 оны 6-р сарын 21-ний өдөр үүсгэн байгуулагдсан бөгөөд Орхон аймгийн Иргэдийн төлөөлөгчдийн хурал, Эрдэнэт Үйлдвэр ХХК-тэй 2013 оны 5-р сарын 02-ны өдөр 3 талт хамтран ажиллах гэрээ байгуулж, Уулын баяжуулах Эрдэнэт үйлдвэр ТӨҮГ-ын 8а болон 12-р овоолгыг ашиглан гидрометаллургийн аргаар катодын цэвэр зэс боловсруулах үйлдвэрийн төсөл хэрэгжүүлэх нөхцөл бүрдсэн.
        </span>
          <span style={{ marginTop: 10 }}>
          Зэс эрдэнийн хувь ХХК нь Эрдэнэт үйлдвэр ТӨҮГ-ын технологийн бус исэлдсэн хүдэр болон холимог хүдрийн овоолгод түшиглэн жилд 10.0 мян.тн, 99.99%-ийн цэвэршилттэй катодын зэс үйлдвэрлэх хүчин чадалтай SX/EW үйлдвэрийг Орхон аймгийн Баян-Өндөр сумын Баянцагаан баг, Сархиа уулын зүүн урд энгэрт байгуулахаар, үйлдвэрийн бүтээн байгуулалтын ажлыг 2019 оноос эхлэн эрчимжүүлэн шат дараатайгаар гүйцэтгэж байна
        </span>
        </div>
      </div>


      <div style={{ marginBottom: "5rem", paddingTop: "2rem" }} className="margin-style-real">
        <div className="aboutUs-vision-container">
              <div className="aboutUs-vision-box-about">
                <div className="img-container">
                  <img src={ideaImg} className="icon-style" />
                </div>
                <div className="title-style">Эрхэм зорилго</div>
                <div className="text-style" style={{ textAlign: 'justify' }}>
                  Байгалийн баялагыг зохистой ашиглаж,
                  тэгш ашиг  хүртэх иргэдийн оролцоотой
                  жишиг бизнесийн загварыг бий болгоно.
                </div>
              </div>
              <div className="aboutUs-vision-box-about">
                <div className="img-container">
                  <img src={visionImg} className="icon-style" />
                </div>
                <div className="title-style">Алсын хараа</div>
                <div className="text-style" style={{  textAlign: 'justify' }}>
                  Эрдэс баялгаас эцсийн бүтээгдэхүүн
                  үйлдвэрлэнэ.
                </div>
              </div>
              <div className="aboutUs-vision-box-about">
                <div className="img-container">
                  <img src={handShakeImg} className="icon-style" />
                </div>
                <div className="title-style">Үнэт зүйл</div>
                <div className="text-style" style={{ display: 'flex', flexDirection: 'column' }}>
                <div>
                  <ol style={{listStyleType: "disc"}}>
                  <li><span>Хамтын ажиллагаа</span></li>
                  <li><span>Аюулгүй байдал</span></li>
                  <li><span>Ёс зүй, хариуцлага</span></li>
                  <li><span>Үр өгөөжтэй технологи</span></li>
                  <li><span>Байгаль орчин</span></li>
                </ol>
                </div>
                </div>
              </div>
        </div>
      </div>
      <div className="home-news-title-container margin-style-real">
        {newsTitle.map(el => (
          <div key={el.id} className="title-style" style={{width: "100%"}} onClick={() => setNewsTitleId(el.id)}>
            <span className={`${newsTitleId === el.id ? 'text2' : 'text1'}`} style={{display: "flex", justifyContent: "center"}}><span>{el.text}</span></span>
            <div  style={{width: "100%"}} className={`${newsTitleId === el.id ? 'line-style-active' : 'line-style'}`}/>
          </div>
        ))}
      </div>
      {/* <div className="margin-style-real">
        <TabComponent
        list={[
          {title: "Компанийн түүх", element: <TimelineComponent/>}, 
          {title: "Компанийн бүтэц, зохион байгуулалт", element: <StructureChart src={GroupImg}/>} ]} 
        setKey={setNewsTitleId}/>
      </div> */}

           

      {newsTitleId === 1 &&
        <TimelineComponent data={data}/>
      }
      {newsTitleId === 2 &&
        <StructureChart src={GroupImg}/>
      }
      <OrgComponent/>
      <div>
        <NewsComponentHome />
      </div>
       {/* </Parallax> */}
      </ParallaxProvider>
  )
}
