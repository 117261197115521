import moment from 'moment'
import React from 'react'
import {HiArrowLongRight} from 'react-icons/hi2'
import { useHistory } from 'react-router-dom'
import {useNavigate} from 'react-router-dom'


function NewsCard({createdAt, title, content, id, limit}) {
  const navigate= useHistory()

  return (
    <div className='newsCard' onClick={()=>navigate.push(`news-detail/${id}`)}>
        <div className='text'>
            <div>
            {title?.substring(0, limit)}{title?.length>limit && "..."}
            </div>
            <p>{moment(createdAt).format('YYYY.MM.D')}</p>
        </div>
        <div className='button'>
        <div>
                <div/><button>Дэлгэрэнгүй</button><HiArrowLongRight className='icon'/>
        </div></div>
    </div>
  )
}

export default NewsCard
