import React, {useEffect, useState} from "react"
import chairman1 from "../../asset/1.png"
import ArrowImg from "../../asset/arrow.png"
import bg from "../../asset/workplace.jpg"
import ideaImg from "../../asset/idea.png"
import visionImg from "../../asset/shared-vision.png"
import handShakeImg from "../../asset/handshake.png"
import {Link} from "react-router-dom"
import BannerImg from "../../asset/Home3.jpg"
import {Swiper, SwiperSlide} from "swiper/react"
import slashImg from "../../asset/Icon metro-quote@2x.png"
import WorkService from "../../services/workService"
import "swiper/css"
import "swiper/css/pagination"
import {Pagination} from "swiper"
import receiveImg from "../../asset/receive@2x.png"
import selectImg from "../../asset/select@2x.png"
import interViewImg from "../../asset/interview.png"
import speechImg from "../../asset/job-interview (1)@2x.png"
import preImg from "../../asset/presentation@2x.png"
import noteImg from "../../asset/notepad@2x.png"
import Paper from "@mui/material/Paper"
import Table from "@mui/material/Table"
import TableHead from "@mui/material/TableHead"
import TableRow from "@mui/material/TableRow"
import TableCell from "@mui/material/TableCell"
import TableBody from "@mui/material/TableBody"
import TableContainer from "@mui/material/TableContainer"
import {Modal} from "antd"
import moment from "moment"
import ParallaxHeader from "../../component/ParallaxHeader"
import worker1 from "../../asset/worker1.jpg"
import worker2 from "../../asset/worker2.jpg"
import worker3 from "../../asset/worker3.jpg"
import {useScreenWidth} from "../../customHooks/useScreenWidth"
import Paragraph from "antd/lib/typography/Paragraph"

const newsTitle = [
  {
    id: 1,
    text: "Бидний тухай",
  },
  {
    id: 2,
    text: "Хүний нөөцийн бодлого",
  },
  {
    id: 3,
    text: "Сонгон шалгаруулалтын процесс",
  },
  {
    id: 4,
    text: "Нээлттэй ажлын байр",
  },
]

const processData = [
  {
    id: 1,
    img: receiveImg,
    title: "Хүсэлт хүлээн авах",
    text: "Цахим хэлбэрээр, эсхүл компанийн байранд ажилд орох хүсэлтийг хүлээн авна.",
    width: 50,
    height: 50,
  },
  {
    id: 2,
    img: selectImg,
    title: "Урьдчилсан сонгон шалгаруулалт",
    text: "Ажлын байранд тавигдах шаардлагыг хангасан иргэдийг дараагийн шатны шалгаруулалтад оруулахаар сонгон, шалгаруулна. Урьдчилсан сонгон шалгаруулалтын явцад тусгай тестээр үнэлгээ хийж болно.",
    width: 42,
    height: 42,
  },
  {
    id: 3,
    img: speechImg,
    title: "Ярилцлага уулзалт",
    text: "Урьдчилсан сонгон шалгаруулалтад тэнцсэн ажил горилогчтой ажлын байрны онцлог, тухайн ажлын байрны шалгуурыг хангаж байгаа эсэхээс хамаарч 1-3 шатны ярилцлагыг зохион байгуулна.",
    width: 42,
    height: 42,
  },
  {
    id: 4,
    img: interViewImg,
    title: "Ярилцлагын шатны хариуг мэдэгдэх",
    text: "Ярилцлагын шатанд тэнцсэн ажил горилогчид хариуг мэдэгдэнэ. Энэ шатанд шалгарсан ажил горилогч шаардлагатай нэмэлт бичиг баримт, материалыг бүрдүүлнэ.",
    width: 50,
    height: 50,
  },
  {
    id: 5,
    img: preImg,
    title: "Сургалт дадлага шалгалт",
    text: "Ажил горилогчийн туршлагаас хамаарч тухайн ажил горилогчийг сургалт, дадлагын ажилд хамруулах, тодорхой даалгавар өгөх, шалгалт авах зэргээр тухайн ажлын байранд мэргэжлийн ур чадвараар тэнцэх эсэхийг шалгана.",
    width: 41,
    height: 46,
  },
  {
    id: 6,
    img: noteImg,
    title: "Эцсийн шалгаруулалтын хариуг мэдэгдэх",
    text: "Ажлын байранд амжилттай шалгарсан ажил горилогчид хариуг мэдэгдэнэ.",
    width: 42,
    height: 50,
  },
]

const commitList = [
  {
    id: 1,
    img: worker1,
    name: "Г. Сүнжидмаа",
    position: "Ахлах тогооч",
    text: (
      <span>
        Миний бие 1998 оноос хойш 25 дахь жилдээ олон хүмүүсийг ходоодоор нь
        баярлуулж байна. 2022 оны 11 дүгээр сард анкет бөглөөд “ Зэс эрдэнийн
        хувь” ХХК иас ажлын санал хүлээн аваад Монгол улсын мастер тогооч
        Наранжаргал эгчтэйгээ хамтарч энэ үйлдвэрийнхээ гал тогооны ажлыг 12
        дугаар сарын 1- ний өдрөөс эхлүүлж байлаа.
        <br />
        Үйлдвэрийн маань ажилчдын талархсан сэтгэлийн үг нь бид бүхнийг хөглөж
        урам зоригтойгоор ажиллах эрх хүчийг өгдөг. Манай гал тогооны хамт олны
        нэгэн хүсэл тэмүүллээр үйлдвэрийнхээ ажилчдад амттай, чанартай, эрүүл
        хоол хүнсээр үйлчлэхийг зорилгоо болгоод санаа нэгтэйгээр ажиллаж байна.{" "}
      </span>
    ),
  },
  {
    id: 2,
    img: worker2,
    name: "Б. Мэндбаяр",
    position: <span>Байгаль орчны ахлах мэргэжилтэн</span>,
    text: (
      <span>
        Манай компани нь исэлдсэн хүдрийг нуруулдан уусгалт,
        шүлтгүйжүүлэлт-уусган хандлалт, цахилгаан химийн аргаар боловсруулан
        катодын цэвэр зэс /99,999%/ үйлдвэрлэхдээ байгаль экологид хамгийн
        ээлтэй технологи буюу нуруулдан уусгалтын талбай байгуулж уусгалт
        хийдгээрээ Орхон аймаг төдийгүй Монгол улсад анхдагч эко үйлдвэрүүдийн
        нэг юм. Мөн Байгаль орчны бодлогодоо “Эко үйлдвэр-Ногоон ирээдүй”
        зорилтыг дэвшүүлэн хариуцлагатай уул уурхай, хүнд үйлдвэрлэлийн
        тогтвортой хөгжлийг байгаль орчны тэнцэлтэй уялдуулан хамгаалах,
        байгалийн нөөц баялгийг зүй зохистой ашиглах, нөхөн сэргээх, ард
        иргэдийн эрүүл аюулгүй орчинд ажиллаж амьдрах эрхийг хангахад чиглэсэн
        хөтөлбөрийг хэрэгжүүлэн ажиллаж байна.
        <br />
        Миний бие “Эко үйлдвэр-Ногоон ирээдүй” зорилтын нэгэн эд эс болж энэхүү
        үйлдвэрт ажиллан байгаль дэлхийгээ хайрлан хамгаалах их үйлсэд хувь
        нэмрээ оруулах болсондоо үнэхээр талархан ажилладаг.
      </span>
    ),
  },
  {
    id: 3,
    img: worker3,
    name: "Ө. Эрдэнэ-Очир",
    position: <span>Үйлчилгээний ахлах ажилтан</span>,
    text: (
      <span>
        Миний хувьд компанийхаа залуу үеийн төлөөлөл болон ажиллаж байгаадаа
        баяртай явдаг.Анх 2021 онд компанидаа ачигч оосорлогчоор ажилд орж
        байсан бол одоо үйлчилгээний ахлах ажилтанаар ажиллаж байна.Залуу үеээ
        дэмжиж сургаж чиглүүлж чаддаг компани, эрч хүч дүүрэн хамт олонтойгоо
        ажиллахад урам зоригтой талархалтай байдаг.
      </span>
    ),
  },
]

export default function WorkPlace() {
  const [newsTitleId, setNewsTitleId] = useState(1)
  const [selectedData, setSelectedData] = useState(null)
  const [selectedProcess, setSelectedProcess] = useState()
  const [selectedCommit, setSelectedCommit] = useState(null)
  const [open, setOpen] = React.useState(false)
  const [toggle, setToggle] = useState(false)
  const [tableData, setTableData] = useState([])
  useEffect(() => {
    getData()
    // window.scrollTo(0, 0)
  }, [])
  const {width} = useScreenWidth()

  const getData = async () => {
    const result = await WorkService.getWorkList()
    setTableData(result)
  }

  const clickChange = (data) => {
    console.log("data: ", data)
    setSelectedData(data)
  }

  const onChangeCommit = (data) => {
    setSelectedCommit(data)
    handleOpen()
  }

  const handleOpen = () => setOpen(true)
  const handleClose = () => setOpen(false)

  const openToggle = () => setToggle(true)

  const handleToggle = (e) => {
    setSelectedProcess(e)
    openToggle()
  }
  return (
    <div>
      <Modal
        open={open}
        footer={null}
        onCancel={() => setOpen(false)}
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          zIndex: 1,
        }}
      >
        {selectedCommit && (
          <div className="management-modal">
            <img src={selectedCommit.img} className="img" />
            <div className="text-container">
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-between",
                }}
              >
                <span style={{fontSize: "1.2rem", fontWeight: "bold"}}>
                  {selectedCommit.name}
                </span>
              </div>
              <span
                style={{
                  fontSize: "1rem",
                  color: "#AF6C52",
                  marginTop: 10,
                  marginBottom: 10,
                }}
              >
                {selectedCommit.position}
              </span>
              <div style={{fontSize: "1rem", textAlign: "justify"}}>
                {selectedCommit.text}
              </div>
            </div>
          </div>
        )}
      </Modal>

      <Modal
        open={toggle}
        footer={null}
        onCancel={() => setToggle(false)}
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          zIndex: 1,
        }}
      >
        {selectedProcess && (
          <div className="process-modal">
            <img src={selectedProcess.img} className="img" />
            <div className="text-container" style={{width: "100%"}}>
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-between",
                }}
              >
                <span style={{fontSize: 18, fontWeight: "bold"}}>
                  {selectedProcess.title}
                </span>
              </div>
              <div style={{fontSize: 14, textAlign: "justify"}}>
                {selectedProcess.text}
              </div>
            </div>
          </div>
        )}
      </Modal>

      <ParallaxHeader src={bg} />
      <div
        className="home-news-title-container workplace margin-style-real"
        style={{marginTop: 50}}
      >
        {newsTitle.map((el) => (
          <div
            key={el.id}
            className="title-style"
            style={{width: "100%"}}
            onClick={() => setNewsTitleId(el.id)}
          >
            <span
              className={`${newsTitleId === el.id ? "text2" : "text1"}`}
              style={{display: "flex", justifyContent: "center"}}
            >
              <span>{el.text}</span>
            </span>
            <div
              style={{width: "100%"}}
              className={`${
                newsTitleId === el.id ? "line-style-active" : "line-style"
              }`}
            />
          </div>
        ))}
      </div>

      {newsTitleId === 1 && (
        <div className="margin-style-real">
          <div className="aboutUs-vision-container">
            <div className="aboutUs-vision-box-about">
              <div className="img-container">
                <img src={ideaImg} className="icon-style" />
              </div>
              <div className="title-style">Эрхэм зорилго</div>
              <div className="text-style" style={{textAlign: "justify"}}>
                Байгалийн баялагыг зохистой ашиглаж, тэгш ашиг хүртэх иргэдийн
                оролцоотой жишиг бизнесийн загварыг бий болгоно.
              </div>
            </div>

            <div className="aboutUs-vision-box-about" style={{height: "auto"}}>
              <div className="img-container">
                <img src={visionImg} className="icon-style" />
              </div>
              <div className="title-style">Алсын хараа</div>
              <div className="text-style" style={{textAlign: "justify"}}>
                Эрдэс баялгаас эцсийн бүтээгдэхүүн үйлдвэрлэнэ.
              </div>
            </div>

            <div className="aboutUs-vision-box-about">
              <div className="img-container">
                <img src={handShakeImg} className="icon-style" />
              </div>
              <div className="title-style">Үнэт зүйл</div>
              <div
                className="text-style"
                style={{
                  display: "flex",
                  flexDirection: "column",
                  gap: ".25rem",
                }}
              >
                <div>
                  <ol style={{listStyleType: "circle"}}>
                    <li>
                      <span>Хамтын ажиллагаа</span>
                    </li>
                    <li>
                      <span>Аюулгүй байдал</span>
                    </li>
                    <li>
                      <span>Ёс зүй, хариуцлага</span>
                    </li>
                    <li>
                      <span>Үр өгөөжтэй технологи</span>
                    </li>
                    <li>
                      <span>Байгаль орчин</span>
                    </li>
                  </ol>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}

      {newsTitleId === 1 && (
        <div className="margin-style-real">
          <Link
            to="/about"
            style={{
              display: "flex",
              justifyContent: "flex-end",
              alignItems: "center",
              marginTop: 60,
              color: "#000000",
              textDecoration: "none",
            }}
          >
            <div
              style={{
                backgroundColor: "#AF6C52",
                width: 50,
                height: 50,
                borderRadius: 35,
              }}
            />
            <div style={{fontSize: 16, fontWeight: "bold", marginLeft: -30}}>
              Дэлгэрэнгүй
            </div>
            <img
              src={ArrowImg}
              style={{width: 25, height: 14, marginLeft: 10, marginTop: 5}}
            />
          </Link>
        </div>
      )}

      {newsTitleId === 2 && (
        <div className="margin-style-real">
          <div className="workPlace-text-content" style={{fontSize: ".875rem"}}>
            <span>
              2010 оны 6-р сарын 21-ний өдөр үүсгэн байгуулагдсан бөгөөд Орхон
              аймгийн Иргэдийн төлөөлөгчдийн хурал, Эрдэнэт Үйлдвэр ХХК-тэй 2013
              оны 5-р сарын 02-ны өдөр 3 талт хамтран ажиллах гэрээ байгуулж,
              Уулын баяжуулах Эрдэнэт үйлдвэр ТӨҮГ-ын 8а болон 12-р овоолгыг
              ашиглан гидрометаллургийн аргаар катодын цэвэр зэс боловсруулах
              үйлдвэрийн төсөл хэрэгжүүлэх нөхцөл бүрдсэн.
            </span>
            <span>
              Зэс эрдэнийн хувь ХХК нь Эрдэнэт үйлдвэр ТӨҮГ-ын технологийн бус
              исэлдсэн хүдэр болон холимог хүдрийн овоолгод түшиглэн жилд 10.0
              мян.тн, 99.99%-ийн цэвэршилттэй катодын зэс үйлдвэрлэх хүчин
              чадалтай SX/EW үйлдвэрийг Орхон аймгийн Баян-Өндөр сумын
              Баянцагаан баг, Сархиа уулын зүүн урд энгэрт байгуулахаар,
              үйлдвэрийн бүтээн байгуулалтын ажлыг 2019 оноос эхлэн эрчимжүүлэн
              шат дараатайгаар гүйцэтгэж байна2010 оны 6-р сарын 21-ний өдөр
              үүсгэн байгуулагдсан бөгөөд Орхон аймгийн
            </span>
          </div>
        </div>
      )}

      {newsTitleId === 3 && (
        <>
          <div className="margin-style-real">
            Зэс эрдэнийн хувь ХХК нь худалдан авалтыг тендер болон хялбаршуулсан
            сонгон шалгаруулалтын зарчмаар ханган нийлүүлэгчийн сонгон
            шалгаруулалтыг хийдэг ба дараах үе шатны дагуу явуулна.
          </div>
          <div
            className="workers-choice-list one margin-style-real"
            style={{gap: "1.2rem"}}
          >
            {processData.map((el, index) => (
              <div
                key={el.id}
                className="aboutUs-vision-box-workplace"
                onClick={() => {
                  width < 887 && handleToggle(el)
                }}
                style={{
                  alignItems: "center",
                  height: "auto",
                  cursor: "pointer",
                }}
              >
                <div className="color-box" style={{width: 75, height: 70}}>
                  <img src={el.img} style={{width: 40, height: 40}} />
                </div>
                <div
                  className="title-style"
                  style={{textAlign: "center", fontSize: ".875rem"}}
                >
                  {index + 1}
                  {". "}
                  {el.title}
                </div>
                {width > 887 && (
                  <div className="text-style">
                    <span>{el.text}</span>
                  </div>
                )}
              </div>
            ))}
          </div>
          <div className="margin-style-real" style={{marginTop: "2rem"}}>
            Ажилд орох хүсэлт гаргасан иргэн бүрт адил, тэгш боломж олгон,
            ажилтан сонгон шалгаруулах үйл ажиллагааг шударга, ил тод явуулдаг,
            ажил горилогч ажлын байранд тавигдах шаардлагыг хангасан тохиолдолд
            дараагийн шатны шалгаруулалтад оруулах бөгөөд ажилтан сонгон
            шалгаруулах эцсийн шийдвэрийг шалгаруулалтын баг ямар нэгэн зуучлагч
            хувь хүний оролцоогүйгээр гаргадаг болно.
          </div>
        </>
      )}

      {newsTitleId === 4 && (
        <div className="margin-style-real">
          <div className="purchase-button-container">
            <a
              href="http://anket.zes-erdene.mn/"
              className="btn-style"
              style={{textOverflow: "ellipsis", textDecoration: "none"}}
            >
              <span style={{color: "#FFFFFF", fontSize: 18, fontWeight: 500}}>
                Нээлттэй ажлын байр
              </span>
            </a>
          </div>
        </div>
      )}

      <div
        style={{
          display: "flex",
          justifyContent: "center",
          marginTop: 157,
          marginBottom: "3rem",
        }}
      >
        <span className="container-title-style-main">АЖИЛЧДЫН СЭТГЭГДЭЛ</span>
      </div>

      <div
        className="margin-style-real workers-container"
        style={{marginBottom: "5rem"}}
      >
        {commitList.map((element) => (
          <div
            className="workers-commend"
            key={element.id}
            onClick={() => onChangeCommit(element)}
          >
            <div className="container1">
              <img src={slashImg} className="slash-img" />
              <div className="text">
                <Paragraph ellipsis={{rows: 5}}>{element.text}</Paragraph>
              </div>
            </div>
            <div className="container2">
              <img src={element.img} className="img-style" />
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  marginLeft: 15,
                }}
              >
                <div className="name-style">{element.name}</div>
                <div className="position-style">{element.position}</div>
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  )
}
