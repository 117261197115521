import React, {useEffect, useState} from "react";
import LoginBanner from "../../asset/LoginBanner.png";
import UserImg from "../../asset/user@2x.png";
import SecretImg from "../../asset/padlock@2x.png";
import { Modal } from "antd";
import Select from "react-select";
import {mainType, Country, workDirectionData} from "./staticData";
import {toast, ToastContainer} from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import WorkService from "../../services/workService";

export default function Login() {
    const [windowDimenion, detectHW] = useState({
        winWidth: window.innerWidth,
        winHeight: window.innerHeight,
    })
    const [visible1, setVisible1] = useState(false);
    const [visible2, setVisible2] = useState(false);
    const [isOpen, setIsOpen] = useState(false);
    const [checkedPerson, setCheckedPerson] = useState(true);
    const [checkedCompany, setCheckedCompany] = useState(false);
    const [foriegnInvestTrue, setForiegnInvestTrue] = useState(true);
    const [foriegnInvestFalse, setForiegnInvestFalse] = useState(false);
    const [modalPosition, setModalPosition] = useState('');
    const [mainName, setMainName] = useState('');
    const [firstName, setFirstName] = useState('');
    const [lastName, setLastName] = useState('');
    const [compType, setCompType] = useState(mainType[0]);
    const [regNo, setRegNo] = useState('');
    const [dirWork, setDirWork] = useState('');
    const [chairManNumber, setChairManNumber] = useState('');
    const [employeNumber, setEmployeNumber] = useState('');
    const [address, setAddress] = useState('');
    const [phone1, setPhone1] = useState('');
    const [phone2, setPhone2] = useState('');
    const [email, setEmail] = useState('');
    const [facebook, setFacebook] = useState('');
    const [instagram, setInstagram] = useState('');
    const [twitter, setTwitter] = useState('');
    const [linked, setLinked] = useState('');
    const [workDir, setWorkDir] = useState();
    const [dirCompany, setDirCompany] = useState('');
    const [dirSeller, setDirSeller] = useState('');
    const [dirRep, setDirRep] = useState('');
    const [dirDespor, setDirDespor] = useState('');
    const [workDirectionList, setWorkDirectionList] = useState([]);
    const [subWorkerList, setSubWorkerList] = useState([]);
    const [subFirstName, setSubFirstName] = useState('');
    const [subLastName, setSubLastName] = useState('');
    const [subPosition, setSubPosition] = useState('');
    const [subPhone, setSubPhone] = useState('');
    const [subEmail, setSubEmail] = useState('');

    const detectSize = () => {
        detectHW({
            winWidth: window.innerWidth,
            winHeight: window.innerHeight,
        });
    }

    useEffect(() => {
        window.addEventListener('resize', detectSize)

        return () => {
            window.removeEventListener('resize', detectSize)
        }
    }, [windowDimenion])

    const handleClose = () => setIsOpen(false);
    const handleClose1 = () => setVisible1(false);
    const handleClose2 = () => setVisible2(false);

    const changeTrue = () => {
        if (!foriegnInvestTrue) {
            setForiegnInvestTrue(true);
            setForiegnInvestFalse(false);
        }
    };

    const changeFalse = () => {
        if (!foriegnInvestFalse) {
            setForiegnInvestTrue(false);
            setForiegnInvestFalse(true);
        }
    };

    const changePerson = () => {
        if (!checkedPerson) {
            setCheckedPerson(true);
            setCheckedCompany(false);
        }
    };

    const changeCompany = () => {
        if (!checkedCompany) {
            setCheckedPerson(false);
            setCheckedCompany(true);
        }
    };

    useEffect(() => {
        detectSize();
    }, []);

    const saveData = async () => {
        if (checkedCompany) {
            if (mainName === '') return toast("Хуулийн этгээдийн нэр хоосон байна", {type: 'warning'});
            if (!modalPosition.value) return toast("Харъяалал сонгоогүй байна", {type: 'warning'});
            if (regNo === '') return toast("Регистрийн дугаар хоосон байна", {type: 'warning'});
            if (dirWork === '') return toast("Үйл ажиллагааны чиглэл хоосон байна", {type: 'warning'});
            if (chairManNumber === '') return toast("Хувьцаа эзэмшигчийн тоо хоосон байна", {type: 'warning'});
            if (employeNumber === '') return toast("Ажилчдын тоо хоосон байна", {type: 'warning'});
            if (address === '') return toast("Хаяг хоосон байна", {type: 'warning'});
            if (phone1 === '') return toast("Утасны дугаар хоосон байна", {type: 'warning'});
            if (email === '') return toast("И-мэйл хаяг хоосон байна", {type: 'warning'});
            if (facebook === '') return toast("Facebook хоосон байна", {type: 'warning'});
            if (twitter === '') return toast("Twitter хоосон байна", {type: 'warning'});
            if (instagram === '') return toast("Instagram хоосон байна", {type: 'warning'});
            if (linked === '') return toast("Linkedin хоосон байна", {type: 'warning'});
            const org = {
                ent_type: 'Байгууллага',
                ent_option: compType.value,
                foreign_investment: foriegnInvestTrue ? 1 : 0,
                first_name: mainName,
                country: modalPosition.value,
                main_activity: dirWork,
                shareholders_count: chairManNumber,
                worker_count: employeNumber,
                regno: regNo,
                address: address,
                phone1: phone1,
                phone2: phone2,
                email: email,
                website: '',
                social: [
                    {name: 'facebook', value: facebook},
                    {name: 'twitter', value: twitter},
                    {name: 'instagram', value: instagram},
                    {name: 'linkedin', value: linked}
                ],
                activity: [
                    {name: 'Гадаад худалдаа', value: '1'},
                    {name: 'Гадаад худалдаа', value: '2'},
                    {name: 'Гадаад худалдаа', value: '3'}
                ],
                supply_brandName: workDirectionList,
                agent_info: subWorkerList,
                can_supply: [
                    {name: ''},
                    {name: ''},
                    {name: ''},
                    {name: ''},
                ]
            };

            const result = await WorkService.addSupplier(org);
            if (result.success) {
                setIsOpen(false);
                return toast("Амжилттай бүртгэллээ", {type: 'success'});
            }
        } else {
            if (mainName === '') return toast("Ургийн овог хоосон байна", {type: 'warning'});
            if (firstName === '') return toast("Эцэг эхийн нэр хоосон байна", {type: 'warning'});
            if (lastName === '') return toast("Нэр хоосон байна", {type: 'warning'});
            if (regNo === '') return toast("Регистрийн дугаар хоосон байна", {type: 'warning'});
            if (address === '') return toast("Хаяг хоосон байна", {type: 'warning'});
            if (phone1 === '') return toast("Утасны дугаар хоосон байна", {type: 'warning'});
            if (email === '') return toast("И-мэйл хаяг хоосон байна", {type: 'warning'});
            if (facebook === '') return toast("Facebook хоосон байна", {type: 'warning'});
            if (twitter === '') return toast("Twitter хоосон байна", {type: 'warning'});
            if (instagram === '') return toast("Instagram хоосон байна", {type: 'warning'});
            if (linked === '') return toast("Linkedin хоосон байна", {type: 'warning'});
            const ind = {
                ent_type: 'Иргэн',
                family_name: mainName,
                first_name: firstName,
                last_name: lastName,
                country: modalPosition,
                regno: regNo,
                address: address,
                phone1: phone1,
                phone2: phone2,
                email: email,
                website: '/',
                social: [
                    {name: 'facebook', value: facebook},
                    {name: 'twitter', value: twitter},
                    {name: 'instagram', value: instagram},
                    {name: 'linkedin', value: linked}
                ],
                activity: [],
                supply_brandName: workDirectionList,
                agent_info: subWorkerList,
                can_supply: [],
            };
            const result = await WorkService.addSupplier(ind);
            if (result.success) {
                setIsOpen(false);
                return toast("Амжилттай бүртгэллээ", {type: 'success'});
            }
        }
    }

    const addDirWork = () => {
        if (dirCompany === '') return toast("Үйлдвэрлэгч хоосон байна", {type: 'warning'});
        if (dirSeller === '') return toast("Барааны нэр хоосон байна", {type: 'warning'});
        if (dirRep === '') return toast("Жил хоосон байна", {type: 'warning'});
        let list = [...workDirectionList];
        let data = {
            produceType: dirCompany,
            name: dirSeller,
            year: dirRep,
        }
        list.push(data);
        setDirCompany('');
        setDirSeller('');
        setDirRep('');
        setWorkDirectionList(list);
        handleClose1();
    }

    const addInvestors = () => {
        if (subFirstName === '') return toast("Нэр хоосон байна", {type: 'warning'});
        if (subLastName === '') return toast("Овог хоосон нэр", {type: 'warning'});
        if (subPosition === '') return toast("Ажлын байр хоосон нэр", {type: 'warning'});
        if (subPhone === '') return toast("Утас хоосон нэр", {type: 'warning'});
        if (subEmail === '') return toast("И-мэйл хоосон нэр", {type: 'warning'});
        let list = [...subWorkerList];
        let data = {
            firstName: subFirstName,
            lastName: subLastName,
            position: subPosition,
            phone: subPhone,
            phone2: subPhone,
            email: subEmail
        }
        list.push(data);
        setSubWorkerList(list);
        setSubFirstName('');
        setSubLastName('');
        setSubPosition('');
        setSubPhone('');
        setSubEmail('');
        handleClose2();
    }

    const dirWorkItem = (data) => {
        let list = [...workDirectionList];
        const index = list.findIndex(element => element.name === data.name && element.direction === data.direction &&
            element.seller === data.seller && element.repo === data.repo && element.deso === data.deso);
        list.splice(index, 1);
        setWorkDirectionList(list);
    }

    const subWorkItem = (data) => {
        let list = [...subWorkerList];
        const index = list.findIndex(element => element.firstName === data.firstName && element.lastName === data.lastName);
        list.splice(index, 1);
        setSubWorkerList(list);
    }

    return (
        <React.Fragment>
            <Modal
                open={visible2}
                footer={null}
                onCancel={()=>setVisible2(false)}
                className="form-modal"
            >
                <div className="login-sub-modal">
                    <div style={{
                        width: '100%',
                        display: 'flex',
                        justifyContent: 'flex-end',
                        marginTop: 30,
                        cursor: "pointer"
                    }}>
                    </div>
                    <input placeholder='Овог' className="input-style"
                           value={subLastName} onChange={(event) => setSubLastName(event.target.value)}/>
                    <input placeholder='Нэр' className="input-style"
                           value={subFirstName} onChange={(event) => setSubFirstName(event.target.value)}/>
                    <input placeholder='Албан тушаал' className="input-style"
                           value={subPosition} onChange={(event) => setSubPosition(event.target.value)}/>
                    <input placeholder='Утас' className="input-style" type="number"
                           value={subPhone} onChange={(event) => setSubPhone(event.target.value)}/>
                    <input placeholder='И-мэйл хаяг' className="input-style"
                           value={subEmail} onChange={(event) => setSubEmail(event.target.value)}/>
                    <div className="btn" onClick={() => addInvestors()}>
                        Нэмэх
                    </div>
                </div>
            </Modal>
            <Modal
                open={visible1}
                onCancel={()=>setVisible1(false)}
                footer={null}
                className="form-modal"
            >
                <div className="login-sub-modal">
                    <div style={{
                        width: '100%',
                        display: 'flex',
                        justifyContent: 'flex-end',
                        marginTop: 30,
                        cursor: "pointer"
                    }}>
                    </div>
                    <input placeholder='Үйлдвэрлэгч' className="input-style"
                           value={dirCompany} onChange={(event) => setDirCompany(event.target.value)}/>
                    <input placeholder='Барааны нэр' className="input-style"
                           value={dirSeller} onChange={(event) => setDirSeller(event.target.value)}/>
                    <input placeholder='Жил' className="input-style" type="number"
                           value={dirRep} onChange={(event) => setDirRep(event.target.value)}/>
                    <div className="btn" onClick={() => addDirWork()}>
                        Нэмэх
                    </div>
                </div>
            </Modal>
            <Modal
                open={isOpen}
                onCancel={()=>setIsOpen(false)}
                footer={null}
                className="form-modal"
            >
                <div className="login-modal">
                    <div className="modal-header">
                        <div style={{
                            display: 'flex',
                            flexDirection: 'row',
                            justifyContent: 'space-between',
                            width: '100%'
                        }}>
                            <div className="text">
                                Оролцогчоор Бүртгүүлэх
                            </div>
                        </div>
                        <div style={{width: '100%', backgroundColor: '#707070', height: 1, marginTop: 36}}/>
                    </div>
                    <div className="login-body">
                        <div className="row-style">
                            <div className="text1">Оролцогчийн төрөл:</div>
                            <div style={{display: 'flex', flexDirection: 'row', alignItems: 'center'}}>
                                <div className={`checkBox-style ${checkedPerson ? 'isActive' : ''}`}
                                     onClick={() => changePerson()}/>
                                <div className="text2">Иргэн</div>
                            </div>
                            <div style={{display: 'flex', flexDirection: 'row', alignItems: 'center'}}>
                                <div className={`checkBox-style ${checkedCompany ? 'isActive' : ''}`}
                                     onClick={() => changeCompany()}/>
                                <div className="text2">Хуулийн Этгээд</div>
                            </div>
                        </div>
                        {checkedPerson && <div className="bold-text">Иргэний мэдээлэл</div>}
                        {checkedCompany && <div className="bold-text">Хуулийн этгээдийн мэдээлэл</div>}
                        <input placeholder={checkedPerson ? 'Ургийн овог' : 'Хуулийн этгээдийн нэр'}
                               className="input-style"
                               value={mainName} onChange={(event) => setMainName(event.target.value)}/>
                        {checkedPerson && <input placeholder="Эцэг, эхийн нэр" className="input-style"
                                                 value={lastName}
                                                 onChange={(event) => setLastName(event.target.value)}/>}
                        {checkedPerson && <input placeholder="Өөрийн нэр" className="input-style"
                                                 value={firstName}
                                                 onChange={(event) => setFirstName(event.target.value)}/>}
                        {checkedCompany && <div style={{marginTop: 15}}>
                            <Select
                                options={mainType}
                                value={compType}
                                onChange={(value) => setCompType(value)}
                            />
                        </div>}
                        {checkedCompany && <div className="row-style">
                            <div className="text1">Гадаадын хөрөнгө оруулалттай эсэх:</div>
                            <div style={{display: 'flex', flexDirection: 'row', alignItems: 'center'}}>
                                <div className={`checkBox-style ${foriegnInvestTrue ? 'isActive' : ''}`}
                                     onClick={() => changeTrue()}/>
                                <div className="text2">Тийм</div>
                            </div>
                            <div style={{display: 'flex', flexDirection: 'row', alignItems: 'center'}}>
                                <div className={`checkBox-style ${foriegnInvestFalse ? 'isActive' : ''}`}
                                     onClick={() => changeFalse()}/>
                                <div className="text2">Үгүй</div>
                            </div>
                        </div>}
                        {checkedCompany && <div style={{marginTop: 30}}>
                            <Select
                                options={Country}
                                placeholder="Харъяалал"
                                value={modalPosition}
                                onChange={(value) => setModalPosition(value)}
                            />
                        </div>}
                        <input placeholder="Регистрийн дугаар" className="input-style"
                               value={regNo} onChange={(event) => setRegNo(event.target.value)}/>
                        {checkedCompany && <input placeholder="Үйл ажиллагааны чиглэл" className="input-style"
                                                  value={dirWork}
                                                  onChange={(event) => setDirWork(event.target.value)}/>}
                        {checkedCompany && <div className="row-style">
                            <input placeholder="Хувьцаа эзэмшигчийн тоо" className="input-style"
                                   style={{marginRight: 15}} type="number"
                                   value={chairManNumber} onChange={(event) => setChairManNumber(event.target.value)}/>
                            <input placeholder="Ажилчдын тоо" className="input-style" style={{marginLeft: 15}}
                                   type="number"
                                   value={employeNumber} onChange={(event) => setEmployeNumber(event.target.value)}/>
                        </div>}
                        <div className="bold-text">Холбоо барих мэдээлэл</div>
                        <input placeholder="Хаяг" className="input-style"
                               value={address} onChange={(event) => setAddress(event.target.value)}/>
                        <div className="row-style">
                            <input placeholder="Утасны дугаар 1" className="input-style" style={{marginRight: 15}}
                                   type="number"
                                   value={phone1} onChange={(event) => setPhone1(event.target.value)}/>
                            <input placeholder="Утасны дугаар 2" className="input-style" style={{marginLeft: 15}}
                                   type="number"
                                   value={phone2} onChange={(event) => setPhone2(event.target.value)}/>
                        </div>
                        <input placeholder="И-мэйл хаяг" className="input-style"
                               value={email} onChange={(event) => setEmail(event.target.value)}/>
                        <div className="row-style">
                            <input placeholder="Facebook" className="input-style" style={{marginRight: 15}}
                                   value={facebook} onChange={(event) => setFacebook(event.target.value)}/>
                            <input placeholder="Twitter" className="input-style" style={{marginLeft: 15}}
                                   value={twitter} onChange={(event) => setTwitter(event.target.value)}/>
                        </div>
                        <div className="row-style">
                            <input placeholder="Instagram" className="input-style" style={{marginRight: 15}}
                                   value={instagram} onChange={(event) => setInstagram(event.target.value)}/>
                            <input placeholder="Linkedin" className="input-style" style={{marginLeft: 15}}
                                   value={linked} onChange={(event) => setLinked(event.target.value)}/>
                        </div>
                        <div className="bold-text">Үйл ажиллагааны мэдээлэл</div>
                        {checkedCompany && <div style={{marginTop: 15}}>
                            <Select
                                options={workDirectionData}
                                placeholder="Үйл ажиллагааны чиглэл"
                                value={workDir}
                                onChange={({option}) => setWorkDir(option)}
                            />
                        </div>}
                        <div className="plus-btn" onClick={() => setVisible1(true)}>
                            Нэмэх
                        </div>
                        <div className="dir-work-container">
                            {workDirectionList.length > 0 && workDirectionList.map((data, index) => (
                                <div className="box">
                                    <div style={{
                                        display: 'flex',
                                        width: '98%',
                                        justifyContent: 'flex-end',
                                        marginBottom: 10,
                                        marginTop: 10
                                    }}>
                                    </div>
                                    <div key={index} className="container">
                                        {/*<div style={{ display: 'flex', flexDirection: 'column' }}>*/}
                                        {/*  <div style={{ fontSize: 15, color: '#000000', fontWeight: 500 }}>Үйл ажиллагааны чиглэл</div>*/}
                                        {/*  <div style={{ fontSize: 15, opacity: 0.5, color: '#000000', marginTop: 10 }}>{data.direction}</div>*/}
                                        {/*</div>*/}
                                        <div style={{
                                            display: 'flex',
                                            flexDirection: 'row',
                                            justifyContent: "space-between"
                                        }}>
                                            <div style={{fontSize: 15, color: '#000000', fontWeight: 500}}>Үйлдвэрлэгч
                                            </div>
                                            <div style={{
                                                fontSize: 15,
                                                opacity: 0.5,
                                                color: '#000000',
                                                marginTop: 10
                                            }}>{data.produceType}</div>
                                        </div>
                                        <div style={{
                                            display: 'flex',
                                            flexDirection: 'row',
                                            justifyContent: "space-between"
                                        }}>
                                            <div style={{fontSize: 15, color: '#000000', fontWeight: 500}}>Барааны нэр
                                            </div>
                                            <div style={{
                                                fontSize: 15,
                                                opacity: 0.5,
                                                color: '#000000',
                                                marginTop: 10
                                            }}>{data.name}</div>
                                        </div>
                                        <div style={{
                                            display: 'flex',
                                            flexDirection: 'row',
                                            justifyContent: "space-between"
                                        }}>
                                            <div style={{fontSize: 15, color: '#000000', fontWeight: 500}}>Жил</div>
                                            <div style={{
                                                fontSize: 15,
                                                opacity: 0.5,
                                                color: '#000000',
                                                marginTop: 10
                                            }}>{data.year}</div>
                                        </div>
                                    </div>
                                </div>
                            ))}
                        </div>
                        {checkedCompany && <div className="bold-text">Төлөөлөх эрх бүхий албан тушаалтны мэдээлэл</div>}
                        {checkedCompany && <div className="plus-btn" onClick={() => setVisible2(true)}>
                            Нэмэх
                        </div>}
                        {checkedCompany && <div className="dir-work-container">
                            {subWorkerList.length > 0 && subWorkerList.map((data, index) => (
                                <div className="box">
                                    <div style={{
                                        display: 'flex',
                                        width: '99%',
                                        justifyContent: 'flex-end',
                                        marginBottom: 10,
                                        marginTop: 10
                                    }}>
                                    </div>
                                    <div key={index} className="container">
                                        <div style={{
                                            display: 'flex',
                                            flexDirection: 'row',
                                            justifyContent: "space-between"
                                        }}>
                                            <div style={{fontSize: 15, color: '#000000', fontWeight: 500}}>Овог</div>
                                            <div style={{
                                                fontSize: 15,
                                                opacity: 0.5,
                                                color: '#000000',
                                                marginTop: 10
                                            }}>{data.lastName}</div>
                                        </div>
                                        <div style={{
                                            display: 'flex',
                                            flexDirection: 'row',
                                            justifyContent: "space-between"
                                        }}>
                                            <div style={{fontSize: 15, color: '#000000', fontWeight: 500}}>Нэр</div>
                                            <div style={{
                                                fontSize: 15,
                                                opacity: 0.5,
                                                color: '#000000',
                                                marginTop: 10
                                            }}>{data.firstName}</div>
                                        </div>
                                        <div style={{
                                            display: 'flex',
                                            flexDirection: 'row',
                                            justifyContent: "space-between"
                                        }}>
                                            <div style={{fontSize: 15, color: '#000000', fontWeight: 500}}>Албан
                                                тушаал
                                            </div>
                                            <div style={{
                                                fontSize: 15,
                                                opacity: 0.5,
                                                color: '#000000',
                                                marginTop: 10
                                            }}>{data.position}</div>
                                        </div>
                                        <div style={{
                                            display: 'flex',
                                            flexDirection: 'row',
                                            justifyContent: "space-between"
                                        }}>
                                            <div style={{fontSize: 15, color: '#000000', fontWeight: 500}}>Утас</div>
                                            <div style={{
                                                fontSize: 15,
                                                opacity: 0.5,
                                                color: '#000000',
                                                marginTop: 10
                                            }}>{data.phone}</div>
                                        </div>
                                        <div style={{
                                            display: 'flex',
                                            flexDirection: 'row',
                                            justifyContent: "space-between"
                                        }}>
                                            <div style={{fontSize: 15, color: '#000000', fontWeight: 500}}>И-мэйл</div>
                                            <div style={{
                                                fontSize: 15,
                                                opacity: 0.5,
                                                color: '#000000',
                                                marginTop: 10
                                            }}>{data.email}</div>
                                        </div>
                                    </div>
                                </div>
                            ))}
                        </div>}
                        <div className="btn-container">
                            <div className="btn-style" onClick={() => saveData()}>
                                <div style={{color: '#FFFFFF', fontSize: 14, fontWeight: 'bold'}}>Хадгалах</div>
                            </div>
                        </div>
                    </div>
                </div>
            </Modal>
            <div className="login-page">
                <ToastContainer/>
                <div style={{backgroundImage: 'url(' + LoginBanner + ')', width: "auto"}} className="banner">
                    <div className="login-background">
                        <div className="login-text-area">
                            <div className="login-width login-text">
                                Нэвтрэх
                            </div>
                            <div className="login-width small-text">
                                Санал илгээхийн тулд Оролцогчийн эрхээр нэвтэрнэ үү
                            </div>
                            <div className="login-width input-container">
                                <img src={UserImg} className="icon-style"/>
                                <input placeholder="Нэвтрэх нэр" className="capex-earn-input"/>
                            </div>
                            <div className="login-width input-container">
                                <img src={SecretImg} className="icon-style"/>
                                <input placeholder="Нууц үг" className="capex-earn-input"/>
                            </div>
                            <div className="login-width">
                                <div className="login-btn">
                                    Нэвтрэх
                                </div>
                            </div>
                            <div className="login-width">
                                <div className="forget-container">
                                    <div className="text">Нууц үг мартсан?</div>
                                </div>
                            </div>
                            <div className="login-width">
                                <div style={{width: '100%', backgroundColor: '#FFFFFF', height: 1, marginTop: 45}}/>
                            </div>
                            <div className="login-width" style={{marginTop: 95}}>
                                <div className="register-btn" onClick={() => setIsOpen(true)}>
                                    <div className="text">
                                        Оролцогчоор Бүртгүүлэх
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </React.Fragment>
    )
}
