import React from 'react'


function FileCard(props) {
  return (
    <div style={{width: "100%", display: "flex", justifyContent: "center"}}>
    <a className='xac-download' target="blank"  style={{textDecoration: "none", color: "black"}} >
        <span className='report-meta uk-hidden-small uk-text-uppercase' >{props.filetype} <br/>{props.size}</span>
        <span className='left-icon transition'><img src="https://www.xacbank.mn/img/icons/download.png" style={{width: "25px"}}/></span>
        <span className='report-title text-semibold transition'><span>{props.text}</span></span>
    </a>
    </div>
  )
}

export default FileCard