import React from 'react'
import homeHero from '../../asset/homeHero.png'
import BBCIMG from "../../asset/bbc.png";
import NetflixIMG from "../../asset/netflix.png";
import ForbesIMG from "../../asset/forbes.png";
import LifeHackIMG from "../../asset/lifehacker.png";
import MashIMG from "../../asset/mashable.png";
import arZalaat from '../../asset/arZalaat.png'
import chmm from '../../asset/chmm.png'
import erdenet from '../../asset/erdenet.png'
import gowi from '../../asset/gowi.png'
import hanerchim from '../../asset/hanerchim.png'

import landTest from '../../asset/landTest.png'

import msltt from '../../asset/msltt.png'

import NMP from '../../asset/NMP.png'

import orem from '../../asset/orem.png'

import orhonHynalt from '../../asset/orhonHynalt.png'

import shigi from '../../asset/shigi.png'

import SmartPlus from '../../asset/SmartPlus.png'
import SliderOrg from './SliderOrg';


function OrgComponent() {
  return (
    <>
    <div style={{position: 'relative', width: "100%", marginTop: "5rem", display: "flex", padding: "2.5rem 0", justifyContent: "center", height: "15rem"}}>
    <div style={{width: "100%", height: "100%", position: "absolute", top: 0, left: 0, zIndex: "-2"}}><img style={{width: "100%", height: "100%", objectFit: "cover"}} src={homeHero}/></div>
      <div className='org-slider' style={{display: "flex", flexDirection: "column", justifyContent: "center"}}>
        <div className='orgTitle' style={{color: "white", fontSize: "2rem", fontWeight: 600, display: "flex", flexDirection: "column", alignItems: "center", gap: "1rem"}}>
          <div>ХАМТРАГЧ БАЙГУУЛЛАГУУД <div className='underline' style={{height: "1.5px",  background: "white"}}/></div>
          
        </div>
        <div>
          <SliderOrg/>
        </div>
      </div>
  </div>
  
  </>
  )
}

export default OrgComponent