import React from 'react'

function EnvCard({src, text, setTab, index, tab}) {
  return (
    <div className={`envCard ${tab===index+1  && "active"}`} onClick={()=>setTab(index+1)}>
        <div className='icon'><img src={src}/></div>
        <div className='text'>{text}</div>
    </div>
  )
}

export default EnvCard