import React, {useEffect, useState} from "react"
import BannerImg from "../../asset/Home4.jpg"
import {Link} from "react-router-dom"
import WorkService from "../../services/workService"
import moment from "moment"
import ParallaxHeader from "../../component/ParallaxHeader"

const newsTitle = [
  {
    id: 1,
    text: "Худалдан авалтын бодлого",
    to: "/purchase",
  },
  {
    id: 2,
    text: "Худалдан авалтын процесс",
    to: "/purchase",
  },
  {
    id: 3,
    text: "Худалдан авалт",
    to: "/purchase",
  },
]

export default function PurchaseDetail() {
  const [newsTitleId, setNewsTitleId] = useState(3)
  const [hrefId, setHrefId] = useState(null)
  const [resultData, setResultData] = useState(null)
  const [requireDoc, setRequireDoc] = useState([])
  const [requireGoods, setRequireGoods] = useState([])
  const [atchRequire, setAtchRequire] = useState([])

  useEffect(() => {
    const location = window.location.href
    const id = location.substring(location.lastIndexOf("/") + 1)
    setHrefId(id)
    getData(id)
    // window.scrollTo(0, 0)
  }, [window.location.href])

  const getData = async (id) => {
    const result = await WorkService.getPurchaseId(id)
    console.log("the result data is", result)
    const doc = JSON.parse(result[0].requirement_document)
    const good = JSON.parse(result[0].requirement_goods)
    // const atch = JSON.parse(result[0].attach_req);
    setResultData(result[0])
    setRequireDoc(doc)
    setRequireGoods(good)
    // setAtchRequire(atch);
  }

  return (
    <React.Fragment>
      <ParallaxHeader src={BannerImg} />
      <div
        className=" margin-style-real"
        style={{marginBottom: 50, marginTop: 50}}
      >
        <div
          className="title-style"
          style={{
            fontSize: "1.1rem",
            fontWeight: "500",
            paddingBottom: "1rem",
            borderBottom: "1px solid rgba(30, 30, 30, 0.1137254902)",
          }}
        >
          Худалдан авалтын дэлгэрэнгүй
        </div>
      </div>

      {newsTitleId === 3 && (
        <div className="margin-style-real">
          <div className="purchase-page">
            <div style={{display: "flex", flexDirection: "row"}}>
              <span className="title-question">Худалдан авалтын төрөл:</span>
              <span className="text-answer">
                {resultData && resultData.type && resultData.type}
              </span>
            </div>
            <div style={{display: "flex", flexDirection: "row"}}>
              <span className="title-question">
                Сонгон шалгаруулалтын төрөл:
              </span>
              <span className="text-answer">{resultData?.chooseType}</span>
            </div>
            <div style={{display: "flex", flexDirection: "row"}}>
              <span className="title-question">Худалдан авалтын нэр:</span>
              <span className="text-answer">{resultData?.name}</span>
            </div>
            <div style={{display: "flex", flexDirection: "row"}}>
              <span className="title-question">Нээгдсэн огноо:</span>
              <span className="text-answer">
                {moment(resultData?.start_date).format("YYYY.MM.D")}
              </span>
            </div>
            <div style={{display: "flex", flexDirection: "row"}}>
              <span className="title-question">Хаагдах огноо:</span>
              <span className="text-answer">
                {moment(resultData?.end_date).format("YYYY.MM.D")}{" "}
              </span>
            </div>
            <div style={{display: "flex", flexDirection: "row"}}>
              <span className="title-question">Төлөв:</span>
              <span
                style={{
                  color: "#6BCDCB",
                  fontSize: 17,
                  fontWeight: "bold",
                  marginLeft: 80,
                }}
              >
                Нээлттэй
              </span>
            </div>
            <div style={{display: "flex", flexDirection: "row"}}>
              <span className="title-question">Санал хүлээн авах хаяг:</span>
              <span className="text-answer">info@zes-erdene.mn</span>
            </div>
            <div style={{display: "flex", flexDirection: "row"}}>
              <span className="title-question">
                Тусгай зөвшөөрөл шаардах эсэх:
              </span>
              <span className="text-answer">
                {resultData?.require_license === 1 ? "тийм" : "үгүй"}
              </span>
            </div>

            {/*<span className="title-style" style={{ marginTop: 60, marginBottom: 20 }}>Хавсаргах бичиг баримтын шаардлага:</span>*/}
            {/*{atchRequire && atchRequire.length > 0 && atchRequire.map((data, index) => (*/}
            {/*  <div style={{marginTop: 20, display: 'flex'}} key={index}>*/}
            {/*    <div className="circle-style"/>*/}
            {/*    <span className="text-style" style={{marginLeft: 20}}>{data.value}</span>*/}
            {/*  </div>*/}
            {/*))}*/}
          </div>
          <div>
            <span
              className="title-style"
              style={{marginTop: 60, marginBottom: 20, fontWeight: 500}}
            >
              Нийлүүлэх бараа, ажил үйлчилгээнд тавигдах шаардлага:
            </span>
            {requireGoods &&
              requireGoods.length > 0 &&
              requireGoods.map((data, index) => (
                <ul style={{marginTop: 20, display: "flex"}} key={index}>
                  <li>
                    <span className="text-style">{data.value}</span>
                  </li>
                </ul>
              ))}

            <span
              className="title-style"
              style={{
                marginTop: 100,
                paddingTop: 50,
                marginBottom: 40,
                fontWeight: 500,
              }}
            >
              Бичиг баримтанд тавигдах шаардлага:
            </span>
            {requireDoc &&
              requireDoc.length > 0 &&
              requireDoc.map(
                (data, index) =>
                  data.value !== undefined && (
                    <ul style={{marginTop: 10, display: "flex"}} key={index}>
                      <li>
                        {" "}
                        <span className="text-style" style={{}}>
                          {data.value}
                        </span>
                      </li>
                    </ul>
                  )
              )}
          </div>
        </div>
      )}

      {newsTitleId === 3 && (
        <div className="margin-style">
          <div className="purchase-button-container">
            <Link
              to={`/send-purchase/${hrefId}`}
              className="btn-style"
              style={{textOverflow: "ellipsis", textDecoration: "none"}}
            >
              <span style={{color: "#FFFFFF", fontSize: 16, fontWeight: 500}}>
                Санал илгээх
              </span>
            </Link>
          </div>
        </div>
      )}
    </React.Fragment>
  )
}
